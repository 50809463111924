var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-dialog",
    {
      attrs: { "max-width": "600" },
      model: {
        value: _vm.dialog,
        callback: function($$v) {
          _vm.dialog = $$v
        },
        expression: "dialog"
      }
    },
    [
      _vm.dialog
        ? _c(
            "psi-dialog",
            {
              attrs: {
                title: _vm.title,
                icon: _vm.icon,
                "heading-color": _vm.headingColor
              },
              on: { close: _vm.cancel, save: _vm.agree },
              scopedSlots: _vm._u(
                [
                  {
                    key: "actions",
                    fn: function() {
                      return [
                        _c(
                          "v-btn",
                          {
                            staticClass: "mr-4",
                            on: {
                              click: function($event) {
                                $event.stopPropagation()
                                return _vm.cancel($event)
                              }
                            }
                          },
                          [_vm._v("Cancel")]
                        ),
                        _c(
                          "v-btn",
                          {
                            staticClass: "px-4",
                            attrs: { color: _vm.buttonColor },
                            on: {
                              click: function($event) {
                                $event.stopPropagation()
                                return _vm.agree($event)
                              }
                            }
                          },
                          [
                            _c("v-icon", { staticClass: "mr-1" }, [
                              _vm._v("mdi-check")
                            ]),
                            _vm._v(" OK ")
                          ],
                          1
                        )
                      ]
                    },
                    proxy: true
                  }
                ],
                null,
                false,
                1892705087
              )
            },
            [
              _c(
                "v-alert",
                {
                  staticClass: "font-weight-bold text-subtitle-1",
                  attrs: {
                    prominent: "",
                    color: "primary",
                    border: "top",
                    dark: "",
                    outlined: ""
                  }
                },
                [_vm._v(" " + _vm._s(_vm.message) + " ")]
              )
            ],
            1
          )
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }