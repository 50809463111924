var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-text-field",
    _vm._b(
      {
        directives: [
          {
            name: "mask",
            rawName: "v-mask",
            value: _vm.fieldMask,
            expression: "fieldMask"
          }
        ],
        ref: _vm.name,
        staticClass: "psi-text-input",
        class: [
          { "mt-1": _vm.$vuetify.breakpoint.smAndUp },
          { "mt-1": _vm.$vuetify.breakpoint.smAndDown },
          { "mb-1": _vm.hideDetails }
        ],
        style: _vm.cssVars,
        attrs: {
          type: _vm.fieldType,
          autofocus: _vm.autofocus,
          id: _vm.id,
          name: _vm.name,
          "full-width": _vm.fullWidth,
          "validate-on-blur": _vm.validateOnBlur,
          "prepend-inner-icon": _vm.icon,
          "append-outer-icon": _vm.outerIcon,
          "append-icon": _vm.appendIcon,
          placeholder: _vm.fieldPlaceholder,
          hint: _vm.hint,
          "hide-details": _vm.hideDetails,
          "persistent-hint": _vm.persistentHint,
          rules: _vm.validationRules,
          clearable: _vm.clearable,
          label: _vm.label,
          disabled: _vm.disabled,
          dusk: _vm.id,
          required: _vm.required
        },
        on: {
          input: function($event) {
            return _vm.$emit("input", _vm.model)
          },
          change: function($event) {
            return _vm.$emit("change", _vm.model)
          },
          "click:append-outer": function($event) {
            return _vm.$emit("click:append-outer")
          },
          "click:append": _vm.handleAppend,
          "click:clear": function($event) {
            return _vm.$emit("click:clear")
          },
          focus: function($event) {
            _vm.attrs["background-color"] = _vm.backgroundColor
            _vm.focused = true
          },
          blur: function($event) {
            _vm.attrs["background-color"] = ""
            _vm.focused = false
          }
        },
        model: {
          value: _vm.model,
          callback: function($$v) {
            _vm.model = $$v
          },
          expression: "model"
        }
      },
      "v-text-field",
      _vm.attrs,
      false
    )
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }