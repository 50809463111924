import uploadItemMap from "../../config/item-map.json";

const deepClone = (obj) => {
    return JSON.parse(JSON.stringify(obj));
};

const createItems = (obj) => {
    let items = [];
    Object.keys(obj).forEach((key) => {
        if (Object.keys(uploadItemMap).includes(key)) {
            let item = deepClone(uploadItemMap[key]);
            item.text = obj[key];
            items.push(item);
        }
    });
    return items.sort((a, b) => {
        return a.order - b.order;
    });
};
export const transform = (data) => {
    return data.map((val) => {
        return {
            ...val,
            items: createItems(val),
        };
    });
};
