<template>
    <v-autocomplete
        :style="cssVars"
        :id="id"
        :name="name"
        v-model="model"
        v-bind="elemAttrs"
        validate-on-blur
        class="psi-text-input"
        :class="[
            { 'mt-1': $vuetify.breakpoint.smAndUp },
            { 'mt-1': $vuetify.breakpoint.smAndDown },
        ]"
        :items="items"
        :item-text="itemText"
        :item-value="itemValue"
        :prepend-inner-icon="icon"
        :append-outer-icon="outerIcon"
        :placeholder="fieldPlaceholder"
        :hint="hint"
        :persistent-hint="persistentHint"
        :rules="validationRules"
        :label="label"
        :disabled="disabled"
        :dusk="name"
        :required="required"
        :return-object="returnObject"
        @change="$emit('input', model)"
        @update:search-input="$emit('search-input', $event)"
        @click:append-outer="$emit('click:append-outer')"
        @focus="
            attrs['background-color'] = backgroundColor;
            focused = true;
        "
        @blur="
            attrs['background-color'] = '';
            focused = false;
        "
    >
    </v-autocomplete>
</template>
<script>
export default {
    name: "psi-form-autocomplete-field",
    components: {},
    props: {
        id: {
            required: true,
            type: String,
        },
        value: {
            type: [String, Number],
            required: true,
        },
        items: {
            type: Array,
            required: true,
        },
        itemText: {
            type: String,
            required: false,
            default: "text",
        },
        itemValue: {
            type: String,
            required: false,
            default: "value",
        },
        required: {
            type: Boolean,
            required: false,
            default: false,
        },
        label: {
            type: String,
            required: false,
            default: "",
        },
        icon: {
            type: String,
            required: false,
            default: "",
        },
        placeholder: {
            type: String,
            required: false,
            default: "",
        },
        name: {
            type: String,
            required: true,
        },
        disabled: {
            type: Boolean,
            required: false,
            default: false,
        },
        hint: {
            type: String,
            required: false,
            default: "",
        },
        persistentHint: {
            type: Boolean,
            required: false,
            default: false,
        },
        rules: {
            type: Array,
            required: false,
            default: () => [],
        },
        color: {
            type: String,
            required: false,
            default: "primary",
        },
        outerIcon: {
            type: String,
            required: false,
            default: "",
        },
        backgroundColor: {
            required: false,
            default: "alternate4 lighten-5",
        },
        returnObject: {
            required: false,
            type: Boolean,
            default: false,
        },
        attrs: {
            required: false,
            type: Object,
            default: () => {
                return {};
            },
        },
    },
    data() {
        return {
            model: this.value,
            error: false,
            focused: false,
            elemAttrs: Object.assign(this.attrs, {
                height: "40",
                solo: true,
                color: this.color,
                "background-color": "",
            }),
        };
    },
    watch: {
        value() {
            this.model = this.value;
        },
    },
    computed: {
        validationRules() {
            return this.rules.length > 0 ? this.rules : this.customValidation();
        },
        fieldPlaceholder() {
            return this.placeholder.length > 0
                ? this.placeholder
                : `Enter ${this.label}`;
        },
        cssVars() {
            return {
                "--textinput-box-shadow": this.focused
                    ? "0px 5px 3px 2px rgba(0, 0, 0, 0.2),0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12)"
                    : "0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12)",
            };
        },
    },
    methods: {
        customValidation() {
            return this.required
                ? [(v) => !!v || `${this.label} is a required field`]
                : [];
        },
    },
    mounted() {},
};
</script>

<style >
.psi-text-input > .v-input__control > .v-input__slot {
    box-shadow: var(--textinput-box-shadow) !important;
}
</style>