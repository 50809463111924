<template>
    <v-form ref="form" class="mb-4">
        <v-file-input
            :id="name"
            :name="name"
            @click:clear="clearFile"
            @change="fileUpload"
            :placeholder="`Upload your ${label}`"
            :hint="hint"
            :clearable="!loading"
            :disabled="loading"
            :error="uploadError"
            persistent-hint
            :prepend-icon="
                $vuetify.breakpoint.mdAndUp ? 'attachment' : 'mdi-camera'
            "
            :accept="accept"
            chips
            :counter="$vuetify.breakpoint.mdAndUp"
            dense
            :rules="rules"
            :show-size="$vuetify.breakpoint.mdAndUp"
            :loading="loading"
            :value="value"
            :ref="name"
        >
            <template v-slot:selection="{ text }">
                <v-chip small label color="primary">
                    <v-icon small class="mr-1" v-if="uploadSuccess"
                        >mdi-check-bold</v-icon
                    >
                    {{ text }}
                </v-chip>
            </template>
            <template v-slot:progress>
                <v-progress-linear
                    color="primary"
                    :value="uploadProgress"
                ></v-progress-linear>
            </template>
        </v-file-input>
    </v-form>
</template>
<script>
export default {
    name: "psi-form-file-upload",
    components: {},
    props: {
        value: {
            required: false,
            default: () => {},
        },
        required: {
            type: Boolean,
            required: false,
            default: true,
        },
        name: {
            type: String,
            required: false,
            default: "file",
        },
        label: {
            type: String,
            required: false,
            default: "File",
        },
        hint: {
            type: String,
            required: false,
            default: "",
        },
        accept: {
            type: String,
            required: false,
            default:
                "image/png, image/jpg, image/jpeg, application/pdf, application/docx, application/doc",
        },
        maxUploadSize: {
            type: Number,
            required: false,
            default: 10000000, // 10 MBytes
        },
        maxUploadError: {
            type: String,
            required: false,
            default: "File must be smaller than 10 Mbtyes",
        },
    },
    data() {
        return {
            loading: false,
            uploadProgress: 0,
            uploadSuccess: false,
            uploadError: false,
            rules: this.required
                ? [
                      (v) => !!v || `${this.label} is required`,
                      (v) =>
                          (v && v.size < this.maxUploadSize) ||
                          this.maxUploadError,
                  ]
                : [],
        };
    },
    computed: {},
    watch: {},
    methods: {
        initialize() {
            this.$refs.form.reset();
            this.loading = false;

            this.uploadProgress = 0;
            this.uploadSuccess = false;
            this.uploadError = false;
            // this.$emit("update", null, this.file.id);
        },
        clearFile() {
            this.initialize();
        },
        fileUpload() {},
    },
};
</script>

<style scoped>
</style>


        