<template>
    <v-snackbar v-model="show" :color="color" top right :timeout="timeout">
        {{ message }}
        <template v-slot:action="{ attrs }">
            <v-btn icon dark text v-bind="attrs" @click="show = false">
                <v-icon small>mdi-close</v-icon>
            </v-btn>
        </template>
    </v-snackbar>
</template>

<script>
export default {
    name: "psi-snackbar",
    props: {
        timeout: {
            type: Number,
            required: false,
            default: 3000, // 3 seconds
        },
    },
    data() {
        return {
            show: false,
            message: "",
            color: "",
        };
    },

    created() {
        // Subscribe to the global store and listen a message being set for the snackbar
        if (this.$store) {
            this.$store.subscribe((mutation, state) => {
                if (mutation.type === "Snackbar/SET_MESSAGE") {
                    this.message = state.Snackbar.content;
                    this.color = state.Snackbar.color;
                    this.show = true;
                }
            });

            this.$store.subscribe((mutation, state) => {
                if (mutation.type === "SET_ERROR") {
                    this.$store.dispatch("Snackbar/showMessage", {
                        content: state.error,
                        color: "error",
                    });
                }
            });
        }
    },
};
</script>