import httpClient from "@api/client";

import { transform, lookup, mapKeys } from "@utils/transform";
import itemMap from "./transform/item-map";
import get from "lodash.get";
import caseTransform from "./transform";

// const sleep = (ms) => {
//     return new Promise((resolve) => setTimeout(resolve, ms));
// };
// Client Side Filtering only during prototyping
if (process.env.VUE_APP_PROTOTYPING) {
    httpClient.interceptors.response.use(async (res) => {
        // Add  delay to the response for UI loaders

        // Only filter on API calls to the orders path in this interceptor
        if (res.config.url.includes("/orders/")) {
            //       await sleep(150); //
            // filter the results if we have a parameter set
            res.data = applyFilter(res.data, res.config);
        }
        return res;
    });
}
/**
 * applyFilter - filter response data from input parameters
 * @param {*} data
 * @param {*} config
 */
const applyFilter = (data, config) => {
    const params = get(config, "params", {});
    if (Object.keys(params).length) {
        let items = get(data, "data.page.data", []);
        items = items.filter((item) => {
            let includeItems = [];

            for (const key in params) {
                switch (key) {
                    case "assigned_to":
                        includeItems.push(
                            item[key].id.toString() === params[key]
                        );
                        break;
                    case "date_range":
                        includeItems.push(true); // TODO: add date range filter
                        break;
                    case "property":
                        includeItems.push(
                            item[key].id.toString() === params[key]
                        );
                        break;
                    case "company":
                        includeItems.push(
                            item.property[key].id.toString() === params[key]
                        );
                        break;
                    case "status":
                        includeItems.push(
                            item[key].toLowerCase() ===
                                params[key].toLowerCase()
                        );
                        break;
                    case "time_pending":
                        includeItems.push(true); // TODO: add time pending filter
                        break;
                }
            }
            return includeItems.every((val) => val);
        });
        // Reset items to the filtered set
        data.data.page.data = items;
    }
    return data;
};

const getParams = (params) => {
    let filterParams = {};
    for (const key in params) {
        if (get(params, `${key}.value`, "").toString().length > 0) {
            filterParams[key] = get(params, `${key}.value`, "").toString();
        }
    }
    return filterParams;
};

export default {
    async getScreens(filterParams) {
        const params = getParams(filterParams);

        let { data } = await httpClient.get("/orders", {
            params,
        });
        let items = get(data.data, "page.data", []);
        items.forEach((screen) => {
            screen.status = lookup(screen.status, "status", itemMap);
        });
        return data.data;
    },
    async getScreen(id) {
        let res = await httpClient.get(`/orders/${id}`);
        let order = res.data.data;

        order["status"] = lookup(order.status, "status", itemMap);
        return transform(order, ["details"], itemMap);
    },
    async getScreeners() {
        let res = await httpClient.get("/screeners");
        return res.data.data;
    },
    async getApplications() {
        let res = await httpClient.get("/applications");
        return res.data.data;
    },
    async getApplication(id) {
        let res = await httpClient.get(`/applications/${id}`);
        return res.data.data;
    },

    async getApplicants() {
        let res = await httpClient.get("/applicants");
        return res.data.data;
    },
    async getApplicant(id) {
        let res = await httpClient.get(`/applicants/${id}`);
        let applicant = res.data.data;
        return {
            ...applicant,
            ...mapKeys(
                applicant,
                ["application_details", "applicant_profile"],
                itemMap
            ),
        };
    },

    async getLandlords() {
        let res = await httpClient.get("/landlords");
        return res.data;
    },
    async getEmployers() {
        let res = await httpClient.get("/employers");
        return res.data;
    },

    async getScreenSearch() {
        let res = await httpClient.get(
            "https://psi-screening-service-dev-output.s3.us-west-2.amazonaws.com/0-ojin-mel-flygare/output.json?X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Credential=AKIAIAFPJY7FQJ75JGLQ/20210424/us-west-2/s3/aws4_request&X-Amz-Date=20210424T233016Z&X-Amz-Expires=604794&X-Amz-Signature=c63fd70cb26474170b131a9edf7f396007b3144be70ad8d7c34b9bd63c0754cd&X-Amz-SignedHeaders=host&response-content-disposition=inline"
        );
        let resReport = await httpClient.get(
            "https://psi-screening-service-dev-output.s3-us-west-2.amazonaws.com/0-ojin-mel-flygare/ojin-report.json?X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Credential=AKIAJLIWNWYK4MTBOIVA/20210426/us-west-2/s3/aws4_request&X-Amz-Date=20210426T160337Z&X-Amz-Expires=604793&X-Amz-Signature=8cd01314f1a6d097e242a9f07a9f3017e309c05dde7e3d8edbedabb608fed3c0&X-Amz-SignedHeaders=host&response-content-disposition=inline"
        );
        console.log(res.data.ojin.tags);
        res.data.ojin.tags = caseTransform.mapCases(
            res.data.ojin.tags,
            resReport.data
        );
        return res.data;
    },
};
