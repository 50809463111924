var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-autocomplete",
    _vm._b(
      {
        staticClass: "psi-text-input",
        class: [
          { "mt-1": _vm.$vuetify.breakpoint.smAndUp },
          { "mt-1": _vm.$vuetify.breakpoint.smAndDown }
        ],
        style: _vm.cssVars,
        attrs: {
          id: _vm.id,
          name: _vm.name,
          "validate-on-blur": "",
          items: _vm.items,
          "item-text": _vm.itemText,
          "item-value": _vm.itemValue,
          "prepend-inner-icon": _vm.icon,
          "append-outer-icon": _vm.outerIcon,
          placeholder: _vm.fieldPlaceholder,
          hint: _vm.hint,
          "persistent-hint": _vm.persistentHint,
          rules: _vm.validationRules,
          label: _vm.label,
          disabled: _vm.disabled,
          dusk: _vm.name,
          required: _vm.required,
          "return-object": _vm.returnObject
        },
        on: {
          change: function($event) {
            return _vm.$emit("input", _vm.model)
          },
          "update:search-input": function($event) {
            return _vm.$emit("search-input", $event)
          },
          "click:append-outer": function($event) {
            return _vm.$emit("click:append-outer")
          },
          focus: function($event) {
            _vm.attrs["background-color"] = _vm.backgroundColor
            _vm.focused = true
          },
          blur: function($event) {
            _vm.attrs["background-color"] = ""
            _vm.focused = false
          }
        },
        model: {
          value: _vm.model,
          callback: function($$v) {
            _vm.model = $$v
          },
          expression: "model"
        }
      },
      "v-autocomplete",
      _vm.elemAttrs,
      false
    )
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }