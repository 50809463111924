export default {
    accountMenuItems: [
        {
            route: "Account Dashboard",
            title: "Dashboard",
            icon: "mdi-view-dashboard",
        },
        {
            route: "Account Company",
            title: "Company",
            icon: "mdi-home-city",
        },
        {
            route: "Account Properties",
            title: "Properties",
            icon: "mdi-domain",
        },
        {
            route: "Account Users",
            title: "Users",
            icon: "mdi-account-box-multiple",
        },
        {
            route: "Account Teams",
            title: "Teams",
            icon: "mdi-handshake",
        },
        {
            route: "Account Profile",
            title: "My Profile",
            icon: "mdi-account-circle",
        },
        {
            route: "Account Billing",
            title: "Billing",
            icon: "mdi-bank",
        },
        {
            route: "Account Reports",
            title: "Reports",
            icon: "mdi-chart-line",
        },
    ],

    profileMenuItems: [
        {
            id: "update-profile",
            title: "Update Profile",
            icon: "mdi-account",
            component: "update-profile",
        },
        {
            id: "change-password",
            title: "Change Password",
            icon: "mdi-lock",
            component: "change-password",
        },
        {
            id: "update-properties",
            title: "Update Properties",
            icon: "mdi-domain",
            component: "update-properties",
        },
        {
            id: "ring-central",
            title: "Ring Central",
            icon: "mdi-phone",
            component: "ring-central",
        },
        {
            id: "role-permissions",
            title: "Role and Permissions",
            icon: "mdi-account-lock",
            component: "role-permissions",
        },
        {
            id: "my-details",
            title: "My Details",
            icon: "mdi-account-details",
            component: "my-details",
        },
    ],
};
