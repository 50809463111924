<template>
    <v-navigation-drawer
        :mini-variant.sync="miniVariant"
        :mini-variant-width="miniVariantWidth"
        app
        :class="navigationClass.background"
        clipped
        :expand-on-hover="$vuetify.breakpoint.mobile ? false : !drawer"
        :value="$vuetify.breakpoint.mobile ? drawer : true"
        @input="$emit('update:drawer', $event)"
    >
        <template v-slot:prepend>
            <sidebar-section
                :text="sectionText"
                :mini-variant="miniVariant"
            ></sidebar-section>
        </template>

        <v-list
            dense
            :class="navigationClass.listItem"
            class="mr-n2 ml-n2"
            :shaped="!miniVariant"
            :ripple="false"
        >
            <template v-for="(item, i) in items">
                <sidebar-group
                    v-if="item.hasOwnProperty('group')"
                    :item="item"
                    :key="i"
                    :group-active-class="navigationClass.groupActive"
                    :list-item-text-class="navigationClass.listItemText"
                ></sidebar-group>
                <sidebar-item v-else :item="item" :key="i"></sidebar-item>
            </template>
        </v-list>
    </v-navigation-drawer>
</template>
<script>
import SidebarGroup from "./components/SidebarGroup";
import SidebarItem from "./components/SidebarItem";
import SidebarSection from "./components/SidebarSection";

export default {
    name: "psi-sidebar-navigation",
    components: {
        SidebarGroup,
        SidebarItem,
        SidebarSection,
    },
    props: {
        drawer: {
            validator: (val) => {
                return val === null || typeof val === "boolean";
            },
            required: true,
        },
        items: {
            type: Array,
            required: true,
        },
        miniVariantWidth: {
            type: Number,
            required: false,
            default: 92,
        },
        navigationClass: {
            type: Object,
            required: true,
        },
        sectionText: {
            type: String,
            required: false,
            default: "",
        },
    },
    data() {
        return {
            miniVariant: false,
        };
    },
};
</script>

<style scoped>
</style>