import uuid from 'uuid';

export default {
    companyName: 'Pacific Screening, Inc',
    // Set a selected NavItemId to exclusively include that navItem
    // selectedNavItemId: "screening-credit",

    /**
     * navItems define the Screen Processing Quicklinks and Tiles
     */
    navItems: [
        {
            id: 'screening-scorecard',
            visible: true,
            title: 'Scorecard',
            headingColor: 'accent darken-1',
            icon: 'mdi-clipboard-list-outline',
            class: 'error--text text--lighten-2',
            component: 'screening-scorecard',
            params: {
                scorecard: 'screen.scorecard',
            },
            maxHeight: 590,
            layout: 'main',
            roles: [],
            colsClass: 'col-lg-12 col-xl-12 col-md-12 col-sm-12 col-12',
        },
        {
            id: 'screening-household',
            visible: true,
            title: 'Household',
            headingColor: 'accent darken-1',
            icon: 'mdi-clipboard-text-multiple',
            class: 'alternate3--text text--darken-2',
            component: 'screening-household',
            params: {
                summary: 'household_summary',
                rent: 'applicant.application.rent',
            },
            maxHeight: 300,
            layout: 'main',
            group: '2',
            colsClass: 'col-lg-6 col-xl-6 col-md-6 col-sm-6 col-12',
        },
        {
            id: 'screening-applicant',
            title: 'Applicant',
            visible: true,
            headingColor: 'accent darken-1',
            icon: 'mdi-clipboard-account',
            class: 'alternate1--text text--darken-2',
            params: {
                applicant: 'applicant',
            },
            layout: 'main',
            maxHeight: 380,
            component: 'screening-process-applicant',
            group: '2',
            colsClass: 'col-lg-6 col-xl-6 col-md-6 col-sm-6 col-12',
        },

        {
            id: 'screening-summary',
            title: 'Summary',
            visible: true,
            headingColor: 'accent darken-1',
            icon: 'mdi-clipboard-text',
            class: 'alternate4--text text--darken-2',
            component: 'screening-summary',
            params: {
                summary: 'screen.summary.summary',
            },
            maxHeight: 391,
            layout: 'main',
            colsClass: 'col-lg-6 col-xl-6 col-md-6 col-sm-6 col-12',
        },

        {
            id: 'screening-details',
            title: 'Details',
            visible: true,
            headingColor: 'accent darken-1',
            icon: 'mdi-card-account-details',
            class: 'secondary--text text--darken-2',
            component: 'screening-details',
            params: {
                items: 'details.items',
                products: 'products',
                applicant: 'applicant',
                property: 'property',
                screen: 'screen',
            },
            maxHeight: 300,
            layout: 'sidebar',
            colsClass: 'col-lg-6 col-xl-6 col-md-6 col-sm-6 col-12',
        },
        {
            id: 'screening-flags',
            title: 'Flags',
            visible: true,
            headingColor: 'accent darken-1',
            icon: 'mdi-flag-variant',
            class: 'warning--text text--darken-1',
            component: 'screening-flags',
            params: {
                flags: 'flags',
            },
            maxHeight: 198,
            layout: 'sidebar',
            colsClass: 'col-lg-6 col-xl-6 col-md-6 col-sm-6 col-12',
        },
        {
            id: 'screening-tasks',
            title: 'Tasks',
            visible: true,
            headingColor: 'accent darken-1 darken-2',
            icon: 'mdi-format-list-checks',
            class: 'alternate2--text text--lighten-2',
            component: 'screening-tasks',
            params: {
                progress: 'screen.progress',
                tasks: 'screen.tasks',
            },
            height: 1000,
            maxHeight: 1200,
            layout: 'sidebar',
            colsClass: 'col-lg-6 col-xl-6 col-md-6 col-sm-6 col-12',
        },

        {
            id: 'screening-activities',
            component: 'screening-activities',
            visible: true,
            params: {
                items: 'activities',
            },
            title: 'Activities',
            headingColor: 'accent darken-1',
            icon: 'mdi-timeline',
            class: 'alternate4--text text--darken-2',
            layout: 'sidebar',
            height: 1200,
            maxHeight: 1200,
            colsClass: 'col-lg-6 col-xl-6 col-md-6 col-sm-6 col-12',
        },

        {
            id: 'screening-notifications',
            title: 'Notifications',
            visible: true,
            headingColor: 'accent darken-1',
            icon: 'mdi-bell',
            class: 'warning--text text--darken-2',
            component: 'screening-notifications',
            params: {
                notifications: 'screen.notifications',
            },
            layout: 'sidebar',
            maxHeight: 600,
            group: '3',
            colsClass: 'col-lg-6 col-xl-6 col-md-6 col-sm-6 col-12',
        },
        {
            id: 'screening-contacts',
            title: 'Contacts',
            visible: true,
            headingColor: 'accent darken-1',
            icon: 'mdi-contacts',
            class: 'secondary--text text--lighten-2',
            component: 'screening-contacts',
            params: {
                contacts: 'contacts',
            },
            maxHeight: 600,
            layout: 'sidebar',
            colsClass: 'col-lg-6 col-xl-6 col-md-6 col-sm-6 col-12',
        },
        {
            id: 'screening-messages',
            title: 'Messages',
            visible: true,
            headingColor: 'accent darken-1',
            icon: 'mdi-comment-text-multiple',
            class: 'alternate2--text text--darken-2',
            component: 'screening-blank',
            layout: 'sidebar',
            maxHeight: 600,
            colsClass: 'col-lg-6 col-xl-6 col-md-6 col-sm-6 col-12',
        },
        {
            id: 'screening-notes',
            title: 'Notes',
            visible: true,
            headingColor: 'accent darken-1',
            icon: 'mdi-note-multiple',
            class: 'error--text text--darken-1',
            layout: 'main',
            component: 'screening-notes-listing',
            params: {
                notes: 'screen.all_notes',
            },
            maxHeight: 600,
            height: 800,
            colsClass: 'col-lg-12 col-xl-12 col-md-12 col-sm-12 col-12',
        },
        {
            id: 'screening-rental-history',
            title: 'Rental History',
            visible: true,
            headingColor: 'primary darken-1',
            icon: 'mdi-home-map-marker',
            class: 'info--text text--darken-1',
            layout: 'main',
            maxHeight: 600,
            params: {
                addresses: 'applicant.addresses',
                order_id: 'order_id',
            },
            component: 'screening-rental-history',
            colsClass: 'col-lg-12 col-xl-12 col-md-12 col-sm-12 col-12',
        },
        {
            id: 'screening-employment-history',
            title: 'Employment',
            visible: true,
            headingColor: 'accent darken-1',
            icon: 'mdi-briefcase-account',
            class: 'alternate1--text text--darken-2',
            layout: 'main',
            maxHeight: 300,
            params: {
                employers: 'applicant.employers',
                order_id: 'order_id',
            },
            component: 'screening-employment-history',
            colsClass: 'col-lg-12 col-xl-12 col-md-12 col-sm-12 col-12',
        },
        {
            id: 'screening-public-records',
            title: 'Public Records',
            visible: true,
            headingColor: 'accent darken-1',
            icon: 'mdi-scale-balance',
            class: 'alternate5--text text--lighten-2',
            layout: 'main',
            params: {
                search: 'screen.search.result',
                summary: 'screen.summary.summary',
                reports: 'screen.reports',
            },
            maxHeight: 600,
            component: 'screening-process-crime',
            colsClass: 'col-lg-12 col-xl-12 col-md-12 col-sm-12 col-12',
        },
        {
            id: 'screening-credit',
            title: 'Credit',
            visible: true,
            headingColor: 'accent darken-1',
            icon: 'mdi-file-chart',
            class: 'warning--text text--darken-3',
            layout: 'main',
            params: {
                search: 'screen.search.result',
                summary: 'screen.summary.summary',
                reports: 'screen.reports',
            },
            maxHeight: 600,
            component: 'screening-process-credit',
            colsClass: 'col-lg-12 col-xl-12 col-md-12 col-sm-12 col-12',
        },
        {
            id: 'screening-files',
            title: 'Files',
            visible: true,
            headingColor: 'accent darken-1',
            icon: 'mdi-file-document-multiple',
            class: 'alternate3--text text--lighten-2',
            layout: 'main',
            maxHeight: 600,
            component: 'screening-blank',
            colsClass: 'col-lg-12 col-xl-12 col-md-12 col-sm-12 col-12',
        },
        {
            id: 'screening-invoice',
            title: 'Invoice',
            visible: true,
            headingColor: 'accent darken-1',
            icon: 'mdi-currency-usd',
            component: 'screening-invoice',
            class: 'alternate5--text text--lighten-2',
            params: {
                invoices: 'invoices',
                invoice_amount: 'invoice_amount',
                due_amount: 'due_amount',
                paid_amount: 'paid_amount',
                property: 'property',
                payment: 'payment',
            },
            layout: 'main',
            maxHeight: 300,
            colsClass: 'col-lg-12 col-xl-12 col-md-12 col-sm-12 col-12',
        },
    ],
    processToolBarItems: [
        {
            type: 'button',
            icon: 'mdi-cellphone',
            tooltip: 'Call',
            event: 'screening-toolbar-call',
        },
        {
            type: 'button',
            icon: 'mdi-message',
            tooltip: 'Messages',
            event: 'screening-toolbar-messages',
        },
        {
            type: 'button',
            icon: 'mdi-note-plus',
            tooltip: 'Add Note',
            event: 'screening-toolbar-add-note',
        },
        {
            type: 'divider',
        },
        {
            type: 'button',
            icon: 'mdi-share-variant',
            tooltip: 'Share',
            event: 'screening-toolbar-share',
        },
        {
            type: 'button',
            icon: 'mdi-file-pdf-box',
            tooltip: 'Print',
            event: 'screening-toolbar-print',
        },
        {
            type: 'button',
            icon: 'mdi-help-circle',
            tooltip: 'Guide',
            event: 'screening-toolbar-view-guide',
        },
        {
            type: 'divider',
        },
    ],
    activities: {
        Screen: { color: '#57748f', icon: 'mdi-clipboard-account' },
        Product: { color: '#515e85', icon: 'mdi-tag' },
        File: { color: '#4f6f78', icon: 'mdi-file' },
        Note: { color: '#578f8c', icon: 'mdi-pencil-box' },
        Action: { color: '#518573', icon: 'mdi-flash' },
        Report: { color: 'secondary', icon: 'mdi-chart-bar' },
        Search: { color: '#062931', icon: 'mdi-magnify' },
        Notification: { color: '#25445d', icon: 'mdi-bell' },
        'Invoice Item': { color: '#00192f', icon: 'mdi-cash-usd' },
    },
    contacts: {
        Applicant: {
            color: 'alternate3',
        },
        Landlord: {
            color: 'alternate1',
        },
        Employer: {
            color: 'alternate2',
        },
        Property: {
            color: 'alternate4',
        },
        Other: {
            color: 'alternate5',
        },
    },
    filters: [
        {
            icon: 'mdi-account',
            name: 'assigned_to',
            label: 'Assigned To',
            default: '',
            value: '',
            itemText: 'name',
            itemValue: 'id',
            items: [],
        },
        {
            icon: 'mdi-clipboard-check',
            name: 'status',
            label: 'Status',
            default: '',
            value: '',
            itemText: 'status',
            itemValue: 'status',
            items: [
                {
                    status: 'Pending',
                },
                {
                    status: 'On Hold',
                },
                {
                    status: 'Completed',
                },
            ],
        },

        {
            icon: 'mdi-domain',
            name: 'company',
            label: 'Company',
            default: '',
            value: '',
            itemText: 'name',
            itemValue: 'id',
            items: [],
        },
        {
            icon: 'mdi-home-city',
            name: 'property',
            label: 'Property',
            default: '',
            value: '',
            itemText: 'name',
            itemValue: 'id',
            items: [],
        },

        {
            icon: 'mdi-calendar-range',
            name: 'date_range',
            label: 'Date Range',
            default: 'Last 14 days',
            value: 'Last 14 days',
            itemText: 'range',
            itemValue: 'range',
            items: [
                {
                    range: 'Last 4 hours (new)',
                },
                {
                    range: 'Last 7 days',
                },
                {
                    range: 'Last 14 days',
                },
                {
                    range: 'Last 30 days',
                },
                {
                    range: 'Last 60 days',
                },
                {
                    range: 'Last 90 days',
                },
                {
                    range: 'This Month',
                },
                {
                    range: 'Last Month',
                },
                {
                    range: 'This Quarter',
                },
                {
                    range: 'Last Quarter',
                },
                {
                    range: 'This Year',
                },
                {
                    range: 'Last Year',
                },
            ],
        },
        {
            icon: 'mdi-clock-alert',
            name: 'time_pending',
            label: 'Time Pending',
            default: '',
            value: '',
            itemText: 'pending',
            itemValue: 'pending',
            items: [
                {
                    pending: '< 8 hours',
                },
                {
                    pending: '8-24 hours',
                },
                {
                    pending: '24-48 hours',
                },
                {
                    pending: '> 48 hours',
                },
            ],
        },
    ],
    quicklinks: [
        {
            icon: 'mdi-account',
            title: 'My Orders',
            class: 'warning--text text--darken-2',
        },
        {
            icon: 'mdi-timer-sand',
            title: 'Pending Orders',
            class: 'accent--text text--darken-1',
        },
        {
            icon: 'mdi-clock-alert',
            title: 'Overdue Orders',
            class: 'error--text text--darken-2',
        },
        {
            icon: 'mdi-clipboard-alert',
            title: 'New Orders',
            class: 'alternate3--text text--darken-2',
        },
    ],
    actions: [
        {
            icon: 'mdi-clipboard-text-multiple',
            title: 'Group Applicants',
        },
        {
            divider: true,
        },
        {
            icon: 'mdi-clipboard-remove',
            title: 'Delete Applicants',
            class: 'error--text text--darken-2',
        },
    ],
    newApplicationOptions: [
        {
            name: 'upload',
            order: 1,
            type: 'tenant',
            title: 'Upload Application',
            logo:
                'https://pacific-screening.imgix.net/apm-fb-logo.png?auto=enhance,compress,format&width=60&q=60',
            action: 'Upload Application',
            icon: 'mdi-file-upload',
            alert:
                'Upload an application from multiple sources including AppFolio.',
            to: { name: 'Upload Application' },
            disabled: true,
        },
        {
            name: 'invite',
            type: 'tenant',
            order: 2,
            title: 'Invite Applicants',
            action: 'Invite Applicants',
            color: 'accent',
            icon: 'mdi-email-send',
            alert:
                'Create an online application invitation for multiple applicants.',
            to: { name: 'Application Invite' },
            disabled: false,
        },
        {
            name: 'create',
            type: 'tenant',
            order: 3,
            title: 'Create Application',
            action: 'Create Application',
            color: 'alternate4',
            icon: 'mdi-clipboard-account',
            alert: 'Create an application using the application entry forms.',
            to: { name: 'Application Create' },
            disabled: false,
        },
        {
            name: 'import',
            type: 'tenant',
            order: 4,
            title: 'Rent Manager',
            action: 'Import Application',
            logo:
                'https://pacific-screening.imgix.net/rm-logo-sm.png?auto=enhance,compress,format&width=60&q=60',
            icon: 'mdi-application-import',
            alert:
                'Import applications entered in Rent Manager. Applies to limited companies.',
            to: { name: 'Application Import' },
            disabled: false,
        },
        {
            name: 'upload',
            order: 1,
            type: 'employment',
            title: 'Upload Application',
            color: 'accent',
            action: 'Upload Application',
            icon: 'mdi-file-upload',
            alert: 'Upload an employment screening application.',
            to: { name: 'Upload Employment Application' },
            disabled: true,
        },
        {
            name: 'create',
            type: 'employment',
            order: 3,
            title: 'Create Application',
            action: 'Create Application',
            color: 'alternate4',
            icon: 'mdi-clipboard-account',
            alert: 'Create an application using the application entry forms.',
            to: { name: 'Application Create' },
            disabled: false,
        },
    ],
    summaryFilters: [
        {
            icon: 'mdi-radiobox-marked',
            name: 'type',
            label: 'Summary Type',
            default: '',
            value: '',
            itemText: 'type',
            itemValue: 'type',
            items: [
                {
                    type: 'Addresses',
                },
                {
                    type: 'Names',
                },
                {
                    type: 'Birthdates',
                },
            ],
        },
    ],

    notesFilters: [
        {
            icon: 'mdi-radiobox-marked',
            name: 'type',
            label: 'Note Type',
            default: '',
            value: '',
            itemText: 'type',
            itemValue: 'type',
            items: [
                {
                    type: 'Screen',
                },
                {
                    type: 'Applicant Address',
                },
                {
                    type: 'Applicant Employer',
                },
                {
                    type: 'Screen Reference',
                },
            ],
        },
    ],
    rentalAddressToolBarItems: [
        {
            name: 'add_note',
            icon: 'mdi-note-plus',
            tooltip: 'Add Note',
            disabled: false,
        },
        {
            name: 'landlord_lookup',
            icon: 'mdi-home-search',
            tooltip: 'Landlord Lookup',
            disabled: false,
        },
        {
            name: 'create_reference',
            icon: 'mdi-card-account-details-star-outline',
            tooltip: 'Create Rental Reference',
            disabled: false,
        },
        {
            name: 'upload_reference',
            icon: 'mdi-file-upload',
            tooltip: 'Upload Rental Reference',
            disabled: false,
        },
        {
            name: 'stop_sending',
            icon: 'mdi-send-lock',
            tooltip: 'Stop Send Outs',
            disabled: false,
        },
        {
            name: 'remove_address',
            icon: 'mdi-delete',
            tooltip: 'Delete Address',
            disabled: false,
        },
    ],
    employmentEmployerToolBarItems: [
        {
            name: 'add_note',
            icon: 'mdi-note-plus',
            tooltip: 'Add Note',
            disabled: false,
        },
        {
            name: 'employer_lookup',
            icon: 'mdi-briefcase-search',
            tooltip: 'Employer Lookup',
            disabled: false,
        },
        {
            name: 'create_reference',
            icon: 'mdi-card-account-details-star-outline',
            tooltip: 'Create Employer Reference',
            disabled: false,
        },
        {
            name: 'upload_reference',
            icon: 'mdi-file-upload',
            tooltip: 'Upload Employer Reference',
            disabled: false,
        },
        {
            name: 'stop_sending',
            icon: 'mdi-send-lock',
            tooltip: 'Stop Send Outs',
            disabled: false,
        },
        {
            name: 'remove_employer',
            icon: 'mdi-delete',
            tooltip: 'Delete Employer',
            disabled: false,
        },
    ],
    rentalAddressMenuOptions: [
        {
            id: uuid.v4(),
            title: 'Activity',
            icon: 'mdi-timeline-check',
            params: {
                activity: 'activity',
            },
            component: 'rental-employment-activity',
        },
        {
            id: uuid.v4(),
            title: 'Contacts',
            icon: 'mdi-account-multiple',
            params: {
                contacts: 'contacts',
            },
            component: 'rental-employment-contacts',
        },
        {
            id: uuid.v4(),
            title: 'Notes',
            icon: 'mdi-note-multiple',
            params: {
                notes: 'notes',
            },
            component: 'rental-employment-notes',
        },
        {
            id: uuid.v4(),
            title: 'Messages',
            icon: 'mdi-forum',
            params: { messages: 'messages' },
            component: 'rental-employment-messages',
        },
        {
            id: uuid.v4(),
            title: 'Reference',
            icon: 'mdi-card-account-details-star-outline',
            params: {
                references: 'references',
            },
            component: 'reference-widget',
        },
    ],
    employmentEmployerMenuOptions: [
        {
            id: uuid.v4(),
            title: 'Activity',
            icon: 'mdi-timeline-check',
            params: {
                activity: 'activity',
            },
            component: 'rental-employment-activity',
        },
        {
            id: uuid.v4(),
            title: 'Contacts',
            icon: 'mdi-account-multiple',
            params: {
                contacts: 'contacts',
            },
            component: 'rental-employment-contacts',
        },
        {
            id: uuid.v4(),
            title: 'Notes',
            icon: 'mdi-note-multiple',
            params: {
                notes: 'notes',
            },
            component: 'rental-employment-notes',
        },
        {
            id: uuid.v4(),
            title: 'Messages',
            icon: 'mdi-forum',
            params: { messages: 'messages' },
            component: 'rental-employment-messages',
        },
        {
            id: uuid.v4(),
            title: 'Reference',
            icon: 'mdi-card-account-details-star-outline',
            params: {
                references: 'references',
            },
            component: 'reference-widget',
        },
    ],
    contactActionsMenuOptions: [
        { title: 'Unlink', icon: 'mdi-link-variant-off' },
        { title: 'Edit', icon: 'mdi-account-edit' },
        { title: 'Delete', icon: 'mdi-account-remove' },
        { title: 'Make Primary', icon: 'mdi-account-check' },
        { title: 'Rate Contact', icon: 'mdi-account-star' },
    ],
    rentalReferenceTransform: [
        {
            key: 'name',
        },
        {
            key: 'completed_at',
        },
        {
            key: 'rent.stated',
            format: 'currency',
        },
        {
            key: 'rent.actual',
            format: 'currency',
        },
        {
            key: 'move_in.stated',
        },
        {
            key: 'move_in.actual',
        },
        {
            key: 'move_out.stated',
        },
        {
            key: 'move_out.actual',
        },
        {
            key: 'lease_type',
            format: 'uppercase',
        },
        {
            heading: 'Disclosures',
        },
        {
            key: 'complaints.incurred',
            type: 'chip',
            format: 'boolean',
        },
        {
            key: 'covid_deferred_rent.incurred',
            type: 'chip',
            format: 'boolean',
        },
        {
            key: 'nsf_checks.incurred',
            type: 'chip',
            format: 'boolean',
        },
        {
            key: 'past_due_rent.incurred',
            type: 'chip',
            format: 'boolean',
        },
        {
            key: 'damages.incurred',
            type: 'chip',
            format: 'boolean',
        },
        {
            key: 'three_day_notices.incurred',
            type: 'chip',
            format: 'boolean',
        },
        {
            key: 'late_payments.incurred',
            type: 'chip',
            format: 'boolean',
        },
        {
            key: 'unauthorized_occupants',
            type: 'chip',
            format: 'boolean',
        },
        {
            key: 'unauthorized_pets',
            type: 'chip',
            format: 'boolean',
        },
        {
            key: 'rent_again',
            type: 'chip',
            format: 'boolean',
            question_format: 'Positive Good',
        },
        {
            key: 'rental_agreement',
            type: 'chip',
            format: 'boolean',
            question_format: 'Positive Good',
        },
        {
            key: 'proper_notice',
            type: 'chip',
            format: 'boolean',
            question_format: 'Positive Good',
        },
        {
            key: 'vacate_evicted',
            type: 'chip',
            format: 'boolean',
        },

        {
            key: 'proper_notice',
            type: 'chip',
            format: 'boolean',
            question_format: 'Positive Good',
        },
        {
            key: 'good_renter',
            type: 'chip',
            format: 'boolean',
            question_format: 'Positive Good',
        },
    ],
    employerReferenceTransform: [
        {
            key: 'name',
        },
        {
            key: 'completed_at',
        },
        {
            key: 'monthly_income.stated',
            format: 'currency',
        },
        {
            key: 'monthly_income.actual',
            format: 'currency',
        },
        {
            key: 'start_date.actual',
        },
        {
            key: 'start_date.stated',
        },
        {
            key: 'end_date.actual',
        },
        {
            key: 'end_date.stated',
        },
        {
            key: 'would_rehire',
            type: 'chip',
            format: 'boolean',
            question_format: 'Positive Good',
        },
        {
            heading: 'Comments',
        },
        {
            key: 'attendance',
        },
        {
            key: 'comments',
        },
        {
            key: 'completed_assignments',
        },
        {
            key: 'disciplinary_action',
        },
        {
            key: 'job_duties',
        },
        {
            key: 'performance',
        },
        {
            key: 'response_to_criticism',
        },
    ],
};
