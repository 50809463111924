var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-navigation-drawer",
    {
      class: _vm.navigationClass.background,
      attrs: {
        "mini-variant": _vm.miniVariant,
        "mini-variant-width": _vm.miniVariantWidth,
        app: "",
        clipped: "",
        "expand-on-hover": _vm.$vuetify.breakpoint.mobile ? false : !_vm.drawer,
        value: _vm.$vuetify.breakpoint.mobile ? _vm.drawer : true
      },
      on: {
        "update:miniVariant": function($event) {
          _vm.miniVariant = $event
        },
        "update:mini-variant": function($event) {
          _vm.miniVariant = $event
        },
        input: function($event) {
          return _vm.$emit("update:drawer", $event)
        }
      },
      scopedSlots: _vm._u([
        {
          key: "prepend",
          fn: function() {
            return [
              _c("sidebar-section", {
                attrs: {
                  text: _vm.sectionText,
                  "mini-variant": _vm.miniVariant
                }
              })
            ]
          },
          proxy: true
        }
      ])
    },
    [
      _c(
        "v-list",
        {
          staticClass: "mr-n2 ml-n2",
          class: _vm.navigationClass.listItem,
          attrs: { dense: "", shaped: !_vm.miniVariant, ripple: false }
        },
        [
          _vm._l(_vm.items, function(item, i) {
            return [
              item.hasOwnProperty("group")
                ? _c("sidebar-group", {
                    key: i,
                    attrs: {
                      item: item,
                      "group-active-class": _vm.navigationClass.groupActive,
                      "list-item-text-class": _vm.navigationClass.listItemText
                    }
                  })
                : _c("sidebar-item", { key: i, attrs: { item: item } })
            ]
          })
        ],
        2
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }