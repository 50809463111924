import axios from "axios";
import uuid from "uuid";
const httpClient = axios.create({
    baseURL: process.env.VUE_APP_PROTOTYPING
        ? process.env.VUE_APP_MOCK_API_URL
        : process.env.BASE_URL,
    headers: {
        "Content-Type": "application/json",
    },
});

httpClient.interceptors.request.use(
    // Intercept and append the request URL with access to mock data if Mocking
    (config) => {
        // only if we are mocking and relative urls
        if (
            process.env.VUE_APP_PROTOTYPING &&
            config.url.search("http") === -1
        ) {
            config.url += `/data.json?uuid=${uuid.v4()}`; // mock data is on s3 in a data.json object, break cache with uuid
        }
        return config;
    },
    (error) => {
        return Promise.reject(error);
    }
);

export default httpClient;
