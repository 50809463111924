<template>
    <input type="hidden" :name="name" :value="value" />
</template>
<script>
export default {
    name: "psi-form-hidden-field",
    props: {
        name: {
            type: String,
            required: true,
        },
        value: {
            required: true,
        },
    },
};
</script>