// import the api endpoints
import api from "../api";
import get from "lodash.get";

const initialState = () => ({
    screens: [],
    currentScreen: {},
    loading: false,
    screeners: [],
    screenFilters: {},
    screenPage: {},
    application: [], // Applications associated with screens from the portal
    currentApplication: {}, // Application associated with a screen
    applicants: [],
    currentApplicant: {},
    screenSearch:{}
});

const state = initialState();

const getters = {
    screens: (state) => state.screens,
    screeners: (state) => state.screeners,
    screenFilters: (state) => state.screenFilters,
    screenPage: (state) => state.screenPage,
    currentScreen: (state) => state.currentScreen,
    applications: (state) => state.applications,
    currentApplication: (state) => state.currentApplication,
    applicants: (state) => state.applicants,
    currentApplicant: (state) => state.currentApplicant,
    loading: (state) => state.loading,
    screenSearch: (state) => state.screenSearch
};

const actions = {
    reset({ commit }) {
        commit("RESET");
    },
    async saveCurrentScreenAddress({ commit }, address) {
        commit("SET_CURRENT_SCREEN_ADDRESS", address);
    },
    async getCurrentScreen({ commit }, id) {
        try {
            commit("SET_LOADING", true);
            let screen = await api.getScreen(id);
            let application = await api.getApplication(
                get(screen, "applicant.application.id", 0)
            );
            commit("SET_CURRENT_SCREEN", screen);
            commit("SET_CURRENT_APPLICATION", application);

            commit("SET_LOADING", false);
        } catch (error) {
            commit("SET_ERROR", error, { root: true });
            commit("SET_LOADING", false);
        }
    },
    async getApplicants({ commit }) {
        try {
            commit("SET_LOADING", true);
            let applicants = await api.getApplicants();
            commit("SET_APPLICANTS", applicants);
            commit("SET_LOADING", false);
        } catch (error) {
            commit("SET_ERROR", error, { root: true });
        }
    },
    async getCurrentApplicant({ commit }, id) {
        try {
            commit("SET_LOADING", true);
            let applicant = await api.getApplicant(id);
            commit("SET_CURRENT_APPLICANT", applicant);
            commit("SET_LOADING", false);
        } catch (error) {
            commit("SET_ERROR", error, { root: true });
            commit("SET_LOADING", false);
        }
    },
    async getApplications({ commit }) {
        try {
            commit("SET_LOADING", true);
            let applications = await api.getApplications();
            commit("SET_APPLICATIONS", applications);
            commit("SET_LOADING", false);
        } catch (error) {
            commit("SET_ERROR", error, { root: true });
        }
    },
    async getScreens({ commit }, params) {
        try {
            commit("SET_LOADING", true);

            let { filters, page } = await api.getScreens(params);

            commit("SET_SCREENS", page.data);
            commit("SET_SCREEN_FILTERS", filters);
            commit("SET_SCREEN_PAGE", page);

            commit("SET_LOADING", false);
        } catch (error) {
            commit("SET_ERROR", error, { root: true });
            commit("SET_LOADING", false);
        }
    },
    async getScreeners({ commit }, count = 10) {
        try {
            let screeners = await api.getScreeners(count);
            commit("SET_SCREENERS", screeners);
        } catch (error) {
            commit("SET_ERROR", error, { root: true });
        }
    },
    async getScreenSearch({commit}){
        try{
            let screenSearch = await api.getScreenSearch()
            commit("SET_SCREEN_SEARCH",screenSearch);

        } catch(error) {
            commit("SET_ERROR", error, { root: true });
        }
    }
};

const mutations = {
    RESET: (state) => {
        const newState = initialState();
        Object.keys(newState).forEach((key) => {
            state[key] = newState[key];
        });
    },
    SET_SCREENS: (state, payload) => {
        state.screens = payload;
    },
    SET_CURRENT_SCREEN_ADDRESS: (state, payload) => {
        let addresses = state.currentScreen.applicant.addresses;
        const index = addresses.findIndex((item) => item.id === payload.id);
        addresses.splice(index, 1, payload);
        state.currentScreen.applicant.addresses = addresses;
    },
    SET_SCREEN_FILTERS: (state, payload) => {
        state.screenFilters = payload;
    },
    SET_SCREEN_PAGE: (state, payload) => {
        state.screenPage = payload;
    },
    SET_SCREENERS: (state, payload) => {
        state.screeners = payload;
    },
    SET_APPLICATIONS: (state, payload) => {
        state.applications = payload;
    },
    SET_CURRENT_SCREEN: (state, payload) => {
        state.currentScreen = payload;
    },
    SET_CURRENT_APPLICATION: (state, payload) => {
        state.currentApplication = payload;
    },
    SET_CURRENT_APPLICANT: (state, payload) => {
        state.currentApplicant = payload;
    },
    SET_APPLICANTS: (state, payload) => {
        state.applicants = payload;
    },
    SET_LOADING: (state, payload) => {
        state.loading = payload;
    },
    SET_SCREEN_SEARCH: (state,payload) => {
        state.screenSearch = payload;
    }
};

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations,
};
