import { render, staticRenderFns } from "./PsiFormSelectField.vue?vue&type=template&id=2a809daf&"
import script from "./PsiFormSelectField.vue?vue&type=script&lang=js&"
export * from "./PsiFormSelectField.vue?vue&type=script&lang=js&"
import style0 from "./PsiFormSelectField.vue?vue&type=style&index=0&lang=css&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* vuetify-loader */
import installComponents from "!../../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VSelect } from 'vuetify/lib/components/VSelect';
installComponents(component, {VSelect})


/* hot reload */
if (module.hot) {
  var api = require("/home/circleci/project/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('2a809daf')) {
      api.createRecord('2a809daf', component.options)
    } else {
      api.reload('2a809daf', component.options)
    }
    module.hot.accept("./PsiFormSelectField.vue?vue&type=template&id=2a809daf&", function () {
      api.rerender('2a809daf', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/lib/src/components/forms/PsiFormSelectField.vue"
export default component.exports