<template>
    <v-switch inset :color="color" :value="value" @change="changeSwitch">
        <template v-slot:label>
            <v-chip
                small
                v-if="value"
                :color="colorVerified"
                class="text-uppercase"
            >
                <v-icon class="mr-2">mdi-check</v-icon>
                Verified
            </v-chip>
            <v-chip
                v-else
                small
                :color="colorUnverified"
                class="text-uppercase"
            >
                <v-icon class="mr-2">mdi-close</v-icon>
                Not Verified
            </v-chip>
        </template>
    </v-switch>
</template>
<script>
export default {
    name: "psi-form-verify-switch",
    components: {},
    props: {
        value: {
            required: true,
        },
        color: {
            type: String,
            required: false,
            default: "secondary",
        },
        colorVerified: {
            type: String,
            required: false,
            default: "secondary",
        },
        colorUnverified: {
            type: String,
            required: false,
            default: "warning darken-2",
        },
    },
    data() {
        return {};
    },
    methods: {
        changeSwitch(event) {
            this.$emit("input", event == null ? false : event);
        },
    },
};
</script>

<style scoped>
</style>