<template>
    <v-dialog v-model="dialog" max-width="600">
        <psi-dialog
            :title="title"
            :icon="icon"
            v-if="dialog"
            @close="cancel"
            @save="agree"
            :heading-color="headingColor"
        >
            <v-alert
                prominent
                color="primary"
                border="top"
                dark
                outlined
                class="font-weight-bold text-subtitle-1"
            >
                {{ message }}
            </v-alert>

            <template v-slot:actions>
                <v-btn class="mr-4" @click.stop="cancel">Cancel</v-btn>
                <v-btn :color="buttonColor" class="px-4" @click.stop="agree">
                    <v-icon class="mr-1">mdi-check</v-icon>
                    OK
                </v-btn>
            </template>
        </psi-dialog>
    </v-dialog>
</template>
<script>
export default {
    name: "psi-confirm-dialog",
    components: {},
    data() {
        return {
            dialog: false,
            title: "",
            message: "",
            icon: "mdi-sticker-check-outline",
            headingColor: "primary",
            buttonColor: "primary",
            resolve: null,
            reject: null,
        };
    },
    methods: {
        async open(title, message, options) {
            this.dialog = true;
            this.title = title;
            this.headingColor = this.$_.get(options, "headingColor", "primary");
            this.buttonColor = this.$_.get(options, "buttonColor", "primary");
            this.icon = this.$_.get(
                options,
                "icon",
                "mdi-sticker-check-outline"
            );
            this.message = message;
            return new Promise((resolve, reject) => {
                this.resolve = resolve;
                this.reject = reject;
            });
        },
        agree() {
            this.resolve(true);
            this.dialog = false;
        },
        cancel() {
            this.resolve(false);
            this.dialog = false;
        },
    },
};
</script>

<style scoped>
</style>