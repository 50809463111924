var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-menu",
    {
      attrs: {
        origin: "right top",
        transition: "scale-transition",
        "close-on-content-click": false,
        bottom: "",
        left: "",
        "nudge-top": "-40"
      },
      scopedSlots: _vm._u([
        {
          key: "activator",
          fn: function(ref) {
            var on = ref.on
            return [
              _vm.menuIcon || _vm.menuAvatar
                ? _c(
                    "v-btn",
                    _vm._g(
                      {
                        class: _vm.btnClass,
                        attrs: {
                          tile: "",
                          large: _vm.large,
                          plain: "",
                          ripple: false,
                          elevation: "0",
                          disabled: _vm.disabled,
                          "retain-focus-on-click": ""
                        }
                      },
                      on
                    ),
                    [
                      _vm.menuIcon
                        ? _c("v-icon", { attrs: { large: "" } }, [
                            _vm._v(_vm._s(_vm.menuIcon))
                          ])
                        : _vm._e(),
                      _vm.menuAvatar
                        ? _c("v-avatar", { attrs: { size: "36" } }, [
                            _c("img", { attrs: { src: _vm.menuAvatar } })
                          ])
                        : _vm._e(),
                      _c(
                        "v-icon",
                        { staticClass: "mt-3", attrs: { small: "" } },
                        [_vm._v("mdi-menu-down")]
                      )
                    ],
                    1
                  )
                : _vm._e(),
              _vm.icon
                ? _c(
                    "v-btn",
                    _vm._g(
                      {
                        staticClass: "mx-2",
                        attrs: {
                          icon: "",
                          large: !_vm.small,
                          disabled: _vm.disabled
                        }
                      },
                      on
                    ),
                    [
                      _c("v-icon", {
                        class: _vm.iconClass,
                        attrs: { large: _vm.large, small: _vm.small },
                        domProps: { textContent: _vm._s(_vm.icon) }
                      })
                    ],
                    1
                  )
                : _vm._e(),
              !_vm.icon && !_vm.menuIcon && !_vm.menuAvatar
                ? _c(
                    "v-btn",
                    _vm._g(
                      {
                        class: _vm.btnClass,
                        attrs: {
                          small: !_vm.large,
                          large: _vm.large,
                          disabled: _vm.disabled
                        }
                      },
                      on
                    ),
                    [
                      _vm.prependIcon
                        ? _c("v-icon", { staticClass: "mr-2" }, [
                            _vm._v(_vm._s(_vm.prependIcon))
                          ])
                        : _vm._e(),
                      _vm._v(_vm._s(_vm.btnLabel) + " "),
                      _c(
                        "v-icon",
                        { staticClass: "ml-2", attrs: { small: "" } },
                        [_vm._v("mdi-chevron-down")]
                      )
                    ],
                    1
                  )
                : _vm._e()
            ]
          }
        }
      ]),
      model: {
        value: _vm.menu,
        callback: function($$v) {
          _vm.menu = $$v
        },
        expression: "menu"
      }
    },
    [
      _c(
        "div",
        { style: "width: " + _vm.minWidth + "px" },
        [
          _c(
            "div",
            {
              staticClass: "d-flex justify-space-between px-4 py-2",
              class: _vm.headingColor
            },
            [
              _c("div", [
                _c("h4", {
                  staticClass: "white--text font-weight-bold",
                  domProps: { textContent: _vm._s(_vm.heading) }
                }),
                _vm.subheading
                  ? _c("h5", {
                      staticClass: "white--text font-weight-light",
                      domProps: { textContent: _vm._s(_vm.subheading) }
                    })
                  : _vm._e()
              ]),
              _vm.indicator
                ? _c(
                    "v-chip",
                    { class: _vm.indicator.class, attrs: { small: "" } },
                    [_vm._v(_vm._s(_vm.indicator.label))]
                  )
                : _vm._e(),
              _vm.headingAvatar
                ? _c("v-avatar", { attrs: { size: "36" } }, [
                    _c("img", { attrs: { src: _vm.headingAvatar } })
                  ])
                : _vm._e()
            ],
            1
          ),
          _c(
            "v-list",
            { attrs: { dense: "", "min-width": _vm.minWidth } },
            [
              _vm._l(_vm.items, function(item, index) {
                return [
                  "divider" in item
                    ? _c("v-divider", { key: "list-divider-" + index })
                    : _vm._e(),
                  _vm.$_.get(item, "subitems", []).length > 0
                    ? _c(
                        "v-list-group",
                        {
                          key: "list-group-" + index,
                          staticClass: "list-group",
                          attrs: {
                            value: _vm.$_.get(item, "open", false),
                            "prepend-icon": item.icon
                          },
                          scopedSlots: _vm._u(
                            [
                              {
                                key: "activator",
                                fn: function() {
                                  return [
                                    _c(
                                      "v-list-item-content",
                                      [
                                        _c("v-list-item-title", {
                                          staticClass: "text-body-2",
                                          domProps: {
                                            textContent: _vm._s(item.title)
                                          }
                                        })
                                      ],
                                      1
                                    )
                                  ]
                                },
                                proxy: true
                              }
                            ],
                            null,
                            true
                          )
                        },
                        [
                          _c(
                            "v-list-item-group",
                            { staticClass: "ml-3" },
                            [
                              _vm._l(item.subitems, function(subitem, j) {
                                return [
                                  _c(
                                    "v-list-item",
                                    {
                                      key: "list-item-" + index + "-" + j,
                                      staticClass: "list-item",
                                      attrs: {
                                        to: _vm.$_.get(item, "to", ""),
                                        href: _vm.$_.get(item, "href", ""),
                                        link: ""
                                      },
                                      on: {
                                        click: function($event) {
                                          _vm.$emit("action", item, subitem)
                                          _vm.menu = false
                                        }
                                      }
                                    },
                                    [
                                      _c(
                                        "v-list-item-title",
                                        { staticClass: "list-item-text" },
                                        [
                                          _c("v-icon", {
                                            staticClass: "mr-3",
                                            class: _vm.$_.get(
                                              subitem,
                                              "class",
                                              ""
                                            ),
                                            domProps: {
                                              textContent: _vm._s(subitem.icon)
                                            }
                                          }),
                                          _c(
                                            "span",
                                            {
                                              staticClass: "text-body-2",
                                              class: _vm.$_.get(
                                                subitem,
                                                "class",
                                                ""
                                              )
                                            },
                                            [_vm._v(_vm._s(subitem.title))]
                                          )
                                        ],
                                        1
                                      )
                                    ],
                                    1
                                  )
                                ]
                              })
                            ],
                            2
                          )
                        ],
                        1
                      )
                    : _vm._e(),
                  !_vm.$_.has(item, "divider") &&
                  _vm.$_.get(item, "subitems", []).length === 0
                    ? _c(
                        "v-list-item",
                        {
                          key: "list-item-" + index,
                          staticClass: "list-item",
                          attrs: {
                            to: _vm.$_.get(item, "to", ""),
                            href: _vm.$_.get(item, "href", ""),
                            link: ""
                          },
                          on: {
                            click: function($event) {
                              _vm.$emit("action", item)
                              _vm.menu = false
                            }
                          }
                        },
                        [
                          _c(
                            "v-list-item-title",
                            { staticClass: "list-item-text" },
                            [
                              _c("v-icon", {
                                staticClass: "mr-3",
                                class: _vm.$_.get(item, "class", ""),
                                domProps: { textContent: _vm._s(item.icon) }
                              }),
                              _c(
                                "span",
                                {
                                  staticClass: "text-body-2",
                                  class: _vm.$_.get(item, "class", "")
                                },
                                [_vm._v(_vm._s(item.title))]
                              )
                            ],
                            1
                          )
                        ],
                        1
                      )
                    : _vm._e()
                ]
              })
            ],
            2
          )
        ],
        1
      )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }