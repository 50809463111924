<template>
    <v-menu
        origin="right top"
        transition="scale-transition"
        :close-on-content-click="false"
        bottom
        left
        nudge-top="-40"
        v-model="menu"
    >
        <template v-slot:activator="{ on }">
            <v-btn
                v-if="menuIcon || menuAvatar"
                v-on="on"
                :class="btnClass"
                tile
                :large="large"
                plain
                :ripple="false"
                elevation="0"
                :disabled="disabled"
                retain-focus-on-click
            >
                <v-icon large v-if="menuIcon">{{ menuIcon }}</v-icon>
                <v-avatar v-if="menuAvatar" size="36">
                    <img :src="menuAvatar" />
                </v-avatar>
                <v-icon small class="mt-3">mdi-menu-down</v-icon>
            </v-btn>
            <v-btn
                v-if="icon"
                icon
                :large="!small"
                v-on="on"
                class="mx-2"
                :disabled="disabled"
            >
                <v-icon
                    v-text="icon"
                    :class="iconClass"
                    :large="large"
                    :small="small"
                ></v-icon>
            </v-btn>
            <v-btn
                :small="!large"
                :large="large"
                v-if="!icon && !menuIcon && !menuAvatar"
                :disabled="disabled"
                :class="btnClass"
                v-on="on"
            >
                <v-icon v-if="prependIcon" class="mr-2">{{
                    prependIcon
                }}</v-icon
                >{{ btnLabel }}
                <v-icon small class="ml-2">mdi-chevron-down</v-icon>
            </v-btn>
        </template>

        <div :style="`width: ${minWidth}px`">
            <div
                class="d-flex justify-space-between px-4 py-2"
                :class="headingColor"
            >
                <div>
                    <h4
                        class="white--text font-weight-bold"
                        v-text="heading"
                    ></h4>
                    <h5
                        v-if="subheading"
                        class="white--text font-weight-light"
                        v-text="subheading"
                    ></h5>
                </div>
                <v-chip small :class="indicator.class" v-if="indicator">{{
                    indicator.label
                }}</v-chip>
                <v-avatar size="36" v-if="headingAvatar">
                    <img :src="headingAvatar" />
                </v-avatar>
            </div>
            <v-list dense :min-width="minWidth">
                <template v-for="(item, index) in items">
                    <v-divider
                        v-if="'divider' in item"
                        :key="`list-divider-${index}`"
                    ></v-divider>
                    <v-list-group
                        class="list-group"
                        :key="`list-group-${index}`"
                        v-if="$_.get(item, 'subitems', []).length > 0"
                        :value="$_.get(item, 'open', false)"
                        :prepend-icon="item.icon"
                    >
                        <template v-slot:activator>
                            <v-list-item-content>
                                <v-list-item-title
                                    class="text-body-2"
                                    v-text="item.title"
                                ></v-list-item-title>
                            </v-list-item-content>
                        </template>
                        <v-list-item-group class="ml-3">
                            <template v-for="(subitem, j) in item.subitems">
                                <v-list-item
                                    class="list-item"
                                    :to="$_.get(item, 'to', '')"
                                    :href="$_.get(item, 'href', '')"
                                    link
                                    :key="`list-item-${index}-${j}`"
                                    @click="
                                        $emit('action', item, subitem);
                                        menu = false;
                                    "
                                >
                                    <v-list-item-title class="list-item-text">
                                        <v-icon
                                            v-text="subitem.icon"
                                            :class="
                                                $_.get(subitem, 'class', '')
                                            "
                                            class="mr-3"
                                        ></v-icon>
                                        <!-- <i class="mr-3" :class="item.icon"></i> -->
                                        <span
                                            class="text-body-2"
                                            :class="
                                                $_.get(subitem, 'class', '')
                                            "
                                            >{{ subitem.title }}</span
                                        >
                                    </v-list-item-title>
                                </v-list-item>
                            </template>
                        </v-list-item-group>
                    </v-list-group>
                    <v-list-item
                        v-if="
                            !$_.has(item, 'divider') &&
                            $_.get(item, 'subitems', []).length === 0
                        "
                        class="list-item"
                        :to="$_.get(item, 'to', '')"
                        :href="$_.get(item, 'href', '')"
                        link
                        :key="`list-item-${index}`"
                        @click="
                            $emit('action', item);
                            menu = false;
                        "
                    >
                        <v-list-item-title class="list-item-text">
                            <v-icon
                                v-text="item.icon"
                                :class="$_.get(item, 'class', '')"
                                class="mr-3"
                            ></v-icon>
                            <!-- <i class="mr-3" :class="item.icon"></i> -->
                            <span
                                class="text-body-2"
                                :class="$_.get(item, 'class', '')"
                                >{{ item.title }}</span
                            >
                        </v-list-item-title>
                    </v-list-item>
                </template>
            </v-list>
        </div>
    </v-menu>
</template>
<script>
export default {
    name: "psi-action-drop-down",
    components: {},
    props: {
        btnLabel: {
            type: String,
            required: false,
            default: "Actions",
        },
        btnClass: {
            type: String,
            required: false,
            default: "primary",
        },
        iconClass: {
            type: String,
            required: false,
            default: "grey--text text--lighten-2",
        },
        large: {
            required: false,
            default: false,
        },
        small: {
            type: Boolean,
            required: false,
            default: false,
        },
        indicator: {
            type: Object,
            required: false,
            default: null,
        },
        icon: {
            type: String,
            required: false,
            default: null,
        },
        menuIcon: {
            type: String,
            required: false,
            default: null,
        },
        menuAvatar: {
            type: String,
            required: false,
            default: null,
        },
        headingAvatar: {
            type: String,
            required: false,
            default: null,
        },
        prependIcon: {
            type: String,
            required: false,
            default: "",
        },
        heading: {
            type: String,
            required: true,
        },
        headingColor: {
            type: String,
            required: false,
            default: "secondary",
        },
        subheading: {
            type: String,
            required: false,
            default: null,
        },
        items: {
            type: Array,
            required: true,
        },
        minWidth: {
            type: Number,
            required: false,
            default: 350,
        },
        disabled: {
            type: Boolean,
            required: false,
            default: false,
        },
    },
    data() {
        return {
            menu: false,
        };
    },
};
</script>

<style scoped>
.list-item:hover {
    background: var(--v-secondary-lighten2);
}
.list-group:hover {
    background: var(--v-secondary-lighten2);
}

.v-list-group--active:hover {
    background: white !important;
}
.v-list-group--active .list-item:hover {
    background: var(--v-secondary-lighten4);
    color: var(--v-primary-darken2);
}
.v-list-group--active .list-item:hover .list-item-text {
    color: var(--v-primary-darken2);
}

.list-item:hover .list-item-text {
    color: white;
}
.list-item-active:hover {
    background: white !important;
}
</style>
    