<template>
    <v-list-group
        :group="item.group"
        :active-class="groupActiveClass"
        :ripple="false"
        append-icon="mdi-chevron-down-circle"
    >
        <template v-slot:activator>
            <v-list-item-action>
                <v-icon
                    :class="listItemTextClass"
                    class="list-item-icon ml-2"
                    v-text="item.icon"
                ></v-icon>
            </v-list-item-action>

            <v-list-item-title
                :class="listItemTextClass"
                class="font-weight-regular text-subtitle"
                >{{ item.text }}</v-list-item-title
            >
        </template>
        <v-list-item v-for="(child, index) in item.children" :key="index">
            <sidebar-item :item="child" item-class="ml-n2 mr-n4"></sidebar-item>
        </v-list-item>
    </v-list-group>
</template>
<script>
export default {
    name: "sidebar-group",
    components: {
        SidebarItem: () => import("./SidebarItem"),
    },
    props: {
        item: {
            type: Object,
            required: true,
        },
        groupActiveClass: {
            type: String,
            required: false,
            default: "",
        },
        listItemTextClass: {
            type: String,
            required: false,
            default: "",
        },
    },
};
</script>

<style scoped>
</style>