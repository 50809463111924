// import the api endpoints
import api from "../api";

const initialState = () => ({
    invoices: [],
    invoice: {},
    invoiceFilters: [],
    invoicePage: {},
    loading: false,
});

const state = initialState();

const getters = {
    invoices: (state) => state.invoices,
    invoice: (state) => state.invoice,
    invoiceFilters: (state) => state.invoiceFilters,
    invoicePage: (state) => state.invoicePage,
    loading: (state) => state.loading,
};

const actions = {
    reset({ commit }) {
        commit("RESET");
    },
    async getInvoice({ commit }, id) {
        try {
            commit("SET_LOADING", true);
            let invoice = await api.getInvoice(id);
            commit("SET_INVOICE", invoice);
            commit("SET_LOADING", false);
        } catch (error) {
            commit("SET_ERROR", error, { root: true });
            commit("SET_LOADING", false);
        }
    },
    async getInvoices({ commit }, params) {
        try {
            commit("SET_LOADING", true);

            let { filters, page } = await api.getInvoices(params);

            commit("SET_INVOICE_FILTERS", filters);
            commit("SET_INVOICE_PAGE", page);
            commit("SET_INVOICES", page.data);

            commit("SET_LOADING", false);
        } catch (error) {
            commit("SET_ERROR", error, { root: true });
            commit("SET_LOADING", false);
        }
    },
};

const mutations = {
    RESET: (state) => {
        const newState = initialState();
        Object.keys(newState).forEach((key) => {
            state[key] = newState[key];
        });
    },
    SET_INVOICE: (state, payload) => {
        state.invoice = payload;
    },
    SET_INVOICES: (state, payload) => {
        state.invoices = payload;
    },
    SET_INVOICE_PAGE: (state, payload) => {
        state.invoicePage = payload;
    },
    SET_INVOICE_FILTERS: (state, payload) => {
        state.invoiceFilters = payload;
    },
    SET_LOADING: (state, payload) => {
        state.loading = payload;
    },
};

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations,
};
