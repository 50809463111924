var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-sheet",
    {
      staticClass: "mx-auto mb-6",
      attrs: { elevation: "8", "max-width": _vm.maxWidth }
    },
    [
      _c(
        "v-slide-group",
        {
          staticClass: "pa-1",
          attrs: {
            mandatory: _vm.mandatory,
            "center-active": "",
            "show-arrows": ""
          },
          on: { change: _vm.onChange },
          model: {
            value: _vm.model,
            callback: function($$v) {
              _vm.model = $$v
            },
            expression: "model"
          }
        },
        _vm._l(_vm.items, function(item, index) {
          return _c("v-slide-item", {
            key: index,
            scopedSlots: _vm._u(
              [
                {
                  key: "default",
                  fn: function(ref) {
                    var active = ref.active
                    var toggle = ref.toggle
                    return [
                      _c(
                        "v-card",
                        {
                          staticClass: "ma-2",
                          attrs: {
                            color: active ? _vm.activeColor : _vm.color,
                            height: _vm.contentHeight,
                            width: _vm.contentWidth
                          },
                          on: { click: toggle }
                        },
                        [
                          _c(
                            "v-card-text",
                            [
                              _c(
                                "v-row",
                                {
                                  staticClass: "fill-height",
                                  attrs: { align: "center", justify: "center" }
                                },
                                [
                                  _c(
                                    "v-col",
                                    {
                                      staticClass: "py-0 my-0",
                                      attrs: { cols: "10" }
                                    },
                                    [
                                      _c(
                                        "div",
                                        {
                                          staticClass:
                                            "d-flex align-center justify-center",
                                          class: { "white--text": active }
                                        },
                                        [
                                          _vm.$_.get(item, "icon")
                                            ? _c(
                                                "v-icon",
                                                {
                                                  staticClass: "mr-4",
                                                  class: {
                                                    "white--text": active
                                                  }
                                                },
                                                [_vm._v(_vm._s(item.icon))]
                                              )
                                            : _vm._e(),
                                          _vm.$_.get(item, "icon_filepath")
                                            ? _c(
                                                "v-avatar",
                                                {
                                                  staticClass: "mr-4",
                                                  class: {
                                                    "white--text": active
                                                  }
                                                },
                                                [
                                                  _c("img", {
                                                    attrs: {
                                                      src: item.icon_filepath
                                                    }
                                                  })
                                                ]
                                              )
                                            : _vm._e(),
                                          _c(
                                            "div",
                                            {
                                              staticClass: "d-flex flex-column"
                                            },
                                            [
                                              _vm._t("item", null, {
                                                item: item,
                                                active: active
                                              }),
                                              _vm.$scopedSlots.subitem
                                                ? _c("v-divider", {
                                                    staticClass: "my-1",
                                                    class: {
                                                      white: active
                                                    }
                                                  })
                                                : _vm._e(),
                                              _vm._t("subitem", null, {
                                                item: item
                                              })
                                            ],
                                            2
                                          )
                                        ],
                                        1
                                      )
                                    ]
                                  ),
                                  _c(
                                    "v-col",
                                    {
                                      staticClass: "text-center align-center",
                                      attrs: { cols: "2" }
                                    },
                                    [
                                      _c(
                                        "v-scale-transition",
                                        [
                                          active
                                            ? _c("v-icon", {
                                                attrs: {
                                                  color: _vm.activeIconColor,
                                                  size: "36"
                                                },
                                                domProps: {
                                                  textContent: _vm._s(
                                                    "mdi-check-circle-outline"
                                                  )
                                                }
                                              })
                                            : _vm._e()
                                        ],
                                        1
                                      )
                                    ],
                                    1
                                  )
                                ],
                                1
                              )
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ]
                  }
                }
              ],
              null,
              true
            )
          })
        }),
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }