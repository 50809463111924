<template>
    <v-row>
        <v-col :cols="labelCols">
            <v-card
                flat
                color="primary lighten-2"
                height="100%"
                style="opacity: 0.6"
            >
                <v-card-text>
                    <div class="d-flex">
                        <v-spacer></v-spacer>
                        <h3 class="text-uppercase primary--text text--darken-2">
                            {{ title }}
                        </h3>
                    </div>
                    <div class="d-flex mt-4">
                        <v-spacer></v-spacer>
                        <v-avatar rounded size="120" color="primary darken-1">
                            <v-icon
                                size="90"
                                class="primary--text text--lighten-2"
                                >{{ icon }}</v-icon
                            >
                        </v-avatar>
                    </div>
                </v-card-text>
            </v-card>
        </v-col>
        <v-col :cols="contentCols">
            <v-card tile :disabled="sectionDisabled || loading">
                <v-card-text>
                    <slot>
                        <v-card flat height="500"> </v-card>
                    </slot>
                </v-card-text>
                <v-card-actions
                    class="card-actions"
                    v-if="!hideActions && !loading"
                >
                    <v-spacer></v-spacer>
                    <slot name="actions">
                        <v-btn
                            color="primary darken-2"
                            @click.stop="$emit('section-save')"
                            :disabled="btnDisabled"
                        >
                            <v-icon class="mr-2">{{ btnIcon }}</v-icon>
                            {{ btnLabel }}
                        </v-btn>
                    </slot>
                </v-card-actions>
                <v-card-actions v-if="loading">
                    <v-progress-linear
                        indeterminate
                        color="primary"
                    ></v-progress-linear>
                </v-card-actions>
            </v-card>
        </v-col>
    </v-row>
</template>
<script>
export default {
    name: "psi-form-layout-section",
    props: {
        title: {
            type: String,
            required: true,
        },
        icon: {
            type: String,
            required: true,
        },
        btnLabel: {
            type: String,
            required: false,
            default: "",
        },
        btnIcon: {
            type: String,
            required: false,
            default: "",
        },
        btnDisabled: {
            type: Boolean,
            required: false,
            default: false,
        },
        sectionDisabled: {
            type: Boolean,
            required: false,
            default: false,
        },
        hideActions: {
            type: Boolean,
            required: false,
            default: false,
        },
        labelCols: {
            type: Number,
            required: false,
            default: 4,
        },
        contentCols: {
            type: Number,
            required: false,
            default: 8,
        },
        loading: {
            type: Boolean,
            required: false,
            default: false,
        },
    },
};
</script>

<style scoped>
.card-actions {
    background-color: #b3c7cd !important;
}
</style>