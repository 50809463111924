<template>
    <v-card-title class="py-2" :class="headingColor">
        <div class="d-flex align-center">
            <slot name="icon">
                <v-icon
                    :color="headingTextColor"
                    class="mr-3 mt-n1"
                    v-text="icon"
                ></v-icon>
            </slot>
            <div>
                <div class="d-flex align-center">
                    <h5
                        class="font-weight-regular white--text text-truncate mb-1"
                        :class="`${headingTextColor}--text`"
                        style="max-width: 250px"
                        v-text="headingTitle"
                    ></h5>
                    <v-chip
                        class="ml-2"
                        :class="indicatorSmall ? 'mt-n1' : 'mt-1'"
                        :small="indicatorSmall"
                        v-if="indicator"
                        :color="indicatorColor"
                        v-text="indicator"
                    >
                    </v-chip>
                </div>
                <h6
                    v-if="headingSubtitle.length"
                    class="caption text--darken-2 font-weight-light mt-n1"
                    :class="`${headingTextColor}--text`"
                >
                    {{ headingSubtitle }}
                    <v-chip
                        v-if="itemNew"
                        x-small
                        class="mt-n1 ml-1"
                        color="success primary--text text--darken-2 font-weight-regular"
                        >new</v-chip
                    >
                </h6>
            </div>
        </div>
        <v-spacer></v-spacer>
        <slot></slot>
    </v-card-title>
</template>

<script>
export default {
    name: "psi-card-heading",
    props: {
        icon: {
            type: String,
            required: false,
            default: "mdi-apps"
        },
        indicator: {
            type: [String, Number],
            required: false,
            default: ""
        },
        indicatorColor: {
            type: String,
            required: false,
            default: "info"
        },
        indicatorSmall: {
            type: Boolean,
            required: false,
            default: true
        },
        headingColor: {
            type: String,
            required: false,
            default: "primary"
        },
        headingTitle: {
            type: String,
            required: true
        },
        headingSubtitle: {
            type: String,
            required: false,
            default: ""
        },
        headingTextColor: {
            type: String,
            required: false,
            default: "white"
        },
        itemNew: {
            type: Boolean,
            required: false,
            default: false
        }
    }
};
</script>