export default {
    details: {
        order_id: {
            icon: "mdi-receipt",
            label: "Order ID",
            type: "text",
            color: "success",
            copy: true,
            order: 1,
        },
        type: {
            icon: "mdi-clipboard-account",
            label: "Type",
            type: "text",
            color: "success",
            copy: false,
            order: 2,
        },
        assigned_to: {
            icon: "mdi-account-check",
            label: "Assigned to",
            type: "text",
            color: "success",
            copy: false,
            order: 3,
        },
        products: {
            icon: "mdi-tag",
            label: "Product",
            type: "text",
            color: "success",
            copy: false,
            order: 4,
        },
        property: {
            icon: "mdi-domain",
            label: "Property",
            type: "text",
            color: "success",
            copy: false,
            order: 5,
        },
        source: {
            icon: "mdi-clipboard-arrow-right",
            label: "Source",
            type: "text",
            color: "success",
            copy: false,
            order: 6,
        },
    },
    status: {
        APPLICANT_STARTED: {
            label: "Started",
            color: "warning",
        },
        APPLICANT_PENDING: {
            label: "Pending Approval",
            color: "warning",
        },
        APPLICANT_APPROVED: {
            label: "APPROVED",
            color: "info",
        },
        APPLICANT_DENIED: {
            label: "Denied",
            color: "error",
        },
        APPLICANT_INVITED: {
            label: "Invited",
            color: "info",
        },
        APPLICANT_REMINDED: {
            label: "Reminded",
            color: "info",
        },
        APPLICANT_COMPLETED: {
            label: "App Completed",
            color: "info",
        },
        SCREEN_PENDING: {
            label: "Pending",
            color: "warning",
        },
        SCREEN_IN_PROCESS: {
            label: "In Process",
            color: "info",
        },
        SCREEN_CREATED: {
            label: "Created",
            color: "warning",
        },
        SCREEN_COMPLETED: {
            label: "Completed",
            color: "success",
        },
        SCREEN_GATED: {
            label: "Gated",
            color: "warning",
        },
        SCREEN_ON_HOLD: {
            label: "On Hold",
            color: "info",
        },
    },
    application_details: {
        "application.property.name": {
            icon: "mdi-domain",
            label: "Property",
            type: "text",
        },
        "application.unit": {
            icon: "mdi-door",
            label: "Unit",
            type: "text",
        },
        "application.rent": {
            icon: "mdi-currency-usd",
            label: "Rent Amount",
            format: "currency",
            type: "text",
        },
        submitted_at: {
            icon: "mdi-calendar",
            label: "Date Submitted",
            type: "text",
        },
        "application.source": {
            icon: "mdi-clipboard-arrow-right-outline",
            label: "Source",
            type: "text",
        },
        "application.meta.desired_movein": {
            icon: "mdi-calendar-arrow-right",
            label: "Desired Move In",
            type: "text",
        },
    },
    applicant_profile: {
        type: {
            icon: "mdi-account-star",
            label: "Type",
            type: "text",
        },
        phone: {
            icon: "mdi-phone",
            label: "Phone",
            type: "text",
        },
        email: {
            icon: "mdi-email",
            label: "Email",
            type: "text",
        },
        birthdate: {
            icon: "mdi-cake-variant",
            label: "Birthdate",
            type: "text",
        },
        ssn_mask: {
            icon: "mdi-account-lock-outline",
            label: "SSN",
            type: "text",
        },
        itin_mask: {
            icon: "mdi-account-lock-outline",
            label: "ITIN",
            type: "text",
        },
    },
};
