<template>
    <v-menu
        origin="top left"
        offset-y
        transition="slide-y-transition"
        bottom
        nudge-top="-10"
    >
        <template v-slot:activator="{ on }">
            <v-btn
                icon
                :large="!$vuetify.breakpoint.mobile"
                v-on="on"
                :class="btnClass"
            >
                <v-icon>{{ icon }}</v-icon>
            </v-btn>
        </template>

        <div class="dropdown-container" :style="cssVars">
            <div
                class="d-flex justify-space-between darken-2 py-3 pl-3"
                :class="headingColor"
            >
                <div>
                    <h4
                        class="white--text font-weight-bold"
                        v-text="heading"
                    ></h4>
                </div>
                <v-chip small :class="indicator.class" v-if="indicator">{{
                    indicator.label
                }}</v-chip>
            </div>
            <v-list class="dropdown-list">
                <template v-for="(item, index) in items">
                    <v-list-item
                        class="list-item"
                        link
                        :key="`list-item-${index}`"
                        @click="$emit('action', item)"
                    >
                        <v-list-item-title class="list-item-text">
                            <v-icon
                                v-text="item.icon"
                                :class="$_.get(item, 'class', '')"
                                class="mr-3"
                            ></v-icon>
                            <!-- <i class="mr-3" :class="item.icon"></i> -->
                            <span class="text-body-2 primary--text">{{
                                item.title
                            }}</span>
                        </v-list-item-title>
                    </v-list-item>
                </template>
            </v-list>
        </div>
    </v-menu>
</template>
<script>
export default {
    name: "psi-quick-links",
    components: {},
    props: {
        btnClass: {
            type: String,
            required: false,
            default: "mx-2 white--text",
        },
        indicator: {
            type: Object,
            required: false,
            default: null,
        },
        icon: {
            type: String,
            required: false,
            default: "mdi-apps",
        },
        heading: {
            type: String,
            required: false,
            default: "Quick Links",
        },
        headingColor: {
            type: String,
            required: false,
            default: "accent",
        },
        items: {
            type: Array,
            required: true,
        },
        threeCol: {
            type: Boolean,
            required: false,
            default: false,
        },
    },
    data() {
        return {};
    },
    computed: {
        containerWidth() {
            return this.threeCol ? "width:600px" : "width:400px";
        },
        cssVars() {
            return {
                "--dropdown-width": this.threeCol ? "31.5%" : "46.5%",
                "--dropdown-container-width": this.threeCol ? "600px" : "400px",
            };
        },
    },
};
</script>

<style scoped>
.dropdown-container {
    width: var(--dropdown-container-width);
}
.dropdown-list .v-list-item {
    display: inline-flex;
    justify-content: flex-start;
    align-items: center;
    width: var(--dropdown-width);
    box-shadow: 0 3px 3px 0 rgba(0, 0, 0, 0.1);
    margin: 0.3125rem;
}
.list-item:hover {
    background: var(--v-secondary-lighten2);
}
.list-item:hover .list-item-text i {
    color: white !important;
}
.list-item:hover .list-item-text span {
    color: white !important;
}
</style>
    