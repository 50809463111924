// import the api endpoints
import api from "../api";

const initialState = () => ({
    programs: [],
    currentProgram: {},
    instructors: [],
    currentInstructor: {},
    loading: false,
});

const state = initialState();

const getters = {
    programs: (state) => state.programs,
    currentProgram: (state) => state.currentProgram,
    instructors: (state) => state.programs,
    currentInstructor: (state) => state.currentInstructor,

    loading: (state) => state.loading,
};

const actions = {
    reset({ commit }) {
        commit("RESET");
    },
    async getCurrentProgram({ commit }, id) {
        try {
            commit("SET_LOADING", true);
            let data = await api.getProgram(id);
            commit("SET_CURRENT_PROGRAM", data);
            commit("SET_LOADING", false);
        } catch (error) {
            commit("SET_ERROR", error, { root: true });
            commit("SET_LOADING", false);
        }
    },
    async getPrograms({ commit }) {
        try {
            commit("SET_LOADING", true);
            let data = await api.getPrograms();
            commit("SET_PROGRAMS", data);
            commit("SET_LOADING", false);
        } catch (error) {
            commit("SET_ERROR", error, { root: true });
        }
    },
    async getCurrentInstructor({ commit }, id) {
        try {
            commit("SET_LOADING", true);
            let data = await api.getInstructor(id);
            commit("SET_CURRENT_INSTRUCTOR", data);
            commit("SET_LOADING", false);
        } catch (error) {
            commit("SET_ERROR", error, { root: true });
            commit("SET_LOADING", false);
        }
    },
    async getInstructors({ commit }) {
        try {
            commit("SET_LOADING", true);
            let data = await api.getInstructors();
            commit("SET_INSTRUCTORS", data);
            commit("SET_LOADING", false);
        } catch (error) {
            commit("SET_ERROR", error, { root: true });
        }
    },
};

const mutations = {
    RESET: (state) => {
        const newState = initialState();
        Object.keys(newState).forEach((key) => {
            state[key] = newState[key];
        });
    },
    SET_PROGRAMS: (state, payload) => {
        state.programs = payload;
    },
    SET_CURRENT_PROGRAM: (state, payload) => {
        state.currentProgram = payload;
    },
    SET_CURRENT_INSTRUCTOR: (state, payload) => {
        state.currentInstructor = payload;
    },
    SET_INSTRUCTORS: (state, payload) => {
        state.instructors = payload;
    },
    SET_LOADING: (state, payload) => {
        state.loading = payload;
    },
};

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations,
};
