export default {
    filters: [
        {
            icon: "mdi-clipboard-check",
            name: "status",
            label: "Status",
            default: "",
            value: "",
            itemText: "status",
            itemValue: "status",
            items: [
                {
                    status: "Draft",
                },
                {
                    status: "Due",
                },
                {
                    status: "Overdue",
                },
                {
                    status: "Paid",
                },
            ],
        },

        {
            icon: "mdi-domain",
            name: "company",
            label: "Company",
            default: "",
            value: "",
            itemText: "name",
            itemValue: "id",
            items: [],
        },
        {
            icon: "mdi-home-city",
            name: "property",
            label: "Property",
            default: "",
            value: "",
            itemText: "name",
            itemValue: "id",
            items: [],
        },

        {
            icon: "mdi-calendar-range",
            name: "date_range",
            label: "Date Range",
            default: "This Month",
            value: "This Month",
            itemText: "range",
            itemValue: "range",
            items: [
                {
                    range: "This Month",
                },
                {
                    range: "Last Month",
                },
                {
                    range: "This Quarter",
                },
                {
                    range: "Last Quarter",
                },
                {
                    range: "This Year",
                },
                {
                    range: "Last Year",
                },
            ],
        },
    ],
    quicklinks: [
        {
            icon: "mdi-account",
            title: "Current",
            class: "warning--text text--darken-2",
        },
        {
            icon: "mdi-timer-sand",
            title: "Overdue",
            class: "accent--text text--darken-1",
        },
    ],
    actions: [
        {
            icon: "mdi-file-pdf",
            title: "Print Invoices",
        },
        {
            divider: true,
        },
        {
            icon: "mdi-credit-card-check-outline",
            title: "Mark Paid",
        },
        {
            divider: true,
        },
        {
            icon: "mdi-email",
            title: "Send Invoices",
            class: "primary--text text--darken-2",
        },
        {
            icon: "mdi-file-export",
            title: "Export for Quickbooks",
            class: "primary--text text--darken-2",
        },
    ],
};
