var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-main",
    [
      _c(
        "v-container",
        { attrs: { fluid: "", "fill-height": "" } },
        [
          _c(
            "v-row",
            { attrs: { align: "center", justify: "center" } },
            [
              _c(
                "v-col",
                {
                  attrs: {
                    cols: "12",
                    md: "4",
                    sm: "8",
                    "align-center": "",
                    "justify-center": ""
                  }
                },
                [
                  _c("v-progress-circular", {
                    attrs: {
                      indeterminate: "",
                      color: _vm.color,
                      size: _vm.size,
                      width: _vm.width
                    }
                  })
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }