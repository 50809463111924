var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("v-switch", {
    attrs: { inset: "", color: _vm.color, value: _vm.value },
    on: { change: _vm.changeSwitch },
    scopedSlots: _vm._u([
      {
        key: "label",
        fn: function() {
          return [
            _vm.value
              ? _c(
                  "v-chip",
                  {
                    staticClass: "text-uppercase",
                    attrs: { small: "", color: _vm.colorVerified }
                  },
                  [
                    _c("v-icon", { staticClass: "mr-2" }, [
                      _vm._v("mdi-check")
                    ]),
                    _vm._v(" Verified ")
                  ],
                  1
                )
              : _c(
                  "v-chip",
                  {
                    staticClass: "text-uppercase",
                    attrs: { small: "", color: _vm.colorUnverified }
                  },
                  [
                    _c("v-icon", { staticClass: "mr-2" }, [
                      _vm._v("mdi-close")
                    ]),
                    _vm._v(" Not Verified ")
                  ],
                  1
                )
          ]
        },
        proxy: true
      }
    ])
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }