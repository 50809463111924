var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-form",
    { ref: "form", staticClass: "mb-4" },
    [
      _c("v-file-input", {
        ref: _vm.name,
        attrs: {
          id: _vm.name,
          name: _vm.name,
          placeholder: "Upload your " + _vm.label,
          hint: _vm.hint,
          clearable: !_vm.loading,
          disabled: _vm.loading,
          error: _vm.uploadError,
          "persistent-hint": "",
          "prepend-icon": _vm.$vuetify.breakpoint.mdAndUp
            ? "attachment"
            : "mdi-camera",
          accept: _vm.accept,
          chips: "",
          counter: _vm.$vuetify.breakpoint.mdAndUp,
          dense: "",
          rules: _vm.rules,
          "show-size": _vm.$vuetify.breakpoint.mdAndUp,
          loading: _vm.loading,
          value: _vm.value
        },
        on: { "click:clear": _vm.clearFile, change: _vm.fileUpload },
        scopedSlots: _vm._u([
          {
            key: "selection",
            fn: function(ref) {
              var text = ref.text
              return [
                _c(
                  "v-chip",
                  { attrs: { small: "", label: "", color: "primary" } },
                  [
                    _vm.uploadSuccess
                      ? _c(
                          "v-icon",
                          { staticClass: "mr-1", attrs: { small: "" } },
                          [_vm._v("mdi-check-bold")]
                        )
                      : _vm._e(),
                    _vm._v(" " + _vm._s(text) + " ")
                  ],
                  1
                )
              ]
            }
          },
          {
            key: "progress",
            fn: function() {
              return [
                _c("v-progress-linear", {
                  attrs: { color: "primary", value: _vm.uploadProgress }
                })
              ]
            },
            proxy: true
          }
        ])
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }