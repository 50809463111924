import { render, staticRenderFns } from "./PsiContentLoader.vue?vue&type=template&id=06dc7130&"
import script from "./PsiContentLoader.vue?vue&type=script&lang=js&"
export * from "./PsiContentLoader.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* vuetify-loader */
import installComponents from "!../../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VCol } from 'vuetify/lib/components/VGrid';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VProgressCircular } from 'vuetify/lib/components/VProgressCircular';
import { VProgressLinear } from 'vuetify/lib/components/VProgressLinear';
import { VRow } from 'vuetify/lib/components/VGrid';
installComponents(component, {VCol,VContainer,VProgressCircular,VProgressLinear,VRow})


/* hot reload */
if (module.hot) {
  var api = require("/home/circleci/project/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('06dc7130')) {
      api.createRecord('06dc7130', component.options)
    } else {
      api.reload('06dc7130', component.options)
    }
    module.hot.accept("./PsiContentLoader.vue?vue&type=template&id=06dc7130&", function () {
      api.rerender('06dc7130', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/lib/src/components/loaders/PsiContentLoader.vue"
export default component.exports