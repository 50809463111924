import httpClient from "@api/client";

export default {
    async getCompanies() {
        let { data } = await httpClient.get("/companies");
        return data.data;
    },
    async getCompany(id) {
        let { data } = await httpClient.get(`/companies/${id}`);
        return data.data;
    },
};
