import history from "./history.json";

const resolveFunction = (args) => {
    return new Promise((resolve) => {
        setTimeout(() => {
            resolve(args);
        }, 1);
    });
};
let callBack = null;

export default {
    async initialize(...args) {
        callBack = args[5];
        await resolveFunction(args);
        return {
            error: null,
            data: {},
        };
    },

    async makeCall(...args) {
        await resolveFunction(args);
        callBack("callActive", true);
        callBack("callDuration", "time: 0:00:01");
    },

    setVolume(...args) {
        console.log("Set Volume Called with %o", args[0]);
    },
    /** IN CALL FUNCTIONS */
    dtmf(...args) {
        console.log("Set DTMF called with %o", args[0]);
    },
    mute() {
        console.log("Set Mute called ");
        callBack("callMute", true);
    },
    unmute() {
        console.log("Set Unmute called ");
        callBack("callMute", false);
    },
    async startRecord() {
        await resolveFunction();
        callBack("callRecord", true);
    },
    async stopRecord() {
        await resolveFunction();
        callBack("callRecord", false);
    },
    async hold() {
        await resolveFunction();
        callBack("callHold", true);
    },
    async unhold() {
        await resolveFunction();
        callBack("callHold", false);
    },
    hangup() {
        console.log("Hangup called");
        callBack("callActive", false);
    },
    increaseVolume() {},
    decreaseVolume() {},

    async callHistory() {
        await resolveFunction();
        return history;
    },
};
