<template>
    <v-card flat class="mb-2">
        <v-card-text :class="`alternate${colorOption.toString()}`" class="pa-5">
            <div class="d-flex align-center">
                <div class="align-self-center">
                    <h1 class="white--text" :class="fonts.icon" v-if="!!icon">
                        <v-icon class="white--text" :class="fonts.icon">{{
                            icon
                        }}</v-icon>
                    </h1>
                </div>
                <div class="mx-4">
                    <h3
                        class="card-title white--text font-weight-regular"
                        :class="fonts.title"
                        v-text="title"
                    ></h3>
                    <h6
                        v-if="!!subTitle"
                        class="card-subtitle white--text op-5 font-weight-regular"
                        :class="fonts.subTitle"
                        v-text="subTitle"
                    ></h6>
                </div>
            </div>
            <v-row class="mt-1">
                <v-col cols="12" class="d-flex align-center">
                    <h3
                        class="font-weight-light white--text text-nowrap"
                        :class="fonts.stat"
                        v-text="stat"
                    ></h3>
                    <slot name="qualifier"> </slot>
                </v-col>
            </v-row>
            <v-row class="mt-1" v-if="counter">
                <v-col cols="12" class="d-flex justify-end">
                    <v-chip
                        dark
                        :color="`alternate${colorOption.toString()} darken-2`"
                    >
                        {{ counter }}
                    </v-chip>
                </v-col>
            </v-row>
            <slot name="footer"></slot>
        </v-card-text>

        <v-card-actions
            :class="`alternate${colorOption.toString()} lighten-2`"
            class="d-flex justify-end lighten pa-3"
            v-if="!hideActions"
        >
            <v-btn
                v-if="this.action"
                :color="`alternate${colorOption.toString()}`"
                class="white--text text--darken-2"
                @click.stop="$emit('stat-action:clicked')"
            >
                <v-icon small class="mr-1">{{ icon }}</v-icon>
                {{ getActionLabel() }}
            </v-btn>
            <div v-else style="height: 38px"></div>
        </v-card-actions>
    </v-card>
</template>

<script>
export default {
    name: "psi-stats-card",
    props: {
        colorOption: {
            type: Number,
            default: 1,
            required: false,
        },
        stat: {
            type: [String, Number],
            required: true,
        },
        icon: {
            type: String,
            required: false,
        },
        title: {
            type: String,
            required: true,
        },
        subTitle: {
            type: String,
            required: false,
            default: "",
        },
        action: {
            type: Boolean,
            required: false,
            default: true,
        },
        actionLabel: {
            type: String,
            required: false,
            default: "",
        },
        hideActions: {
            type: Boolean,
            required: false,
            default: false,
        },
        counter: {
            type: String,
            required: false,
            default: "",
        },
        size: {
            type: String,
            required: false,
            default: "large",
        },
    },
    data: () => ({}),
    components: {},
    methods: {
        getActionLabel() {
            return this.actionLabel ? this.actionLabel : `View ${this.title}`;
        },
    },
    computed: {
        fonts() {
            let fonts = {};
            switch (this.size) {
                case "large":
                    fonts = {
                        icon: "text-h3",
                        title: "text-title",
                        subtitle: "text-subtitle-2",
                        stat: "text-title",
                    };
                    break;
                case "medium":
                    fonts = {
                        icon: "text-h4",
                        title: "text-subtitle-1",
                        subtitle: "text-body-1",
                        stat: "text-h4",
                    };
                    break;
                case "small":
                    fonts = {
                        icon: "text-h5",
                        title: "text-subtitle-2",
                        subtitle: "text-body-2",
                        stat: "text-h5",
                    };
                    break;
            }
            return fonts;
        },
    },
};
</script>
<style scoped>
</style>