<template>
    <div>
        <v-card
            class="hidden-sm-and-down mb-n1"
            color="primary"
            tile
            flat
            v-if="!miniVariant"
            height="160"
        >
            <div class="d-flex flex-column d-none align-center py-2">
                <v-avatar rounded size="120" color="primary darken-1">
                    <v-icon size="100" class="primary--text text--lighten-2">{{
                        icon
                    }}</v-icon>
                </v-avatar>
                <h4 class="font-weight-regular white--text text-uppercase mt-1">
                    {{ text }}
                </h4>
            </div>
        </v-card>
        <v-card
            v-else
            height="160"
            class="d-flex flex-column primary mb-n1 justify-center align-center"
            flat
            tile
        >
            <v-avatar rounded size="70" color="primary darken-1">
                <v-icon size="50" class="primary--text text--lighten-2">{{
                    icon
                }}</v-icon>
            </v-avatar>
        </v-card>
    </div>
</template>
<script>
export default {
    name: "sidebar-section",
    components: {},
    props: {
        miniVariant: {
            type: Boolean,
            required: false,
            default: false,
        },
        icon: {
            type: String,
            required: false,
            default: "mdi-home-city",
        },
        text: {
            type: String,
            required: false,
            default: "",
        },
    },
    data() {
        return {};
    },
    computed: {},
    watch: {},
    created() {},
    mounted() {},
    updated() {},
    detroyed() {},
    methods: {},
};
</script>

<style scoped>
</style>