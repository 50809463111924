<template>
    <v-menu
        v-model="menu"
        bottom
        left
        nudge-top="-36"
        :close-on-click="true"
        :close-on-content-click="false"
    >
        <template v-slot:activator="{ on, attrs }">
            <v-btn :class="btnClass" v-bind="attrs" v-on="on" :small="small">
                <v-badge
                    :color="badgeColor"
                    :class="badgeClass"
                    :content="filterItemCount"
                    :value="filterItemCount > 0"
                >
                    <v-icon :small="small">mdi-filter-menu</v-icon>
                </v-badge>
            </v-btn>
        </template>
        <v-card :width="width" outlined class="pa-3">
            <v-btn
                block
                class="my-1"
                v-if="filterItemCount > 0"
                @click.stop="filterClearAll"
            >
                <v-icon class="mr-2">mdi-filter-remove</v-icon>
                Clear all filters
            </v-btn>

            <v-list class="py-0 px-0">
                <template v-for="(filter, index) in filters">
                    <v-list-item class="py-0 my-2 px-0" :key="index">
                        <v-list-item-content class="py-0 mt-0">
                            <v-card-title
                                :class="labelClass"
                                class="font-weight-bold text-uppercase py-2"
                                >{{ filter.label }}</v-card-title
                            >
                            <v-card-text class="grey lighten-4 py-2">
                                <v-select
                                    dense
                                    :clearable="filter.value != filter.default"
                                    background-color="grey lighten-4"
                                    :color="color"
                                    :value="filter.value"
                                    :prepend-icon="filter.icon"
                                    :items="filter.items"
                                    :item-text="filter.itemText"
                                    :item-value="filter.itemValue"
                                    solo
                                    hide-details
                                    @change="
                                        filterItemChange(filter.name, $event)
                                    "
                                    @click:clear="filterItemClear(filter.name)"
                                >
                                </v-select>
                            </v-card-text>
                        </v-list-item-content>
                    </v-list-item>
                </template>
            </v-list>
        </v-card>
    </v-menu>
</template>
<script>
export default {
    name: "psi-filter-menu",
    components: {},
    props: {
        filters: {
            type: Array,
            required: true,
        },
        width: {
            type: [String, Number],
            default: 350,
            required: false,
        },
        btnClass: {
            type: String,
            required: false,
            default: "secondary darken-1",
        },
        small: {
            type: Boolean,
            required: false,
            default: false,
        },
        badgeColor: {
            type: String,
            required: false,
            default: "secondary darken-2",
        },
        badgeClass: {
            type: String,
            required: false,
            default: "text-subtitle-1 font-weight-bold",
        },
        color: {
            type: String,
            required: false,
            default: "secondary",
        },
        labelClass: {
            type: String,
            required: false,
            default: "secondary white--text text-subtitle-2",
        },
        closeOnSelect: {
            type: Boolean,
            required: false,
            default: false,
        },
        closeOnClear: {
            type: Boolean,
            required: false,
            default: false,
        },
    },
    data() {
        return {
            menu: false,
        };
    },
    computed: {
        filterItemCount: {
            immediate: true,
            deep: true,
            get() {
                let count = 0;
                this.filters.forEach((item) => {
                    if (item.value != item.default) {
                        count++;
                    }
                });
                return count;
            },
        },
    },

    methods: {
        filterItemChange(name, value) {
            // Vuetify will issue an change event on the clear icon click, ignore this
            if (value !== undefined) {
                this.$emit("filter-item", { name, value });
                if (this.closeOnSelect) {
                    this.menu = false;
                }
            }
        },
        filterItemClear(name) {
            this.$emit("filter-item", {
                name,
                value: this.filters.find((item) => item.name === name).default,
            });
        },
        filterClearAll() {
            this.$emit("filter-clear-all");
            if (this.closeOnClear) {
                this.menu = false;
            }
        },
    },
};
</script>

<style scoped>
</style>