export const routes = [
    {
        path: "/",
        redirect: "/orders/history",
    },
    {
        name: "Order History",
        path: "/orders/history",
        meta: {
            keepAlive: true,
            layout: "auth", // specify layout for route here, we require an authorized layout for this route
        },
        component: () => import("@views/screens/ScreeningHistory"),
    },
    {
        name: "Order Search",
        path: "/orders/search",
        meta: {
            keepAlive: true,
            layout: "auth", // required, authorized layout
        },
        component: () => import("@views/applications/ApplicationSearch"),
    },
    {
        name: "Order Screen",
        path: "/orders/screen",
        meta: {
            keepAlive: true,
            layout: "auth", // required, authorized layout
        },
        component: () =>
            import("@components/screen/components/Search/ScreenSearch"),
    },
    {
        name: "Program Dashboard",
        path: "/programs/dashboard",
        meta: {
            keepAlive: true,
            layout: "auth", // specify layout for route here, we require an authorized layout for this route
        },
        component: () => import("@components/program/views/ProgramDashboard"),
    },
    {
        name: "Program Students",
        path: "/programs/students",
        meta: {
            keepAlive: true,
            layout: "auth", // required, authorized layout
        },
        component: () => import("@components/program/views/ProgramStudents"),
    },
    {
        name: "Application Process",
        path: "/orders/process/:id",
        meta: {
            keepAlive: false,
            layout: "auth",
        },
        component: () => import("@views/screens/ScreeningProcess"),
    },
    {
        name: "Applicant Print",
        path: "/applicant/print/:id",
        meta: {
            keepAlive: false,
            layout: "print", // print layout to print the Applicant PDF
        },
        component: () => import("@views/screens/ApplicantPrint"),
    },
    {
        name: "Application Print",
        path: "/application/print/:id",
        meta: {
            keepAlive: false,
            layout: "print", // print layout to print the Applicant PDF
        },
        component: () => import("@views/screens/ApplicationPrint"),
    },
    {
        name: "Order Print",
        path: "/orders/print/:id",
        meta: {
            keepAlive: false,
            layout: "print", // print layout to print the Applicant PDF
        },
        component: () => import("@views/screens/OrderPrint"),
    },
    {
        path: "/account",
        redirect: "/acccount/dashboard",
    },
    {
        path: "/account/",
        component: () => import("@views/account/MyAccount.vue"),
        meta: {
            keepAlive: true,
            layout: "auth",
        },
        children: [
            {
                name: "Account Dashboard",
                path: "dashboard",
                meta: {
                    keepAlive: false,
                    layout: "auth", // specify layout for route here, we require an authorized layout for this route
                },
                component: () =>
                    import("@components/account/components/AccountDashboard"),
            },
            {
                name: "Account Company",
                path: "company",
                meta: {
                    keepAlive: false,
                    layout: "auth", // specify layout for route here, we require an authorized layout for this route
                },
                component: () =>
                    import("@components/account/components/AccountCompany"),
            },
            {
                name: "Account Properties",
                path: "properties",
                meta: {
                    keepAlive: false,
                    layout: "auth", // specify layout for route here, we require an authorized layout for this route
                },
                component: () =>
                    import("@components/account/components/AccountProperties"),
            },
            {
                name: "Account Users",
                path: "users",
                meta: {
                    keepAlive: false,
                    layout: "auth", // specify layout for route here, we require an authorized layout for this route
                },
                component: () =>
                    import("@components/account/components/AccountUsers"),
            },
            {
                name: "Account Teams",
                path: "teams",
                meta: {
                    keepAlive: false,
                    layout: "auth", // specify layout for route here, we require an authorized layout for this route
                },
                component: () =>
                    import("@components/account/components/AccountTeams"),
            },
            {
                name: "Account Profile",
                path: "profile",
                meta: {
                    keepAlive: false,
                    layout: "auth", // specify layout for route here, we require an authorized layout for this route
                },
                component: () =>
                    import("@components/account/components/AccountProfile"),
            },
            {
                name: "Account Billing",
                path: "billing",
                meta: {
                    keepAlive: false,
                    layout: "auth", // specify layout for route here, we require an authorized layout for this route
                },
                component: () =>
                    import("@components/account/components/AccountBilling"),
            },
            {
                name: "Account Reports",
                path: "reports",
                meta: {
                    keepAlive: false,
                    layout: "auth", // specify layout for route here, we require an authorized layout for this route
                },
                component: () =>
                    import("@components/account/components/AccountReports"),
            },
        ],
    },
    {
        name: "Admin Users",
        path: "/admin/users",
        meta: {
            keepAlive: false,
            layout: "auth",
        },
        component: () => import("@views/admin/AdminUsers"),
    },
    {
        name: "Admin Companies",
        path: "/admin/companies",
        meta: {
            keepAlive: false,
            layout: "auth",
        },
        component: () => import("@views/admin/AdminCompanies"),
    },
    {
        name: "Settings Colors",
        path: "/settings/colors/",
        meta: {
            keepAlive: false,
            layout: "auth",
        },
        component: () => import("@views/settings/SettingsColors"),
    },
    {
        name: "Settings Logos",
        path: "/settings/logos/",
        meta: {
            keepAlive: false,
            layout: "auth",
        },
        component: () => import("@views/settings/SettingsLogos"),
    },
    {
        name: "Register",
        path: "/register",
        meta: {
            requiresAuth: false,
            keepAlive: false,
            layout: "public",
        },
        component: () => import("@views/registration/RegisterUser"),
    },
    {
        name: "Register Error",
        path: "/register/error",
        meta: {
            requiresAuth: false,
            keepAlive: false,
            layout: "public",
        },
        component: () => import("@views/registration/RegisterError"),
    },
    {
        name: "New Application",
        path: "/applications/create/",
        meta: {
            keepAlive: false,
            layout: "auth",
        },
        component: () => import("@views/screens/ScreeningNewApplication"),
    },
    {
        name: "Invoicing",
        path: "/invoicing/",
        meta: {
            keepAlive: false,
            layout: "auth",
        },
        component: () => import("@views/invoices/CompanyInvoicing"),
    },
    {
        name: "Invoice",
        path: "/invoice/:id",
        meta: {
            keepAlive: false,
            layout: "auth",
        },
        component: () => import("@views/invoices/PropertyInvoice"),
    },
    {
        name: "Print Invoice",
        path: "/invoice/print/:id",
        meta: {
            keepAlive: false,
            layout: "print",
        },
        component: () => import("@views/invoices/InvoicePrint"),
    },
    {
        name: "Application Uploads",
        path: "/uploads",
        meta: {
            keepAlive: false,
            layout: "auth",
        },
        component: () => import("@/views/uploads/ApplicationUploads"),
        children: [
            {
                name: "Application Uploads Dashboard",
                path: "dashboard",
                meta: {
                    keepAlive: false,
                    layout: "auth",
                },
                component: () =>
                    import(
                        "@/components/uploads/components/ApplicationUploadsDashboard"
                    ),
            },
            {
                name: "Application Uploads Applications",
                path: "apps",
                meta: {
                    keepAlive: false,
                    layout: "auth",
                },
                component: () =>
                    import(
                        "@/components/uploads/components/ApplicationUploadsApplications"
                    ),
            },
            {
                name: "Application Uploads Review",
                path: "review",
                meta: {
                    keepAlive: false,
                    layout: "auth",
                },
                component: () =>
                    import(
                        "@/components/uploads/components/ApplicationUploadsReview"
                    ),
            },
        ],
    },
];
