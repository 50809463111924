const addDividers = (items) => {
    let withDividers = [];
    // Prepend a divider if the item has a divider
    items.forEach((item) => {
        if (item.divider) {
            withDividers.push({
                divider: true,
            });
        }
        // don't include the divider element in the menu item, only in a divider element
        delete item.divider;
        withDividers.push(item);
    });
    return withDividers;
};
export const transformMenus = (data) => {
    let menus = {};
    const subdomain = window.location.hostname.split(".")[0];

    data.forEach((item) => {
        menus[item.type] = item.menu_headings.map((heading) => {
            const {
                icon,
                text,
                text: title,
                slug: id,
                meta: { divider = false },
            } = heading;
            let menu = { icon, text, id, divider, title };

            if (heading.is_group) {
                menu["group"] = heading.path;
            }
            // Determine if external or internal link based on
            // our subdomain
            if (subdomain === heading.subdomain || subdomain === "prototypes") {
                menu["to"] = heading.link.to;
            } else {
                menu["href"] = heading.link.href;
            }
            if (Object.keys(heading).includes("menu_items")) {
                menu["children"] = heading.menu_items.map((child) => {
                    const {
                        icon,
                        text,
                        text: title,
                        slug: id,
                        meta: { divider = false },
                    } = child;
                    let elem = { icon, text, id, divider, title };
                    if (
                        subdomain === child.subdomain ||
                        subdomain === "prototypes"
                    ) {
                        elem["to"] = child.link.to;
                    } else {
                        elem["href"] = child.link.href;
                    }
                    if (child.exact) {
                        if (child.subdomain === subdomain) {
                            return elem;
                        }
                    } else {
                        return elem;
                    }
                });
            }

            // If are being exact, only return the heading item if this is our subdomain
            if (heading.exact) {
                if (heading.subdomain === subdomain) {
                    return menu;
                }
            } else {
                return menu;
            }
        });
        menus[item.type] = addDividers(menus[item.type]);
    });
    return menus;
};
