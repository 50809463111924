var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-list",
    { attrs: { dense: "" } },
    _vm._l(_vm.items, function(item, index) {
      return _c(
        "v-list-item",
        { key: index },
        [
          _c("v-list-item-content", [
            _c("div", { staticClass: "d-flex" }, [
              _c(
                "div",
                {
                  staticClass: "text-right text-uppercase font-weight-bold",
                  staticStyle: { width: "200px" }
                },
                [_vm._v(" " + _vm._s(item.label) + " ")]
              ),
              _c("div", { staticClass: "ml-6" }, [
                _vm._v(" " + _vm._s(item.value) + " ")
              ])
            ])
          ])
        ],
        1
      )
    }),
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }