<template>
    <v-card :flat="flat">
        <v-card-title class="py-2" :class="headingColor" v-if="!hideHeading">
            <div class="d-flex align-center">
                <v-icon
                    :color="headingTextColor"
                    class="mr-3 mt-n1"
                    v-text="icon"
                ></v-icon>
                <div>
                    <h5
                        class="font-weight-regular white--text text-truncate mb-1"
                        :class="`${headingTextColor}--text`"
                        style="max-width: 250px"
                        v-text="headingTitle"
                    ></h5>
                    <h6
                        v-if="headingSubtitle.length"
                        class="caption text--darken-2 font-weight-light mt-n1"
                        :class="`${headingTextColor}--text`"
                    >
                        {{ headingSubtitle }}
                        <v-chip
                            v-if="itemNew"
                            x-small
                            class="mt-n1 ml-1"
                            color="success primary--text text--darken-2 font-weight-regular"
                            >new</v-chip
                        >
                    </h6>
                </div>
            </div>
            <v-spacer></v-spacer>
        </v-card-title>
        <v-card-text class="mx-4 mt-2">
            <template v-for="(item, index) in items">
                <v-row :key="`heading-${index}`" v-if="$_.has(item, 'heading')">
                    <v-col cols="12">
                        <h4
                            class="font-weight-regular"
                            v-text="item.heading"
                        ></h4>
                    </v-col>
                </v-row>
                <v-row :key="`details-${index}`" v-else>
                    <v-col cols="1" class="mb-n2">
                        <v-icon
                            v-text="item.icon"
                            class="grey--text text--lighten-1"
                        ></v-icon>
                    </v-col>
                    <v-col cols="4" class="mb-n2">
                        <h4 class="font-weight-light" v-text="item.label"></h4>
                    </v-col>
                    <v-col
                        cols="7"
                        :class="{
                            'text-right':
                                item.type === 'chip' &&
                                !$_.get(item, 'align', '') === 'left',
                        }"
                        class="mb-n2"
                    >
                        <v-chip
                            v-if="item.type === 'chip'"
                            class="mr-3"
                            :small="$_.get(item, 'size', 'small') === 'small'"
                            :class="$_.get(item, 'class', '')"
                            :color="item.color"
                            v-text="item.text"
                        ></v-chip>
                        <div v-else class="d-flex">
                            <h4
                                class="font-weight-bold text-truncate"
                                v-html="item.text"
                            ></h4>
                            <psi-copy-button
                                :value="item.text"
                                v-if="item.copy"
                                btn-class="my-n2 ml-2"
                            ></psi-copy-button>
                        </div>
                    </v-col>
                </v-row>
                <v-row
                    :key="`divider-${index}`"
                    v-if="index != items.length - 1"
                >
                    <v-col cols="12" class="my-0 py-0">
                        <v-divider></v-divider>
                    </v-col>
                </v-row>
            </template>
        </v-card-text>
    </v-card>
</template>
<script>
export default {
    name: "psi-detail-card",
    props: {
        icon: {
            type: String,
            required: false,
            default: "mdi-account",
        },
        headingColor: {
            type: String,
            required: false,
            default: "primary",
        },
        headingTitle: {
            type: String,
            required: false,
            default: "",
        },
        headingSubtitle: {
            type: String,
            required: false,
            default: "",
        },
        headingTextColor: {
            type: String,
            required: false,
            default: "white",
        },
        loading: {
            type: Boolean,
            required: false,
            default: false,
        },
        items: {
            type: Array,
            required: true,
        },
        itemNew: {
            type: Boolean,
            required: false,
            default: false,
        },
        hideHeading: {
            type: Boolean,
            required: false,
            default: false,
        },
        flat: {
            type: Boolean,
            required: false,
            default: false,
        },
    },
};
</script>


<style>
.chip--flag {
    width: 240px !important;
}
</style>