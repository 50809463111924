import get from "lodash.get";
import has from "lodash.has";
import upperFirst from "lodash.upperfirst";
import capitalize from "lodash.capitalize";
import startCase from "lodash.startcase";
import camelCase from "lodash.camelcase";
import pick from "lodash.pick";

const titleCase = (str) => {
    return str
        .replace(/_/gi, " ")
        .split(" ")
        .map((str) => {
            const word = str.toLowerCase();
            return word.charAt(0).toUpperCase() + word.slice(1);
        })
        .join(" ");
};

export {
    get,
    has,
    capitalize,
    startCase,
    upperFirst,
    camelCase,
    titleCase,
    pick,
};
