import * as components from "./components";
import {
    get,
    has,
    camelCase,
    upperFirst,
    titleCase,
    pick,
    startCase,
} from "./plugins/lodash";
import VueClipboard from "vue-clipboard2";
import VCurrencyField from "v-currency-field";

const ComponentLibrary = {
    // eslint-disable-next-line no-unused-vars
    install(Vue, options = {}) {
        // components
        for (const componentName in components) {
            const component = components[componentName];

            Vue.component(component.name, component);
        }
        Vue.prototype.$_ = {
            get,
            has,
            startCase,
            camelCase,
            upperFirst,
            titleCase,
            pick,
        };
        Vue.prototype.$dollarFormat = function(val) {
            return parseFloat(
                val
                    .toString()
                    .replace(/\$/gi, "")
                    .replace(/,/gi, "")
            ).toLocaleString("en-US", {
                style: "currency",
                currency: "USD",
            });
        };

        Vue.prototype.$yesNoFormat = (val) => {
            return val ? "Yes" : "No";
        };

        Vue.prototype.$phoneFormat = function(val, intl = false) {
            const cleaned = ("" + val).replace(/\D/g, "");
            const match = cleaned.match(/^(1|)?(\d{3})(\d{3})(\d{4})$/);
            if (match) {
                const intlCode = match[1] ? "+1 " : "";
                return intl
                    ? [
                          intlCode,
                          "(",
                          match[2],
                          ") ",
                          match[3],
                          "-",
                          match[4],
                      ].join("")
                    : ["(", match[2], ") ", match[3], "-", match[4]].join("");
            }
            return "";
        };

        Vue.use(VueClipboard);
        Vue.use(VCurrencyField);

        // Add in support for Vuetify Snackbar with a toast function
        Vue.prototype.$toast = function(message) {
            if (this.$store) {
                this.$store.dispatch("Snackbar/showMessage", message);
            }
        };
    },
};

export default ComponentLibrary;

if (typeof window !== "undefined" && window.Vue) {
    window.Vue.use(ComponentLibrary);
}
