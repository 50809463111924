<template>
    <v-list-item
        :class="itemClass"
        link
        exact
        :ripple="false"
        :to="$_.get(item, 'to', '')"
        :href="$_.get(item, 'href', '')"
        dark
        active-class="list-item-active"
    >
        <v-list-item-action>
            <v-icon
                class="ml-2"
                :class="$config('app.layoutColors.sideBarItemText')"
                >{{ item.icon }}</v-icon
            >
        </v-list-item-action>
        <v-list-item-content class="ml-n4">
            <v-list-item-title
                class="font-weight-regular text-subtitle"
                :class="$config('app.layoutColors.sideBarItemText')"
                >{{ item.text }}</v-list-item-title
            >
        </v-list-item-content>
    </v-list-item>
</template>
<script>
export default {
    name: "sidebar-item",
    components: {},
    props: {
        item: {
            type: Object,
            required: true,
        },
        itemClass: {
            type: String,
            required: false,
            default: "",
        },
    },
    data: () => {
        return {};
    },
    computed: {
        itemTextClass() {
            return this.isActi
                ? this.$config("settings.layoutColors.activeSideBarItemText")
                : this.$config("settings.layoutColors.sideBarItemText");
        },
    },
};
</script>

<style scoped>
</style>