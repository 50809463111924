// import the api endpoints
import api from "../api";

const initialState = () => ({
    impersonator: {},
    authUser: {},
    user: {},
    menus: {},
    loggedIn: false,
});

const state = initialState();

const getters = {
    impersonator: (state) => state.impersonater,
    authUser: (state) => state.authUser,
    user: (state) => state.user,
    menus: (state) => state.menus,
    loggedIn: (state) => state.loggedIn,
};

const actions = {
    reset({ commit }) {
        commit("RESET");
    },
    async logout({ commit }) {
        try {
            await api.logout();
            commit("SET_LOGGED_OUT");
            commit("RESET", true, { root: true });
            // trying to get authenticated user after a logout forces a redirect
            await api.getAuthenticatedUser();
        } catch (error) {
            commit("SET_ERROR", error, { root: true });
        }
    },
    async getAuthenticatedUser({ commit }) {
        try {
            commit("SET_LOADING", true, { root: true });
            const user = await api.getAuthenticatedUser();
            const menus = await api.getUserMenus();
            commit("SET_MENUS", menus);

            commit("SET_AUTH_USER", user);
            commit("SET_LOADING", false, { root: true });
        } catch (error) {
            commit("SET_ERROR", error, { root: true });
            commit("SET_LOADING", false, { root: true });
        }
    },
    async getUser({ commit }, id) {
        try {
            //commit("SET_LOADING", true, { root: true });
            const user = await api.getUser(id);

            commit("SET_USER", user);
            commit("SET_LOADING", false, { root: true });
        } catch (error) {
            commit("SET_ERROR", error, { root: true });
            commit("SET_LOADING", false, { root: true });
        }
    },
    async getUsers({ commit }) {
        try {
            commit("SET_LOADING", true, { root: true });
            const users = await api.getUsers();
            commit("SET_USERS", users);
            commit("SET_LOADING", false, { root: true });
        } catch (error) {
            commit("SET_ERROR", error, { root: true });
            commit("SET_LOADING", false, { root: true });
        }
    },
};

const mutations = {
    RESET: (state) => {
        const newState = initialState();
        Object.keys(newState).forEach((key) => {
            state[key] = newState[key];
        });
    },
    SET_USER: (state, payload) => {
        state.user = payload;
    },
    SET_AUTH_USER: (state, payload) => {
        state.authUser = payload;
        state.loggedIn = Object.keys(state.user).length > 0;
    },
    SET_MENUS: (state, payload) => {
        state.menus = payload;
    },
    SET_LOGGED_OUT: (state, payload) => {
        state.loggedIn = payload;
    },
};

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations,
};
