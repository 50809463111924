var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-currency-field",
    _vm._b(
      {
        ref: "currency_" + _vm.name,
        staticClass: "psi-text-input",
        class: [
          { "mt-1": _vm.$vuetify.breakpoint.smAndUp },
          { "mt-1": _vm.$vuetify.breakpoint.smAndDown }
        ],
        style: _vm.cssVars,
        attrs: {
          id: _vm.id,
          "validate-on-blur": "",
          placeholder: _vm.placeholder,
          disabled: _vm.disabled,
          rules: _vm.validationRules,
          dusk: "currency-input",
          label: _vm.label
        },
        on: {
          input: function($event) {
            return _vm.$emit("input", _vm.currency)
          },
          focus: function($event) {
            _vm.attrs["background-color"] = _vm.backgroundColor
            _vm.focused = true
          },
          blur: function($event) {
            _vm.attrs["background-color"] = ""
            _vm.focused = false
          }
        },
        model: {
          value: _vm.currency,
          callback: function($$v) {
            _vm.currency = $$v
          },
          expression: "currency"
        }
      },
      "v-currency-field",
      _vm.attrs,
      false
    )
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }