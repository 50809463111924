export default {
    mapCases(charges, cases) {
        return charges.map((charge) => {
            charge.caseDetails = cases.find(
                (caseItem) => caseItem.caseId === charge.detail.caseId
            );
            // OBSCURE NAME
            charge.caseDetails.fullName = "Parker, William Wallace, Sr";
            return charge;
        });
    },
};
