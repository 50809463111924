var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      !_vm.miniVariant
        ? _c(
            "v-card",
            {
              staticClass: "hidden-sm-and-down mb-n1",
              attrs: { color: "primary", tile: "", flat: "", height: "160" }
            },
            [
              _c(
                "div",
                { staticClass: "d-flex flex-column d-none align-center py-2" },
                [
                  _c(
                    "v-avatar",
                    {
                      attrs: {
                        rounded: "",
                        size: "120",
                        color: "primary darken-1"
                      }
                    },
                    [
                      _c(
                        "v-icon",
                        {
                          staticClass: "primary--text text--lighten-2",
                          attrs: { size: "100" }
                        },
                        [_vm._v(_vm._s(_vm.icon))]
                      )
                    ],
                    1
                  ),
                  _c(
                    "h4",
                    {
                      staticClass:
                        "font-weight-regular white--text text-uppercase mt-1"
                    },
                    [_vm._v(" " + _vm._s(_vm.text) + " ")]
                  )
                ],
                1
              )
            ]
          )
        : _c(
            "v-card",
            {
              staticClass:
                "d-flex flex-column primary mb-n1 justify-center align-center",
              attrs: { height: "160", flat: "", tile: "" }
            },
            [
              _c(
                "v-avatar",
                {
                  attrs: { rounded: "", size: "70", color: "primary darken-1" }
                },
                [
                  _c(
                    "v-icon",
                    {
                      staticClass: "primary--text text--lighten-2",
                      attrs: { size: "50" }
                    },
                    [_vm._v(_vm._s(_vm.icon))]
                  )
                ],
                1
              )
            ],
            1
          )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }