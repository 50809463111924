<template>
    <v-menu
        :top="position == 'top'"
        :bottom="position == 'bottom'"
        :right="position == 'right'"
        :left="position == 'left'"
        :transition="transition"
        :close-on-click="closeOnClick"
        :open-on-hover="openOnHover"
    >
        <template v-slot:activator="{ on, attrs }">
            <v-btn v-bind="attrs" v-on="on" icon
                ><v-icon :class="iconClass">{{ icon }}</v-icon></v-btn
            >
        </template>
        <v-card :width="width">
            <v-card-title class="subtitle-2 primary white--text" v-if="title">
                {{ title }}
                <v-spacer></v-spacer>
                <v-btn icon dark><v-icon>mdi-close</v-icon></v-btn>
            </v-card-title>

            <v-card-text class="mt-2">
                <slot></slot>
            </v-card-text>
        </v-card>
    </v-menu>
</template>
<script>
export default {
    name: "psi-popover",
    props: {
        title: {
            type: String,
            required: false,
            default: ""
        },
        icon: {
            type: String,
            required: false,
            default: "mdi-help-circle"
        },
        width: {
            type: Number,
            required: false,
            default: 400
        },
        transition: {
            type: String,
            required: false,
            default: "scale-transition"
        },
        position: {
            type: String,
            required: false,
            default: "bottom"
        },
        iconClass: {
            type: String,
            required: false,
            default: "info--text"
        },
        openOnHover: {
            type: Boolean,
            required: false,
            default: false
        },
        closeOnClick: {
            type: Boolean,
            required: false,
            default: true
        }
    }
};
</script>

<style scoped>
</style>