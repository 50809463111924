var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-menu",
    {
      attrs: {
        origin: "top left",
        "offset-y": "",
        transition: "slide-y-transition",
        bottom: "",
        "nudge-top": "-10"
      },
      scopedSlots: _vm._u([
        {
          key: "activator",
          fn: function(ref) {
            var on = ref.on
            return [
              _c(
                "v-btn",
                _vm._g(
                  {
                    class: _vm.btnClass,
                    attrs: { icon: "", large: !_vm.$vuetify.breakpoint.mobile }
                  },
                  on
                ),
                [_c("v-icon", [_vm._v(_vm._s(_vm.icon))])],
                1
              )
            ]
          }
        }
      ])
    },
    [
      _c(
        "div",
        { staticClass: "dropdown-container", style: _vm.cssVars },
        [
          _c(
            "div",
            {
              staticClass: "d-flex justify-space-between darken-2 py-3 pl-3",
              class: _vm.headingColor
            },
            [
              _c("div", [
                _c("h4", {
                  staticClass: "white--text font-weight-bold",
                  domProps: { textContent: _vm._s(_vm.heading) }
                })
              ]),
              _vm.indicator
                ? _c(
                    "v-chip",
                    { class: _vm.indicator.class, attrs: { small: "" } },
                    [_vm._v(_vm._s(_vm.indicator.label))]
                  )
                : _vm._e()
            ],
            1
          ),
          _c(
            "v-list",
            { staticClass: "dropdown-list" },
            [
              _vm._l(_vm.items, function(item, index) {
                return [
                  _c(
                    "v-list-item",
                    {
                      key: "list-item-" + index,
                      staticClass: "list-item",
                      attrs: { link: "" },
                      on: {
                        click: function($event) {
                          return _vm.$emit("action", item)
                        }
                      }
                    },
                    [
                      _c(
                        "v-list-item-title",
                        { staticClass: "list-item-text" },
                        [
                          _c("v-icon", {
                            staticClass: "mr-3",
                            class: _vm.$_.get(item, "class", ""),
                            domProps: { textContent: _vm._s(item.icon) }
                          }),
                          _c(
                            "span",
                            { staticClass: "text-body-2 primary--text" },
                            [_vm._v(_vm._s(item.title))]
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                ]
              })
            ],
            2
          )
        ],
        1
      )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }