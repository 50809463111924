<template>
    <div></div>
</template>
<script>
export default {
    name: "psi-form-blank-field",
    components: {},
    props: {},
    data() {
        return {};
    },
};
</script>

<style scoped>
</style>