var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-menu",
    {
      attrs: {
        top: _vm.position == "top",
        bottom: _vm.position == "bottom",
        right: _vm.position == "right",
        left: _vm.position == "left",
        transition: _vm.transition,
        "close-on-click": _vm.closeOnClick,
        "open-on-hover": _vm.openOnHover
      },
      scopedSlots: _vm._u([
        {
          key: "activator",
          fn: function(ref) {
            var on = ref.on
            var attrs = ref.attrs
            return [
              _c(
                "v-btn",
                _vm._g(
                  _vm._b({ attrs: { icon: "" } }, "v-btn", attrs, false),
                  on
                ),
                [
                  _c("v-icon", { class: _vm.iconClass }, [
                    _vm._v(_vm._s(_vm.icon))
                  ])
                ],
                1
              )
            ]
          }
        }
      ])
    },
    [
      _c(
        "v-card",
        { attrs: { width: _vm.width } },
        [
          _vm.title
            ? _c(
                "v-card-title",
                { staticClass: "subtitle-2 primary white--text" },
                [
                  _vm._v(" " + _vm._s(_vm.title) + " "),
                  _c("v-spacer"),
                  _c(
                    "v-btn",
                    { attrs: { icon: "", dark: "" } },
                    [_c("v-icon", [_vm._v("mdi-close")])],
                    1
                  )
                ],
                1
              )
            : _vm._e(),
          _c("v-card-text", { staticClass: "mt-2" }, [_vm._t("default")], 2)
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }