var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("v-simple-table", {
    attrs: { dense: _vm.dense, height: _vm.height, "fixed-header": _vm.fixed },
    scopedSlots: _vm._u([
      {
        key: "default",
        fn: function() {
          return [
            _c("thead", [
              _c(
                "tr",
                _vm._l(_vm.headers, function(header, index) {
                  return _c(
                    "th",
                    {
                      key: index,
                      class: header.class,
                      attrs: { width: _vm.$_.get(header, "width", "") }
                    },
                    [_vm._v(" " + _vm._s(header.title) + " ")]
                  )
                }),
                0
              )
            ]),
            _c(
              "tbody",
              _vm._l(_vm.items, function(item, index) {
                return _c(
                  "tr",
                  { key: "tr-" + index },
                  _vm._l(_vm.headers, function(header, i) {
                    return _c(
                      "td",
                      { key: "tr-" + index + "-td-" + i, class: header.class },
                      [
                        _vm._v(
                          " " +
                            _vm._s(
                              _vm.format(
                                _vm.$_.get(item, header.key),
                                _vm.$_.get(header, "format", "")
                              )
                            ) +
                            " "
                        )
                      ]
                    )
                  }),
                  0
                )
              }),
              0
            )
          ]
        },
        proxy: true
      }
    ])
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }