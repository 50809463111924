<template>
    <v-btn
        icon
        @click="copyItem(value)"
        :class="btnClass"
        :title="`Copy ${label} to clipboard`"
        :small="small"
    >
        <v-icon class="py-n3" :small="small">mdi-content-copy</v-icon>
    </v-btn>
</template>
<script>
export default {
    name: "psi-copy-button",
    props: {
        value: {
            type: [String, Number],
            required: true
        },
        btnClass: {
            type: String,
            required: false,
            default: "ml-2"
        },
        label: {
            type: String,
            required: false,
            default: "text"
        },
        small: {
            type: Boolean,
            required: false,
            default: false
        }
    },
    methods: {
        async copyItem(text) {
            await this.$copyText(text);
            this.$emit("value-copied", {
                content: `${this.label} copied to clipboard`
            });
        }
    }
};
</script>

<style scoped>
</style>