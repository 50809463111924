<template>
    <v-text-field
        :style="cssVars"
        :id="id"
        validate-on-blur
        class="psi-date-input"
        v-model="model"
        v-mask="mask"
        v-bind="attrs"
        :class="[
            { 'mt-1': $vuetify.breakpoint.smAndUp },
            { 'mt-1': $vuetify.breakpoint.smAndDown },
        ]"
        :prepend-inner-icon="icon"
        :placeholder="placeholder"
        :hint="hint"
        :persistent-hint="persistentHint"
        :rules="validationRules"
        :label="label"
        :disabled="disabled"
        :dusk="name"
        :name="name"
        :required="required"
        @input="input"
        @focus="
            attrs['background-color'] = backgroundColor;
            focused = true;
        "
        @blur="
            attrs['background-color'] = '';
            focused = false;
        "
    >
        <template v-slot:append-outer>
            <v-dialog ref="dialog" v-model="dialog" width="290px">
                <template v-slot:activator="{ on, attrs }">
                    <v-btn
                        :color="color"
                        v-bind="attrs"
                        tabindex="-1"
                        v-on="on"
                        class="mt-n1 pa-0"
                        width="20"
                    >
                        <v-icon class="ma-0">mdi-calendar</v-icon>
                    </v-btn>
                </template>
                <v-date-picker
                    ref="picker"
                    v-model="date"
                    scrollable
                    @input="save"
                    v-if="dialog"
                    :max="
                        format === 'birthdate'
                            ? new Date().toISOString().substr(0, 10)
                            : ''
                    "
                    :min="format === 'birthdate' ? '1920-01-01' : ''"
                >
                </v-date-picker>
            </v-dialog>
        </template>
    </v-text-field>
</template>
<script>
import { mask } from "vue-the-mask";

export default {
    name: "psi-form-date-field",
    directives: {
        // Only bind the mask directive if we have a mask value, the mask directive doesn't handle empty string as a "No Mask" configuration
        mask: {
            bind(el, binding, vnode, oldVnode) {
                if (binding.value) {
                    mask(el, binding, vnode, oldVnode);
                }
            },
        },
    },
    components: {},
    props: {
        id: {
            type: String,
            required: true,
        },
        name: {
            type: String,
            required: true,
        },
        value: {
            required: true,
            validator: (prop) =>
                typeof prop === "string" ||
                typeof prop === "object" || // null case
                typeof prop === "number",
        },
        required: {
            type: Boolean,
            required: false,
            default: false,
        },
        label: {
            type: String,
            required: false,
            default: "",
        },
        icon: {
            type: String,
            required: false,
            default: "",
        },
        disabled: {
            type: Boolean,
            required: false,
            default: false,
        },
        hint: {
            type: String,
            required: false,
            default: "",
        },
        persistentHint: {
            type: Boolean,
            required: false,
            default: false,
        },
        rules: {
            type: Array,
            required: false,
            default: () => [],
        },
        color: {
            type: String,
            required: false,
            default: "primary",
        },
        backgroundColor: {
            type: String,
            required: false,
            default: "alternate4 lighten-5",
        },
        format: {
            type: String,
            required: false,
            default: "date",
        },
    },
    data() {
        return {
            model: this.value,
            focused: false,
            mask: "##/##/####",
            placeholder: "mm/dd/yyyy",
            date: this.value
                ? new Date(this.value).toISOString().substr(0, 10)
                : "",
            dialog: false,
            error: false,
            attrs: {
                height: "40",
                solo: true,
                color: this.color,
                "background-color": "",
            },
        };
    },
    watch: {
        value() {
            this.model = this.value;
        },
        dialog(val) {
            val &&
                this.format === "birthdate" &&
                setTimeout(() => (this.$refs.picker.activePicker = "YEAR"));
        },
    },
    computed: {
        validationRules() {
            let rules = [];

            if (this.required) {
                rules.push((v) => !!v || `${this.label} is a required field`);
            }
            rules.push(
                (v) =>
                    /^((((0[13578])|([13578])|(1[02]))[/](([1-9])|([0-2][0-9])|(3[01])))|(((0[469])|([469])|(11))[/](([1-9])|([0-2][0-9])|(30)))|((2|02)[/](([1-9])|([0-2][0-9]))))[/]\d{4}$|^\d{4}$/.test(
                        v
                    ) || `${this.label} is not valid`
            );
            return this.rules.length > 0 ? this.rules : rules;
        },
        cssVars() {
            return {
                "--dateinput-box-shadow": this.focused
                    ? "0px 5px 3px 2px rgba(0, 0, 0, 0.2),0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12)"
                    : "0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12)",
            };
        },
    },
    methods: {
        save() {
            this.model = this.formatDate(this.date);
            this.dialog = false;
            this.input();
        },
        input() {
            // Before we set the date picker date, we need to make sure that the date from the text field is valid either empty or a full mm/dd/yyyy string
            if (this.model.length === 0 || this.model.length === 10) {
                if (!isNaN(new Date(this.model))) {
                    this.date = new Date(this.model)
                        .toISOString()
                        .substr(0, 10);
                }
            }
            this.$emit("input", this.model);
        },
        formatDate(date) {
            const year = date.split("-").shift();
            const day = date.split("-").pop();
            const month = date.split("-")[1];
            return `${month}/${day}/${year}`;
        },
    },
};
</script>

<style >
.psi-date-input > .v-input__control > .v-input__slot {
    box-shadow: var(--dateinput-box-shadow) !important;
}
</style>