<template>
    <div>
        <v-row>
            <template v-for="(item, index) in items">
                <v-col :class="colsClass" :key="index" class="py-0 my-0">
                    <v-checkbox
                        class="mt-0 py-0 mb-0"
                        :id="`${item.name}-${index}`"
                        :input-value="model"
                        :rules="validationRules"
                        :multiple="items.length > 1"
                        :label="item.label"
                        :disabled="$_.get(item, 'disabled', false)"
                        :color="color"
                        :value="$_.get(item, 'value', 1)"
                        :true-value="$_.get(item, 'value', 1)"
                        :false-value="''"
                        :hide-details="hideDetails"
                        :format="format"
                        :dense="dense"
                        @change="handleChange($event)"
                    >
                        <template v-slot:label>
                            <span
                                :class="{
                                    'text-body-2': dense,
                                    'text-button': format === 'button',
                                }"
                                >{{ item.label }}</span
                            >
                        </template>
                    </v-checkbox>
                </v-col>
            </template>
        </v-row>
    </div>
</template>
<script>
export default {
    name: "psi-form-checkbox-field",
    components: {},
    props: {
        // Value can be an object in which case we build an array of checkboxes from keys or a single boolean value
        value: {
            required: true,
        },
        name: {
            type: String,
            required: false,
            default: "",
        },
        // If passing an object, can specify on the keys to use for checkboxes
        only: {
            type: Array,
            required: false,
            default: () => [],
        },
        label: {
            type: String,
            required: false,
            default: "",
        },
        disabled: {
            type: Boolean,
            required: false,
            default: false,
        },
        // provide additional item properties such as label, disabled
        itemProps: {
            type: Object,
            required: false,
            default: () => {},
        },
        // Indicates at least one item must be checked which essentially creates a rodio button list
        required: {
            type: Boolean,
            required: false,
            default: false,
        },
        color: {
            type: String,
            required: false,
            default: "primary",
        },
        dense: {
            type: Boolean,
            required: false,
            default: false,
        },
        rules: {
            type: Array,
            required: false,
            default: () => [],
        },
        format: {
            type: String,
            required: false,
            default: "button",
        },
        colsClass: {
            type: String,
            required: false,
            default: "lg-4 sm-12 md-6",
        },
        hideDetails: {
            type: Boolean,
            required: false,
            default: false,
        },
    },
    data() {
        return {
            model: false,
            items: [],
            itemKeys: [],
        };
    },
    computed: {
        validationRules() {
            return this.rules.length > 0 ? this.rules : this.customValidation();
        },
    },
    watch: {
        value: {
            immediate: true,
            handler(newVal) {
                if (
                    typeof newVal === "object" &&
                    Object.keys(newVal).length > 0
                ) {
                    // We'll use these item keys to our model
                    this.itemKeys =
                        this.only.length > 0 ? this.only : Object.keys(newVal);
                    this.model = Object.keys(newVal).filter(
                        (item) => newVal[item] && this.itemKeys.includes(item)
                    );
                    this.items = this.itemKeys.map((key) => {
                        return {
                            value: key,
                            label: this.$_.get(
                                this.itemProps,
                                `${key}.label`,
                                this.$_.titleCase(key)
                            ),
                            disabled: this.$_.get(
                                this.itemProps,
                                `${key}.disabled`,
                                false
                            ),
                        };
                    });
                } else {
                    // Single element checkbox
                    this.itemKeys = [this.name];

                    this.items = [
                        {
                            value: this.name,
                            label:
                                this.label.length > 0
                                    ? this.label
                                    : this.$_.titleCase(this.name),
                            disabled: this.disabled ?? false,
                        },
                    ];
                    this.model = this.value ? this.name : "";
                }
            },
        },
    },
    created() {},
    mounted() {},
    updated() {},
    detroyed() {},
    methods: {
        customValidation() {
            let rules = [];
            if (this.required) {
                rules.push((v) => !!v || `${this.label} is a required field`);
            }
            return rules;
        },
        handleChange(event) {
            let _model = {};
            this.itemKeys.forEach((key) => {
                _model[key] = Array.isArray(event)
                    ? event.includes(key)
                    : event === key;
            });
            this.$emit("input", _model);
        },
    },
};
</script>

<style scoped>
</style>