<template>
    <v-simple-table :dense="dense" :height="height" :fixed-header="fixed">
        <template v-slot:default>
            <thead>
                <tr>
                    <th
                        v-for="(header, index) in headers"
                        :class="header.class"
                        :key="index"
                        :width="$_.get(header, 'width', '')"
                    >
                        {{ header.title }}
                    </th>
                </tr>
            </thead>
            <tbody>
                <tr v-for="(item, index) in items" :key="`tr-${index}`">
                    <td
                        v-for="(header, i) in headers"
                        :class="header.class"
                        :key="`tr-${index}-td-${i}`"
                    >
                        {{
                            format(
                                $_.get(item, header.key),
                                $_.get(header, "format", "")
                            )
                        }}
                    </td>
                </tr>
            </tbody>
        </template>
    </v-simple-table>
</template>
<script>
export default {
    name: "psi-simple-table",
    props: {
        items: {
            type: Array,
            required: true,
        },
        fixed: {
            type: Boolean,
            required: false,
            default: false,
        },
        headers: {
            type: Array,
            required: true,
        },
        dense: {
            type: Boolean,
            required: false,
            default: false,
        },
        height: {
            type: [String, Number],
            require: false,
            default: "",
        },
    },
    methods: {
        format(value, type) {
            return type === "currency" ? this.$dollarFormat(value) : value;
        },
    },
};
</script>

<style scoped>
</style>