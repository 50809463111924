import httpClient from "@api/client";

export default {
    async getPrograms() {
        let { data } = await httpClient.get("/programs", {});
        return data.data;
    },
    async getProgram(id) {
        let { data } = await httpClient.get(`/programs/${id}`, {});
        return data.data;
    },
    async getInstructors() {
        let { data } = await httpClient.get("/instructors", {});
        return data.data;
    },
    async getInstructor(id) {
        let { data } = await httpClient.get(`/instructors/${id}`, {});
        return data.data;
    },
};
