var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-card",
    { staticClass: "mt-n2" },
    [
      _c(
        "v-toolbar",
        {
          staticClass: "pa-0",
          attrs: {
            dense: "",
            flat: "",
            height: _vm.breadcrumbs.length ? "40px" : "60px"
          }
        },
        [
          _c(
            "v-toolbar-title",
            { staticClass: "pt-3" },
            [
              _c(
                "v-icon",
                { staticClass: "mr-3", attrs: { large: "", color: "primary" } },
                [_vm._v(_vm._s(_vm.icon))]
              ),
              _c("span", { staticClass: "text--primary" }, [
                _vm._v(_vm._s(_vm.title))
              ])
            ],
            1
          ),
          _vm.$slots.subtitle
            ? _c(
                "v-toolbar-title",
                {
                  staticClass: "pb-3",
                  attrs: { slot: "extension" },
                  slot: "extension"
                },
                [_vm._t("subtitle")],
                2
              )
            : _vm._e(),
          _vm.breadcrumbs.length
            ? _c(
                "v-toolbar-title",
                {
                  staticClass: "pb-3",
                  attrs: { slot: "extension" },
                  slot: "extension"
                },
                [
                  _c("v-breadcrumbs", {
                    staticClass: "pa-0",
                    attrs: { items: _vm.breadcrumbs },
                    scopedSlots: _vm._u(
                      [
                        _vm.breadcrumbs
                          ? {
                              key: "divider",
                              fn: function() {
                                return [
                                  _c("v-icon", [_vm._v("mdi-chevron-right")])
                                ]
                              },
                              proxy: true
                            }
                          : null,
                        {
                          key: "item",
                          fn: function(ref) {
                            var item = ref.item
                            return [
                              _c(
                                "v-breadcrumbs-item",
                                _vm._b({}, "v-breadcrumbs-item", item, false),
                                [
                                  item.options
                                    ? _c(
                                        "div",
                                        {
                                          style:
                                            "width:" + item.options.width + "px"
                                        },
                                        [
                                          _c("v-select", {
                                            staticClass: "text-body-2",
                                            attrs: {
                                              "hide-details": "",
                                              "return-object": "",
                                              items: item.options.items,
                                              "item-text": item.options.text,
                                              "item-value": item.options.value,
                                              dense: "",
                                              value: item.options.selected
                                            },
                                            on: {
                                              change: function($event) {
                                                return _vm.$emit(
                                                  "breadcrumbs-item-selected",
                                                  $event
                                                )
                                              }
                                            }
                                          })
                                        ],
                                        1
                                      )
                                    : _c("span", [_vm._v(_vm._s(item.text))])
                                ]
                              )
                            ]
                          }
                        }
                      ],
                      null,
                      true
                    )
                  }),
                  _vm._t("default")
                ],
                2
              )
            : _vm._e(),
          _c("v-spacer"),
          _vm._t("actions")
        ],
        2
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }