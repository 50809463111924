<template>
    <v-text-field
        :type="fieldType"
        :ref="name"
        :autofocus="autofocus"
        :style="cssVars"
        :id="id"
        v-model="model"
        v-mask="fieldMask"
        v-bind="attrs"
        :name="name"
        :full-width="fullWidth"
        :validate-on-blur="validateOnBlur"
        class="psi-text-input"
        :class="[
            { 'mt-1': $vuetify.breakpoint.smAndUp },
            { 'mt-1': $vuetify.breakpoint.smAndDown },
            { 'mb-1': hideDetails },
        ]"
        :prepend-inner-icon="icon"
        :append-outer-icon="outerIcon"
        :append-icon="appendIcon"
        :placeholder="fieldPlaceholder"
        :hint="hint"
        :hide-details="hideDetails"
        :persistent-hint="persistentHint"
        :rules="validationRules"
        :clearable="clearable"
        :label="label"
        :disabled="disabled"
        :dusk="id"
        :required="required"
        @input="$emit('input', model)"
        @change="$emit('change', model)"
        @click:append-outer="$emit('click:append-outer')"
        @click:append="handleAppend"
        @click:clear="$emit('click:clear')"
        @focus="
            attrs['background-color'] = backgroundColor;
            focused = true;
        "
        @blur="
            attrs['background-color'] = '';
            focused = false;
        "
    >
    </v-text-field>
</template>
<script>
import { mask } from "vue-the-mask";

export default {
    name: "psi-form-text-field",
    directives: {
        // Only bind the mask directive if we have a mask value, the mask directive doesn't handle empty string as a "No Mask" configuration
        mask: {
            bind(el, binding, vnode, oldVnode) {
                if (binding.value) {
                    mask(el, binding, vnode, oldVnode);
                }
            },
        },
    },
    components: {},
    props: {
        id: {
            type: String,
            required: true,
        },
        value: {
            validator: (prop) =>
                typeof prop === "string" ||
                typeof prop === "object" ||
                typeof prop === "number",
            required: true,
        },
        required: {
            type: Boolean,
            required: false,
            default: false,
        },
        label: {
            type: String,
            required: false,
            default: "",
        },
        icon: {
            type: String,
            required: false,
            default: "",
        },
        placeholder: {
            type: String,
            required: false,
            default: "",
        },
        name: {
            type: String,
            required: true,
        },
        disabled: {
            type: Boolean,
            required: false,
            default: false,
        },
        mask: {
            type: [String],
            required: false,
            default: "",
        },
        hint: {
            type: String,
            required: false,
            default: "",
        },
        persistentHint: {
            type: Boolean,
            required: false,
            default: false,
        },
        hideDetails: {
            type: Boolean,
            required: false,
            default: false,
        },
        clearable: {
            type: Boolean,
            required: false,
            default: false,
        },
        autofocus: {
            type: Boolean,
            required: false,
            default: false,
        },
        rules: {
            type: Array,
            required: false,
            default: () => [],
        },
        color: {
            type: String,
            required: false,
            default: "primary",
        },
        outerIcon: {
            type: String,
            required: false,
            default: "",
        },
        backgroundColor: {
            required: false,
            default: "alternate4 lighten-5",
        },
        format: {
            type: String,
            required: false,
            default: "text",
        },
        fullWidth: {
            type: Boolean,
            required: false,
            default: false,
        },
        validateOnBlur: {
            type: Boolean,
            required: false,
            default: true,
        },
    },
    data() {
        return {
            model: this.value,
            error: false,
            focused: false,
            fieldType: this.format === "password" ? "password" : "text",
            appendIcon: this.format === "password" ? "mdi-eye" : "",
            attrs: {
                height: "40",
                solo: true,
                color: this.color,
                "background-color": "",
                type: this.format,
            },
        };
    },
    watch: {
        value() {
            this.model = this.value;
        },
    },
    computed: {
        validationRules() {
            return this.rules.length > 0 ? this.rules : this.customValidation();
        },
        fieldPlaceholder() {
            return this.placeholder.length > 0
                ? this.placeholder
                : `Enter ${this.label}`;
        },
        fieldMask() {
            return this.mask.length > 0 ? this.mask : this.customMask();
        },
        cssVars() {
            return {
                "--textinput-box-shadow": this.focused
                    ? "0px 5px 3px 2px rgba(0, 0, 0, 0.2),0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12)"
                    : "0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12)",
            };
        },
    },
    methods: {
        handleAppend() {
            if (this.format === "password") {
                this.appendIcon =
                    this.appendIcon === "mdi-eye-off"
                        ? "mdi-eye"
                        : "mdi-eye-off";
                this.fieldType =
                    this.fieldType === "text" ? "password" : "text";
            }
        },
        customMask() {
            switch (this.format) {
                case "tel":
                    return "(###)-###-####";
            }
        },
        customValidation() {
            let rules = [];
            switch (this.format) {
                case "email":
                    rules.push(
                        (v) =>
                            /^\w+([.+-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(
                                v
                            ) || `${this.label} must be valid`
                    );
                    break;
                case "tel":
                    rules.push(
                        (v) => v.length == 14 || `${this.label} is not valid`
                    );
                    break;
                case "password":
                    rules.push((v) => {
                        return (
                            v.length >= 10 ||
                            `${this.label} must be a minimum of 10 characters`
                        );
                    });
                    rules.push((v) => {
                        return (
                            /\d/.test(v) ||
                            `${this.label} must include a number`
                        );
                    });
                    rules.push((v) => {
                        return (
                            /[A-Z]/.test(v) ||
                            `${this.label} must include an upper case letter`
                        );
                    });
                    rules.push((v) => {
                        return (
                            /[!@#$%^&*)(+=._-]/.test(v) ||
                            `${this.label} must include a special character`
                        );
                    });
                    break;
                case "numeric":
                    rules.push(
                        (v) => !isNaN(v) || `${this.label} must be numeric`
                    );
                    break;
            }
            if (this.required) {
                rules.push(
                    // (v) => !!v.trim() || `${this.label} is a required field`
                    (v) => !!v.trim() || `${this.label} is a required field`
                );
            }

            return rules;
        },
    },
    mounted() {},
};
</script>

<style>
.psi-text-input > .v-input__control > .v-input__slot {
    box-shadow: var(--textinput-box-shadow) !important;
}
</style>