var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "v-form",
        { ref: "form" },
        [
          _vm.showTextLabel
            ? _c(
                "v-row",
                [
                  _c(
                    "v-col",
                    { attrs: { cols: "12" } },
                    [
                      _c("h4", {
                        staticClass:
                          "\n                        text-uppercase text-subtitle-1\n                        font-weight-regular\n                        mb-2\n                    ",
                        domProps: { innerHTML: _vm._s(_vm.label) }
                      }),
                      _c("v-divider", { staticClass: "mb-2" })
                    ],
                    1
                  )
                ],
                1
              )
            : _vm._e(),
          _vm.showArrayLabel
            ? _c(
                "v-row",
                [
                  _c(
                    "v-col",
                    {
                      staticClass: "py-0",
                      attrs: { lg: "6", md: "6", sm: "12" }
                    },
                    [
                      _c("h4", {
                        staticClass:
                          "\n                        text-uppercase text-subtitle-1\n                        font-weight-regular\n                        mb-2\n                    ",
                        domProps: { innerHTML: _vm._s(_vm.label[0]) }
                      }),
                      _c("v-divider", { staticClass: "mb-2" })
                    ],
                    1
                  ),
                  _c(
                    "v-col",
                    {
                      staticClass: "py-0",
                      attrs: { lg: "6", md: "6", sm: "12" }
                    },
                    [
                      _c("h4", {
                        staticClass:
                          "\n                        text-uppercase text-subtitle-1\n                        font-weight-regular\n                        mb-2\n                    ",
                        domProps: { innerHTML: _vm._s(_vm.label[1]) }
                      }),
                      _c("v-divider", { staticClass: "mb-2" })
                    ],
                    1
                  )
                ],
                1
              )
            : _vm._e(),
          _vm._l(_vm.formFields, function(field, index) {
            return [
              _vm.twoCols
                ? _c(
                    "v-row",
                    { key: index },
                    [
                      _vm._l(field, function(col, j) {
                        return [
                          _c(
                            "v-col",
                            {
                              key: "col-" + j,
                              staticClass: "py-0",
                              attrs: { lg: "6", md: "6", sm: "12" }
                            },
                            [
                              _c(
                                "psi-form-element",
                                _vm._b(
                                  { key: index },
                                  "psi-form-element",
                                  col,
                                  false
                                ),
                                [
                                  _c(
                                    "psi-form-" + col.type + "-field",
                                    _vm._b(
                                      {
                                        tag: "component",
                                        attrs: {
                                          id:
                                            _vm.name +
                                            "-" +
                                            col.type +
                                            "-" +
                                            col.name,
                                          value: _vm.$_.get(
                                            _vm.model,
                                            col.key,
                                            ""
                                          ),
                                          color: "primary darken-1"
                                        },
                                        on: {
                                          input: function($event) {
                                            return _vm.update(col.name, $event)
                                          }
                                        }
                                      },
                                      "component",
                                      col,
                                      false
                                    )
                                  )
                                ],
                                1
                              )
                            ],
                            1
                          )
                        ]
                      })
                    ],
                    2
                  )
                : _c(
                    "psi-form-element",
                    _vm._b({ key: index }, "psi-form-element", field, false),
                    [
                      _c(
                        "psi-form-" + field.type + "-field",
                        _vm._b(
                          {
                            tag: "component",
                            attrs: {
                              id: field.name + "-" + field.type,
                              value: _vm.$_.get(_vm.model, field.key, ""),
                              color: "primary darken-1"
                            },
                            on: {
                              input: function($event) {
                                return _vm.update(field.name, $event)
                              }
                            }
                          },
                          "component",
                          field,
                          false
                        )
                      )
                    ],
                    1
                  )
            ]
          })
        ],
        2
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }