var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-card",
    { attrs: { flat: _vm.flat } },
    [
      !_vm.hideHeading
        ? _c(
            "v-card-title",
            { staticClass: "py-2", class: _vm.headingColor },
            [
              _c(
                "div",
                { staticClass: "d-flex align-center" },
                [
                  _c("v-icon", {
                    staticClass: "mr-3 mt-n1",
                    attrs: { color: _vm.headingTextColor },
                    domProps: { textContent: _vm._s(_vm.icon) }
                  }),
                  _c("div", [
                    _c("h5", {
                      staticClass:
                        "font-weight-regular white--text text-truncate mb-1",
                      class: _vm.headingTextColor + "--text",
                      staticStyle: { "max-width": "250px" },
                      domProps: { textContent: _vm._s(_vm.headingTitle) }
                    }),
                    _vm.headingSubtitle.length
                      ? _c(
                          "h6",
                          {
                            staticClass:
                              "caption text--darken-2 font-weight-light mt-n1",
                            class: _vm.headingTextColor + "--text"
                          },
                          [
                            _vm._v(" " + _vm._s(_vm.headingSubtitle) + " "),
                            _vm.itemNew
                              ? _c(
                                  "v-chip",
                                  {
                                    staticClass: "mt-n1 ml-1",
                                    attrs: {
                                      "x-small": "",
                                      color:
                                        "success primary--text text--darken-2 font-weight-regular"
                                    }
                                  },
                                  [_vm._v("new")]
                                )
                              : _vm._e()
                          ],
                          1
                        )
                      : _vm._e()
                  ])
                ],
                1
              ),
              _c("v-spacer")
            ],
            1
          )
        : _vm._e(),
      _c(
        "v-card-text",
        { staticClass: "mx-4 mt-2" },
        [
          _vm._l(_vm.items, function(item, index) {
            return [
              _vm.$_.has(item, "heading")
                ? _c(
                    "v-row",
                    { key: "heading-" + index },
                    [
                      _c("v-col", { attrs: { cols: "12" } }, [
                        _c("h4", {
                          staticClass: "font-weight-regular",
                          domProps: { textContent: _vm._s(item.heading) }
                        })
                      ])
                    ],
                    1
                  )
                : _c(
                    "v-row",
                    { key: "details-" + index },
                    [
                      _c(
                        "v-col",
                        { staticClass: "mb-n2", attrs: { cols: "1" } },
                        [
                          _c("v-icon", {
                            staticClass: "grey--text text--lighten-1",
                            domProps: { textContent: _vm._s(item.icon) }
                          })
                        ],
                        1
                      ),
                      _c(
                        "v-col",
                        { staticClass: "mb-n2", attrs: { cols: "4" } },
                        [
                          _c("h4", {
                            staticClass: "font-weight-light",
                            domProps: { textContent: _vm._s(item.label) }
                          })
                        ]
                      ),
                      _c(
                        "v-col",
                        {
                          staticClass: "mb-n2",
                          class: {
                            "text-right":
                              item.type === "chip" &&
                              !_vm.$_.get(item, "align", "") === "left"
                          },
                          attrs: { cols: "7" }
                        },
                        [
                          item.type === "chip"
                            ? _c("v-chip", {
                                staticClass: "mr-3",
                                class: _vm.$_.get(item, "class", ""),
                                attrs: {
                                  small:
                                    _vm.$_.get(item, "size", "small") ===
                                    "small",
                                  color: item.color
                                },
                                domProps: { textContent: _vm._s(item.text) }
                              })
                            : _c(
                                "div",
                                { staticClass: "d-flex" },
                                [
                                  _c("h4", {
                                    staticClass:
                                      "font-weight-bold text-truncate",
                                    domProps: { innerHTML: _vm._s(item.text) }
                                  }),
                                  item.copy
                                    ? _c("psi-copy-button", {
                                        attrs: {
                                          value: item.text,
                                          "btn-class": "my-n2 ml-2"
                                        }
                                      })
                                    : _vm._e()
                                ],
                                1
                              )
                        ],
                        1
                      )
                    ],
                    1
                  ),
              index != _vm.items.length - 1
                ? _c(
                    "v-row",
                    { key: "divider-" + index },
                    [
                      _c(
                        "v-col",
                        { staticClass: "my-0 py-0", attrs: { cols: "12" } },
                        [_c("v-divider")],
                        1
                      )
                    ],
                    1
                  )
                : _vm._e()
            ]
          })
        ],
        2
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }