<template>
    <div>
        <v-form ref="form">
            <v-row v-if="showTextLabel">
                <v-col cols="12">
                    <h4
                        class="
                            text-uppercase text-subtitle-1
                            font-weight-regular
                            mb-2
                        "
                        v-html="label"
                    ></h4>
                    <v-divider class="mb-2"></v-divider>
                </v-col>
            </v-row>

            <v-row v-if="showArrayLabel">
                <v-col lg="6" md="6" sm="12" class="py-0">
                    <h4
                        class="
                            text-uppercase text-subtitle-1
                            font-weight-regular
                            mb-2
                        "
                        v-html="label[0]"
                    ></h4>
                    <v-divider class="mb-2"></v-divider>
                </v-col>
                <v-col lg="6" md="6" sm="12" class="py-0">
                    <h4
                        class="
                            text-uppercase text-subtitle-1
                            font-weight-regular
                            mb-2
                        "
                        v-html="label[1]"
                    ></h4>
                    <v-divider class="mb-2"></v-divider>
                </v-col>
            </v-row>

            <!-- Two Column Fields are in grouped in column fields -->
            <template v-for="(field, index) in formFields">
                <v-row :key="index" v-if="twoCols">
                    <template v-for="(col, j) in field">
                        <v-col
                            lg="6"
                            md="6"
                            sm="12"
                            :key="`col-${j}`"
                            class="py-0"
                        >
                            <psi-form-element v-bind="col" :key="index">
                                <component
                                    :id="`${name}-${col.type}-${col.name}`"
                                    :is="`psi-form-${col.type}-field`"
                                    :value="$_.get(model, col.key, '')"
                                    v-bind="col"
                                    color="primary darken-1"
                                    @input="update(col.name, $event)"
                                >
                                </component>
                            </psi-form-element>
                        </v-col>
                    </template>
                </v-row>
                <psi-form-element v-bind="field" :key="index" v-else>
                    <component
                        :id="`${field.name}-${field.type}`"
                        :is="`psi-form-${field.type}-field`"
                        :value="$_.get(model, field.key, '')"
                        v-bind="field"
                        color="primary darken-1"
                        @input="update(field.name, $event)"
                    >
                    </component>
                </psi-form-element>
            </template>
        </v-form>
    </div>
</template>
<script>
export default {
    name: "psi-form-section",
    components: {},
    props: {
        twoCols: {
            type: Boolean,
            required: false,
            default: false,
        },
        value: {
            type: Object,
            required: true,
        },
        fields: {
            type: Array,
            required: true,
        },
        name: {
            required: true,
            type: String,
        },
        label: {
            required: false,
            type: [String, Array],
            default: "",
        },
        color: {
            type: String,
            required: false,
            default: "primary",
        },
    },
    data() {
        return {
            model: this.value,
            formFields: [],
        };
    },
    computed: {
        showTextLabel() {
            return typeof this.label === "string" && this.label;
        },
        showArrayLabel() {
            return typeof this.label !== "string" && this.label;
        },
    },
    watch: {
        value: {
            immediate: true,
            handler() {
                this.model = this.value;
            },
        },
        fields: {
            immediate: true,
            deep: true,
            handler(fields) {
                this.model = this.value;
                this.formFields = fields;
            },
        },
    },
    methods: {
        update(name, value) {
            this.model[name] = value;
            this.$emit("input", this.model);
            this.$emit(`update:${name}`, value);
        },
        validate() {
            return this.$refs.form.validate();
        },
        reset() {
            this.$refs.form.reset();
        },
        resetValidation() {
            this.$refs.form.resetValidation();
        },
    },
    mounted() {},
};
</script>

<style scoped>
</style>