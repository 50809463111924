<template>
    <v-sheet class="mx-auto mb-6" elevation="8" :max-width="maxWidth">
        <v-slide-group
            v-model="model"
            class="pa-1"
            :mandatory="mandatory"
            center-active
            show-arrows
            @change="onChange"
        >
            <v-slide-item
                v-for="(item, index) in items"
                :key="index"
                v-slot="{ active, toggle }"
            >
                <v-card
                    :color="active ? activeColor : color"
                    class="ma-2"
                    :height="contentHeight"
                    :width="contentWidth"
                    @click="toggle"
                >
                    <v-card-text>
                        <!--  align="center" justify="center" -->
                        <v-row
                            class="fill-height"
                            align="center"
                            justify="center"
                        >
                            <v-col cols="10" class="py-0 my-0">
                                <div
                                    class="d-flex align-center justify-center"
                                    :class="{ 'white--text': active }"
                                >
                                    <v-icon
                                        class="mr-4"
                                        :class="{ 'white--text': active }"
                                        v-if="$_.get(item, 'icon')"
                                        >{{ item.icon }}</v-icon
                                    >
                                    <v-avatar
                                        v-if="$_.get(item, 'icon_filepath')"
                                        class="mr-4"
                                        :class="{ 'white--text': active }"
                                    >
                                        <img :src="item.icon_filepath" />
                                    </v-avatar>

                                    <div class="d-flex flex-column">
                                        <slot
                                            name="item"
                                            v-bind:item="item"
                                            v-bind:active="active"
                                        ></slot>
                                        <v-divider
                                            class="my-1"
                                            :class="{
                                                white: active,
                                            }"
                                            v-if="$scopedSlots.subitem"
                                        ></v-divider>
                                        <slot
                                            name="subitem"
                                            v-bind:item="item"
                                        ></slot>
                                    </div>
                                </div>
                            </v-col>
                            <v-col cols="2" class="text-center align-center">
                                <v-scale-transition>
                                    <v-icon
                                        v-if="active"
                                        :color="activeIconColor"
                                        size="36"
                                        v-text="'mdi-check-circle-outline'"
                                    ></v-icon>
                                </v-scale-transition>
                            </v-col>
                        </v-row>
                    </v-card-text>
                </v-card>
            </v-slide-item>
        </v-slide-group>
    </v-sheet>
</template>


<script>
export default {
    name: "psi-slide-selector",
    components: {},
    props: {
        items: {
            type: Array,
            required: true,
        },
        selected: {
            type: Number,
            required: false,
            default: 0,
        },
        maxWidth: {
            type: Number,
            required: false,
            default: 800,
        },
        color: {
            type: String,
            required: false,
            default: "grey lighten-4",
        },
        activeColor: {
            type: String,
            required: false,
            default: "accent lighten-2",
        },
        contentHeight: {
            type: Number,
            required: false,
            default: 150,
        },
        contentWidth: {
            type: Number,
            required: false,
            default: 330,
        },
        activeIconColor: {
            type: String,
            required: false,
            default: "white",
        },
        mandatory: {
            type: Boolean,
            required: false,
            default: true,
        },
    },
    data() {
        return {
            model: null,
            // model:
            //     this.selected > 0
            //         ? this.items.findIndex((item) => item.id === this.selected)
            //         : null,
        };
    },
    methods: {
        onChange(index) {
            this.$emit("slide-selector:change", this.items[index]);
        },
    },
};
</script>

<style scoped>
</style>