import httpClient from "@api/client";
import { transform } from "./transform";

const setup = (delay = 0) => {
    if ("Uploads" in window.mocks) {
        window.mocks.Uploads(httpClient, delay);
    }
};

export default {
    async getUploads(count) {
        setup();
        let res = await httpClient.get("/uploads", {
            params: { count: count },
        });
        return transform(res.data);
    },
};
