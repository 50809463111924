var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-list-item",
    {
      class: _vm.itemClass,
      attrs: {
        link: "",
        exact: "",
        ripple: false,
        to: _vm.$_.get(_vm.item, "to", ""),
        href: _vm.$_.get(_vm.item, "href", ""),
        dark: "",
        "active-class": "list-item-active"
      }
    },
    [
      _c(
        "v-list-item-action",
        [
          _c(
            "v-icon",
            {
              staticClass: "ml-2",
              class: _vm.$config("app.layoutColors.sideBarItemText")
            },
            [_vm._v(_vm._s(_vm.item.icon))]
          )
        ],
        1
      ),
      _c(
        "v-list-item-content",
        { staticClass: "ml-n4" },
        [
          _c(
            "v-list-item-title",
            {
              staticClass: "font-weight-regular text-subtitle",
              class: _vm.$config("app.layoutColors.sideBarItemText")
            },
            [_vm._v(_vm._s(_vm.item.text))]
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }