<template>
    <v-currency-field
        :style="cssVars"
        :id="id"
        validate-on-blur
        :ref="`currency_${name}`"
        v-model="currency"
        :placeholder="placeholder"
        :disabled="disabled"
        :rules="validationRules"
        class="psi-text-input"
        :class="[
            { 'mt-1': $vuetify.breakpoint.smAndUp },
            { 'mt-1': $vuetify.breakpoint.smAndDown },
        ]"
        v-bind="attrs"
        :dusk="'currency-input'"
        :label="label"
        @input="$emit('input', currency)"
        @focus="
            attrs['background-color'] = backgroundColor;
            focused = true;
        "
        @blur="
            attrs['background-color'] = '';
            focused = false;
        "
    ></v-currency-field>
</template>
<script>
export default {
    name: "psi-form-currency-field",
    components: {},
    props: {
        id: {
            type: String,
            required: true,
        },
        name: {
            type: String,
            required: true,
        },
        value: {
            required: false,
            validator: (prop) =>
                typeof prop === "string" ||
                typeof prop === "number" ||
                prop === null,
        },
        label: {
            type: String,
            required: false,
        },
        placeholder: {
            type: String,
            required: false,
            default: "",
        },
        required: {
            type: Boolean,
            required: false,
            default: false,
        },
        rules: {
            type: Array,
            required: false,
            default: () => [],
        },
        disabled: {
            type: Boolean,
            required: false,
            default: null,
        },
        color: {
            type: String,
            required: false,
            default: "primary",
        },
        backgroundColor: {
            required: false,
            default: "alternate4 lighten-5",
        },
    },
    data() {
        return {
            focused: false,
            currency: this.value ? this.value : "",
            attrs: {
                decimal: ".",
                thousands: ",",
                prefix: "$",
                suffix: "",
                precision: 2,
                masked: false,
                allowBlank: false,
                min: Number.MIN_SAFE_INTEGER,
                max: Number.MAX_SAFE_INTEGER,
                height: "40",
                solo: true,
                color: this.color,
                "background-color": "",
            },
        };
    },
    computed: {
        validationRules() {
            return this.rules.length > 0 ? this.rules : this.customValidation();
        },
        cssVars() {
            return {
                "--textinput-box-shadow": this.focused
                    ? "0px 5px 3px 2px rgba(0, 0, 0, 0.2),0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12)"
                    : "0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12)",
            };
        },
    },
    watch: {
        value() {
            this.currency = this.value;
        },
    },
    methods: {
        customValidation() {
            return this.required
                ? [
                      (v) => !!v || `${this.label} is required`,
                      (v) => parseFloat(v) > 0 || `${this.label} is required`,
                  ]
                : [];
        },
    },
};
</script>

<style>
.psi-text-input > .v-input__control > .v-input__slot {
    box-shadow: var(--textinput-box-shadow) !important;
}
</style>