export default {
    callHistoryDays: 7, // number of days to go back in time to pull  call logs
    dispositions: [
        {
            label: "Number invalid",
        },
        {
            label: "Unable to leave message",
        },
        {
            label: "Left a voice mail",
        },
        {
            label: "Made contact",
        },
    ],
};
