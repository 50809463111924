import httpClient from "@api/client";

export default {
    async getInvoices() {
        let res = await httpClient.get("/invoices");
        return res.data.data;
    },
    async getInvoice(id) {
        let res = await httpClient.get(`/invoices/${id}`);
        return res.data.data;
    },
    async searchInvoices(term) {
        term = term.toLowerCase();
        let res = await httpClient.get("/invoices");
        return res.data.data.page.data.filter((item) => {
            return item.description.toLowerCase().indexOf(term) > -1;
        });
    },
};
