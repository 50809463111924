<template>
    <v-card
        elevation="12"
        :class="navigationClass"
        v-scroll="onScroll"
        :style="cssVars"
    >
        <v-navigation-drawer permanent floating>
            <v-list dense rounded>
                <v-list-item-group
                    :value="selected ? selected : active"
                    @change="onChange"
                >
                    <v-list-item
                        v-for="item in items"
                        class="scroll-list-item"
                        active-class="scroll-list-item-active"
                        :input-value="item.id === active"
                        :value="item.id"
                        :key="item.title"
                        link
                        @click.stop="itemClick(item.id)"
                    >
                        <v-list-item-icon>
                            <v-icon>{{ item.icon }}</v-icon>
                        </v-list-item-icon>

                        <v-list-item-content>
                            <v-list-item-title>{{
                                item.title
                            }}</v-list-item-title>
                        </v-list-item-content>
                    </v-list-item>
                </v-list-item-group>
            </v-list>
        </v-navigation-drawer>
    </v-card>
</template>
<script>
export default {
    name: "psi-scroll-nav",
    components: {},
    props: {
        items: {
            type: Array,
            required: true,
        },
        scrollStickyPoint: {
            type: Number,
            required: false,
            default: 240,
        },
        scrollActivePoint: {
            type: Number,
            required: false,
            default: 400,
        },
        scrollOff: {
            type: Boolean,
            required: false,
            default: true,
        },
        selected: {
            type: String,
            required: false,
            default: "",
        },
        offsetHeight: {
            type: Number,
            required: false,
            default: 80,
        },
    },
    data() {
        return {
            active: "",
            navigationClass: "scroll-navigation",
        };
    },
    computed: {
        cssVars() {
            return {
                "--ps-offset-height": this.offsetHeight + "px",
            };
        },
    },
    watch: {
        items: {
            immediate: true,
            handler(values) {
                this.active = values[0].id;
            },
        },
    },
    created() {},
    mounted() {},
    updated() {},
    detroyed() {},
    methods: {
        onChange(value) {
            this.$emit("update:selected", value);
        },
        itemClick(id) {
            if (!this.scrollOff) {
                this.scrollTo(id);
            }
            this.active = id;
        },
        scrollTo(id) {
            this.$vuetify.goTo(`#${id}`, {
                easing: "easeInOutCubic",
                offset: 15,
                duration: 450,
            });
        },
        onScroll(e) {
            const scrollTop = e.target.scrollingElement.scrollTop;
            if (!this.scrollOff) {
                this.calculateActive();
            }
            this.calculateClass(scrollTop);
        },
        getElemTop(id) {
            return document.getElementById(id) === null
                ? 0
                : document.getElementById(id).getBoundingClientRect().top;
        },
        calculateActive() {
            let elems = [];
            this.items.forEach((item) => {
                elems.push({
                    id: item.id,
                    top: this.getElemTop(item.id),
                });
            });

            const elem = elems
                .sort((a, b) => a.top > b.top)
                .filter((elem) => elem.top < this.scrollActivePoint)
                .pop();

            this.active = elem ? elem.id : elems[0].id;
        },
        calculateClass(scrollTop) {
            if (
                scrollTop > this.scrollStickyPoint &&
                this.navigationClass === "scroll-navigation"
            ) {
                this.navigationClass = "scroll-fix-navigation";
            } else if (
                scrollTop <= this.scrollStickyPoint &&
                this.navigationClass === "scroll-fix-navigation"
            ) {
                this.navigationClass = "scroll-navigation";
            }
        },
    },
};
</script>

<style>
.scroll-fix-navigation {
    position: fixed;
    top: var(--ps-offset-height);
}
.scroll-navigation {
    position: relative;
}
.scroll-list-item:hover {
    background: var(--v-secondary-lighten3);
}
.scroll-list-item:hover .list-item-text {
    color: white;
}
.scroll-list-item-active {
    background: var(--v-secondary-lighten3);
    color: var(--v-secondary-darken4);
}
</style>