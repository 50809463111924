<template>
    <v-main>
        <v-container fluid fill-height>
            <v-row align="center" justify="center">
                <v-col cols="12" md="4" sm="8" align-center justify-center>
                    <v-progress-circular
                        indeterminate
                        :color="color"
                        :size="size"
                        :width="width"
                    ></v-progress-circular>
                </v-col>
            </v-row>
        </v-container>
    </v-main>
</template>
<script>
export default {
    name: "psi-app-loader",
    props: {
        color: {
            type: String,
            required: false,
            default: "primary"
        },
        size: {
            type: Number,
            required: false,
            default: 100
        },
        width: {
            type: Number,
            required: false,
            default: 7
        }
    },

    data() {
        return {};
    }
};
</script>
