var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-card-title",
    { staticClass: "py-2", class: _vm.headingColor },
    [
      _c(
        "div",
        { staticClass: "d-flex align-center" },
        [
          _vm._t("icon", [
            _c("v-icon", {
              staticClass: "mr-3 mt-n1",
              attrs: { color: _vm.headingTextColor },
              domProps: { textContent: _vm._s(_vm.icon) }
            })
          ]),
          _c("div", [
            _c(
              "div",
              { staticClass: "d-flex align-center" },
              [
                _c("h5", {
                  staticClass:
                    "font-weight-regular white--text text-truncate mb-1",
                  class: _vm.headingTextColor + "--text",
                  staticStyle: { "max-width": "250px" },
                  domProps: { textContent: _vm._s(_vm.headingTitle) }
                }),
                _vm.indicator
                  ? _c("v-chip", {
                      staticClass: "ml-2",
                      class: _vm.indicatorSmall ? "mt-n1" : "mt-1",
                      attrs: {
                        small: _vm.indicatorSmall,
                        color: _vm.indicatorColor
                      },
                      domProps: { textContent: _vm._s(_vm.indicator) }
                    })
                  : _vm._e()
              ],
              1
            ),
            _vm.headingSubtitle.length
              ? _c(
                  "h6",
                  {
                    staticClass:
                      "caption text--darken-2 font-weight-light mt-n1",
                    class: _vm.headingTextColor + "--text"
                  },
                  [
                    _vm._v(" " + _vm._s(_vm.headingSubtitle) + " "),
                    _vm.itemNew
                      ? _c(
                          "v-chip",
                          {
                            staticClass: "mt-n1 ml-1",
                            attrs: {
                              "x-small": "",
                              color:
                                "success primary--text text--darken-2 font-weight-regular"
                            }
                          },
                          [_vm._v("new")]
                        )
                      : _vm._e()
                  ],
                  1
                )
              : _vm._e()
          ])
        ],
        2
      ),
      _c("v-spacer"),
      _vm._t("default")
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }