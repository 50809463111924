import set from "lodash.set";
import get from "lodash.get";

const deepClone = (obj) => {
    return JSON.parse(JSON.stringify(obj));
};

const dollarFormat = (val) => {
    return parseFloat(val).toLocaleString("en-US", {
        style: "currency",
        currency: "USD",
    });
};

const createItems = (obj, map) => {
    let items = [];
    Object.keys(obj).forEach((key) => {
        if (Object.keys(map).includes(key)) {
            let item = deepClone(map[key]);
            item.text = obj[key].toString();
            if (item.text.length) {
                items.push(item);
            }
        }
    });
    return items.sort((a, b) => {
        return a.order - b.order;
    });
};

const mapItems = (data, map) => {
    let items = [];
    Object.keys(map).forEach((key) => {
        let item = deepClone(map[key]);
        item.text = get(data, key, "N/A");
        if (get(item, "format", "") === "currency") {
            item.text = dollarFormat(item.text);
        }
        if (item.text.length) {
            items.push(item);
        }
    });
    return items;
};

export const transform = (data, keys, map) => {
    keys.forEach((key) => {
        let obj = get(data, key, []);
        const objMap = get(map, key, []);
        if (Object.keys(obj).length && Object.keys(objMap).length) {
            set(data, key, {
                ...obj,
                items: createItems(obj, objMap),
            });
        }
    });
    return data;
};

export const mapKeys = (data, keys, map) => {
    let result = {};
    keys.forEach((key) => {
        const objMap = get(map, key, []);
        result[key] = mapItems(data, objMap);
    });
    return result;
};
export const lookup = (data, key, map) => {
    return map[key][data];
};
