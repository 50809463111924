<template>
    <v-card :color="backgroundColor">
        <psi-card-heading
            :icon="icon"
            :headingTitle="title"
            :headingColor="headingColor"
            :indicator="indicator"
            v-if="!hideTitle"
        >
            <v-btn icon dark @click.stop="$emit('close')"
                ><v-icon class="small">mdi-close</v-icon></v-btn
            >
        </psi-card-heading>
        <v-card-text
            :style="maxHeight ? `height: ${maxHeight}px` : ``"
            :class="`${
                $vuetify.breakpoint.mobile ? 'py-2 px-2' : cardTextClass
            }`"
        >
            <slot></slot>
        </v-card-text>

        <v-card-actions class="px-4 grey lighten-2" v-if="!hideActions">
            <v-spacer></v-spacer>
            <slot name="actions">
                <v-btn class="mr-4" @click.stop="$emit('close')">Cancel</v-btn>
                <v-btn
                    :color="btnColor"
                    class="px-4"
                    @click.stop="$emit('save')"
                >
                    <v-icon class="mr-1">mdi-content-save</v-icon>
                    Save
                </v-btn>
            </slot>
        </v-card-actions>
    </v-card>
</template>
<script>
export default {
    name: "psi-dialog",
    components: {},
    props: {
        backgroundColor: {
            type: String,
            required: false,
            default: "",
        },
        title: {
            type: String,
            required: true,
        },
        headingColor: {
            type: String,
            required: false,
            default: "accent",
        },
        btnColor: {
            type: String,
            required: false,
            default: "accent",
        },
        icon: {
            type: String,
            required: false,
            default: "mdi-note",
        },
        indicator: {
            type: [Number, String],
            required: false,
            default: "",
        },
        indicatorColor: {
            type: String,
            required: false,
            default: "",
        },
        indicatorSmall: {
            type: Boolean,
            required: false,
            default: false,
        },
        hideActions: {
            type: Boolean,
            required: false,
            default: false,
        },
        hideTitle: {
            type: Boolean,
            required: false,
            default: false,
        },
        cardTextClass: {
            type: String,
            required: false,
            default: "py-4",
        },
        maxHeight: {
            type: [String, Number],
            required: false,
            default: "",
        },
    },
    data() {
        return {};
    },
    computed: {},
    watch: {},
    created() {},
    mounted() {},
    updated() {},
    detroyed() {},
    methods: {},
};
</script>

<style scoped>
</style>