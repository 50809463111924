import httpClient from "@api/client";

const AUDIO_INPUT_DEVICE_ID = "audioInputDeviceId";
const AUDIO_OUTPUT_DEVICE_ID = "audioOutputDeviceId";
const AUDIO_VOLUME = "audioVolume";
const INITIAL_VOLUME = 0.3;

export default {
    async getRingCentrallAppInfo(id) {
        let res = await httpClient.get(`/services/ringcentral`);
        // TODO: Remove the client side injection of Ring Central Secret
        if (process.env.NODE_ENV !== "production") {
            res.data.secret =
                process.env.VUE_APP_MOCK_RINGCENTRAL_CLIENT_SECRET;
        }
        return res.data;
    },

    async getAudioDevices() {
        let devices = [];
        try {
            // Enumerating devices won't create a flashing red microphone icon once permissions are enabled
            devices = await navigator.mediaDevices.enumerateDevices();
            if (devices.length > 0 && devices[0].label === "") {
                // We need to get permission for the input audio device if we don't have a label
                // Ask the user for permission
                await navigator.mediaDevices.getUserMedia({
                    audio: true,
                });
                // now get the devices
                devices = await navigator.mediaDevices.enumerateDevices();
                if (devices.length > 0 && devices[0].label === "") {
                    throw new Error(
                        "Error trying to get media devices. Make sure permissions are enabled"
                    );
                }
            }
        } catch (error) {
            throw new Error("Error trying to get media devices");
        }
        return devices;
    },
    getInputAudioDevice() {
        return localStorage.getItem(AUDIO_INPUT_DEVICE_ID);
    },
    setInputAudioDevice(deviceId) {
        localStorage.setItem(AUDIO_INPUT_DEVICE_ID, deviceId);
    },
    getOutputAudioDevice() {
        return localStorage.getItem(AUDIO_OUTPUT_DEVICE_ID);
    },
    setOutputAudioDevice(deviceId) {
        console.log("API Set Output Audio Device %s", deviceId);
        localStorage.setItem(AUDIO_OUTPUT_DEVICE_ID, deviceId);
        console.log("Get Output Audio Device %s", this.getOutputAudioDevice());
    },
    getVolume() {
        localStorage.getItem(AUDIO_VOLUME)
            ? parseFloat(localStorage.getItem(AUDIO_VOLUME))
            : INITIAL_VOLUME;
    },
    setVolume(volume) {
        localStorage.setItem(AUDIO_VOLUME, volume.toString());
    },
};
