<template>
    <v-toolbar :class="color" dark>
        <psi-quick-links
            :items="quicklinks"
            v-if="quicklinks.length > 0"
            heading="Quick Links"
            :headingColor="color"
            :btnClass="`white--text ${
                $vuetify.breakpoint.mobile ? 'ml-n3 mr-0' : 'mx-2'
            }`"
            @action="$emit('quicklink-action', $event)"
        ></psi-quick-links>
        <v-toolbar-title v-if="!$vuetify.breakpoint.mobile"
            >{{ title }}
            <v-chip
                v-if="indicator"
                class="ml-1"
                :color="`${color} darken-1`"
                small
                >{{ indicator }}</v-chip
            ></v-toolbar-title
        >
        <slot></slot>
        <v-spacer></v-spacer>
        <slot name="actions"></slot>
        <psi-action-drop-down
            v-if="showActionMenu"
            :small="$vuetify.breakpoint.mobile"
            :items="actions"
            :heading="`${title} Actions`"
            :btnClass="`${color} darken-2 ${color}--text text--lighten-4`"
            :menuIcon="actionMenuIcon"
            @action="$emit('action', $event)"
        ></psi-action-drop-down>
        <v-divider
            v-if="showActionMenu && !$vuetify.breakpoint.mobile"
            vertical
            dark
            inset
            :class="`${color} lighten-2 mx-4 hidden-sm-and-down`"
        ></v-divider>
        <template v-for="(button, index) in buttons">
            <v-tooltip top :color="`${color} darken-1`" :key="index">
                <template v-slot:activator="{ on, attrs }">
                    <v-btn
                        :color="`${color} darken-1`"
                        v-on="on"
                        :small="$vuetify.breakpoint.mobile"
                        :class="`${$vuetify.breakpoint.mobile ? 'mr-1' : ''}`"
                        :items="actions"
                        v-bind="attrs"
                        @click.stop="$emit(button.event)"
                    >
                        <v-icon :small="$vuetify.breakpoint.mobile">{{
                            button.icon
                        }}</v-icon>
                    </v-btn>
                </template>
                <span>{{ button.tooltip }}</span>
            </v-tooltip>
            <v-divider
                v-if="!$vuetify.breakpoint.mobile"
                :key="`divider-${index}`"
                vertical
                dark
                inset
                :class="`${color} lighten-2 mx-4`"
            ></v-divider>
        </template>

        <v-tooltip top :color="`${color} darken-1`" v-if="filters.length > 0">
            <template v-slot:activator="{ on, attrs }">
                <div v-on="on" v-bind="attrs">
                    <psi-filter-menu
                        :color="color"
                        :label-class="filterLabelClass"
                        :filters="filters"
                        :small="$vuetify.breakpoint.mobile"
                        :btn-class="`${color} darken-1 ${
                            $vuetify.breakpoint.mobile ? 'mr-1' : ''
                        }`"
                        @filter-item="$emit('filter-item', $event)"
                        @filter-clear-all="$emit('filter-clear-all')"
                        :close-on-select="closeOnSelect"
                        :close-on-clear="closeOnClear"
                    ></psi-filter-menu>
                </div>
            </template>
            <span>Filter</span>
        </v-tooltip>
        <v-divider
            v-if="filters.length > 0"
            vertical
            dark
            inset
            :class="`${color} lighten-2 mx-4 hidden-sm-and-down`"
        ></v-divider>
        <div v-if="!hideViewOptions">
            <v-tooltip top :color="`${color} darken-1`">
                <template v-slot:activator="{ on, attrs }">
                    <v-btn
                        :color="`${color} darken-1`"
                        class="hidden-sm-and-down"
                        v-on="on"
                        v-bind="attrs"
                        :disabled="viewOption === 'list'"
                        @click.stop="viewOptionChanged('list')"
                    >
                        <v-icon>mdi-view-list</v-icon>
                    </v-btn>
                </template>
                <span>View as List</span>
            </v-tooltip>
            <v-tooltip top :color="`${color} darken-1 hidden-sm-and-down`">
                <template v-slot:activator="{ on, attrs }">
                    <v-btn
                        :color="`${color} darken-1`"
                        v-on="on"
                        v-bind="attrs"
                        class="ml-4 hidden-sm-and-down"
                        :disabled="viewOption === 'grid'"
                        @click.stop="viewOptionChanged('grid')"
                    >
                        <v-icon>mdi-view-grid</v-icon>
                    </v-btn>
                </template>
                <span>View as Grid</span>
            </v-tooltip>
        </div>
        <v-divider
            v-if="!hideRefresh && !hideViewOptions"
            vertical
            dark
            inset
            :class="`${color} lighten-2 mx-4 hidden-sm-and-down`"
        ></v-divider>

        <v-tooltip top :color="`${color} darken-1`" v-if="!hideRefresh">
            <template v-slot:activator="{ on, attrs }">
                <v-btn
                    :color="`${color} darken-1`"
                    v-on="on"
                    :small="$vuetify.breakpoint.mobile"
                    v-bind="attrs"
                    @click.stop="$emit('refresh')"
                >
                    <v-icon :small="$vuetify.breakpoint.mobile"
                        >mdi-refresh</v-icon
                    >
                </v-btn>
            </template>
            <span>Refresh</span>
        </v-tooltip>
    </v-toolbar>
</template>
<script>
export default {
    name: "psi-toolbar",
    components: {},
    props: {
        filters: {
            type: Array,
            required: false,
            default: () => {
                return [];
            },
        },
        quicklinks: {
            type: Array,
            required: false,
            default: () => {
                return [];
            },
        },
        actionMenuIcon: {
            type: String,
            required: false,
            default: "mdi-clipboard-text-multiple-outline",
        },
        showActionMenu: {
            type: Boolean,
            required: false,
            default: false,
        },
        viewOption: {
            type: String,
            required: true,
        },
        enableSearch: {
            type: Boolean,
            required: false,
            default: false,
        },
        color: {
            type: String,
            required: false,
            default: "secondary",
        },
        title: {
            type: String,
            required: false,
            default: "Orders",
        },
        indicator: {
            type: [String, Number],
            required: false,
            default: "",
        },
        actions: {
            type: Array,
            required: false,
            default: () => {
                return [];
            },
        },
        buttons: {
            type: Array,
            required: false,
            default: () => [],
        },
        closeOnSelect: {
            type: Boolean,
            required: false,
            default: false,
        },
        closeOnClear: {
            type: Boolean,
            required: false,
            default: false,
        },
        filterLabelClass: {
            type: String,
            required: false,
            default: "secondary white--text text-subtitle-2",
        },
        hideRefresh: {
            type: Boolean,
            required: false,
            default: false,
        },
        hideViewOptions: {
            type: Boolean,
            required: false,
            default: false,
        },
    },
    data() {
        return {};
    },
    methods: {
        viewOptionChanged(opt) {
            this.$emit("update:viewOption", opt);
        },
    },
};
</script>

<style scoped>
</style>