export default {
    name: "mdi-account",
    first_name: "mdi-account",
    middle_name: "mdi-account",
    last_name: "mdi-account",
    phone: "mdi-phone",
    fax: "mdi-fax",
    email: "mdi-email",
    ssn_mask: "mdi-card-account-details",
    birthdate: "mdi-calendar-account",
    type: "mdi-clipboard-account",
    address: "mdi-home-map-marker",
    unit: "mdi-door-open",
    city: "mdi-city",
    state: "mdi-map-check",
    zip: "mdi-mailbox",
    rental_status: "mdi-home-city",
    rental_label: "mdi-label",
    rental_type: "mdi-timer-sand",
    move_in_date: "mdi-calendar-start",
    move_out_date: "mdi-calendar-end",
    monthly_payment: "mdi-currency-usd",
    payment_type: "mdi-home-currency-usd",
    company: "mdi-home-city",
    employment_type: "mdi-briefcase-clock",
    start_date: "mdi-calendar-start",
    end_date: "mdi-calendar-end",
    sent_date: "mdi-calendar-check",
    due_date: "mdi-calendar-cursor",
    payment_date: "mdi-calendar-text",
    employer_type: "mdi-timer-sand",
    position: "mdi-briefcase-account",
    rent_stated: "mdi-currency-usd",
    rent_actual: "mdi-currency-usd",
    lease_type: "mdi-calendar-question",
    complaints_incurred: "mdi-home-alert",
    damages_incurred: "mdi-home-alert",
    late_payments_incurred: "mdi-home-alert",
    three_day_notices_incurred: "mdi-home-alert",
    past_due_rent_incurred: "mdi-home-alert",
    nsf_checks_incurred: "mdi-home-alert",
    covid_deferred_rent_incurred: "mdi-home-alert",
    move_in_actual: "mdi-calendar-start",
    move_in_stated: "mdi-calendar-start",
    completed_at: "mdi-calendar-check",
    unauthorized_occupants: "mdi-account-multiple-remove-outline",
    unauthorized_pets: "mdi-paw",
    rent_again: "mdi-calendar-refresh",
    proper_notice: "mdi-bell-outline",
    good_renter: "mdi-account-heart",
    rental_agreement: "mdi-handshake",
    vacate_evicted: "mdi-home-remove-outline",
    move_out_actual: "mdi-calendar-end",
    move_out_stated: "mdi-calendar-end",
    note: "mdi-note",
    amount: "mdi-currency-usd",
    invoice_status: "mdi-credit-card-check",
    property: "mdi-domain",
    assigned_to: "mdi-account-check",
    source: "mdi-clipboard-arrow-right",
    password: "mdi-account-lock",
    id: "mdi-barcode",
    created: "mdi-calendar",
    password_expires: "mdi-clock-alert",
};
