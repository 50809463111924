var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "v-row",
        [
          _vm._l(_vm.items, function(item, index) {
            return [
              _c(
                "v-col",
                { key: index, staticClass: "py-0 my-0", class: _vm.colsClass },
                [
                  _c("v-checkbox", {
                    staticClass: "mt-0 py-0 mb-0",
                    attrs: {
                      id: item.name + "-" + index,
                      "input-value": _vm.model,
                      rules: _vm.validationRules,
                      multiple: _vm.items.length > 1,
                      label: item.label,
                      disabled: _vm.$_.get(item, "disabled", false),
                      color: _vm.color,
                      value: _vm.$_.get(item, "value", 1),
                      "true-value": _vm.$_.get(item, "value", 1),
                      "false-value": "",
                      "hide-details": _vm.hideDetails,
                      format: _vm.format,
                      dense: _vm.dense
                    },
                    on: {
                      change: function($event) {
                        return _vm.handleChange($event)
                      }
                    },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "label",
                          fn: function() {
                            return [
                              _c(
                                "span",
                                {
                                  class: {
                                    "text-body-2": _vm.dense,
                                    "text-button": _vm.format === "button"
                                  }
                                },
                                [_vm._v(_vm._s(item.label))]
                              )
                            ]
                          },
                          proxy: true
                        }
                      ],
                      null,
                      true
                    )
                  })
                ],
                1
              )
            ]
          })
        ],
        2
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }