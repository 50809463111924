var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-card",
    {
      directives: [
        {
          name: "scroll",
          rawName: "v-scroll",
          value: _vm.onScroll,
          expression: "onScroll"
        }
      ],
      class: _vm.navigationClass,
      style: _vm.cssVars,
      attrs: { elevation: "12" }
    },
    [
      _c(
        "v-navigation-drawer",
        { attrs: { permanent: "", floating: "" } },
        [
          _c(
            "v-list",
            { attrs: { dense: "", rounded: "" } },
            [
              _c(
                "v-list-item-group",
                {
                  attrs: { value: _vm.selected ? _vm.selected : _vm.active },
                  on: { change: _vm.onChange }
                },
                _vm._l(_vm.items, function(item) {
                  return _c(
                    "v-list-item",
                    {
                      key: item.title,
                      staticClass: "scroll-list-item",
                      attrs: {
                        "active-class": "scroll-list-item-active",
                        "input-value": item.id === _vm.active,
                        value: item.id,
                        link: ""
                      },
                      on: {
                        click: function($event) {
                          $event.stopPropagation()
                          return _vm.itemClick(item.id)
                        }
                      }
                    },
                    [
                      _c(
                        "v-list-item-icon",
                        [_c("v-icon", [_vm._v(_vm._s(item.icon))])],
                        1
                      ),
                      _c(
                        "v-list-item-content",
                        [_c("v-list-item-title", [_vm._v(_vm._s(item.title))])],
                        1
                      )
                    ],
                    1
                  )
                }),
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }