// import the api endpoints
import api from "../api";

const initialState = () => ({
    companies: [],
    currentCompany: {},
    loading: false,
});

const state = initialState();

const getters = {
    companies: (state) => state.companies,
    currentCompany: (state) => state.currentCompany,
    loading: (state) => state.loading,
};

const actions = {
    reset({ commit }) {
        commit("RESET");
    },
    async getCurrentCompany({ commit }, id) {
        try {
            commit("SET_LOADING", true);
            let data = await api.getCompany(id);
            commit("SET_CURRENT_COMPANY", data);
            commit("SET_LOADING", false);
        } catch (error) {
            commit("SET_ERROR", error, { root: true });
            commit("SET_LOADING", false);
        }
    },
    async getCompanies({ commit }) {
        try {
            commit("SET_LOADING", true);
            let data = await api.getCompanies();
            commit("SET_COMPANIES", data);
            commit("SET_LOADING", false);
        } catch (error) {
            commit("SET_ERROR", error, { root: true });
        }
    },
};

const mutations = {
    RESET: (state) => {
        const newState = initialState();
        Object.keys(newState).forEach((key) => {
            state[key] = newState[key];
        });
    },
    SET_COMPANIES: (state, payload) => {
        state.companies = payload;
    },
    SET_CURRENT_COMPANY: (state, payload) => {
        state.currentCompany = payload;
    },
    SET_LOADING: (state, payload) => {
        state.loading = payload;
    },
};

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations,
};
