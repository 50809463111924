<template>
    <v-card class="mt-n2">
        <v-toolbar
            dense
            flat
            class="pa-0"
            :height="breadcrumbs.length ? '40px' : '60px'"
        >
            <v-toolbar-title class="pt-3">
                <v-icon large class="mr-3" color="primary">{{ icon }}</v-icon>
                <span class="text--primary">{{ title }}</span>
            </v-toolbar-title>
            <v-toolbar-title
                class="pb-3"
                slot="extension"
                v-if="$slots.subtitle"
            >
                <slot name="subtitle"></slot>
            </v-toolbar-title>
            <v-toolbar-title
                class="pb-3"
                slot="extension"
                v-if="breadcrumbs.length"
            >
                <v-breadcrumbs :items="breadcrumbs" class="pa-0">
                    <template v-slot:divider v-if="breadcrumbs">
                        <v-icon>mdi-chevron-right</v-icon>
                    </template>
                    <template v-slot:item="{ item }">
                        <v-breadcrumbs-item v-bind="item">
                            <div
                                v-if="item.options"
                                :style="`width:${item.options.width}px`"
                            >
                                <v-select
                                    class="text-body-2"
                                    hide-details
                                    return-object
                                    :items="item.options.items"
                                    :item-text="item.options.text"
                                    :item-value="item.options.value"
                                    dense
                                    :value="item.options.selected"
                                    @change="
                                        $emit(
                                            'breadcrumbs-item-selected',
                                            $event
                                        )
                                    "
                                ></v-select>
                            </div>
                            <span v-else>{{ item.text }}</span>
                        </v-breadcrumbs-item>
                    </template>
                </v-breadcrumbs>
                <slot></slot>
            </v-toolbar-title>
            <v-spacer></v-spacer>
            <slot name="actions"></slot>
        </v-toolbar>
    </v-card>
</template>
<script>
export default {
    name: "psi-page-title",
    props: {
        title: {
            required: true,
            type: String,
        },
        breadcrumbs: {
            type: Array,
            required: false,
            default: () => [],
        },
        icon: {
            type: String,
            required: false,
            default: "",
        },
    },
    data: () => {
        return {};
    },
};
</script>

<style scoped>
</style>