<template>
    <v-container fluid fill-height>
        <v-row align="center" justify="center">
            <v-col cols="12" class="d-flex justify-center">
                <v-progress-circular
                    v-if="type === 'circular'"
                    indeterminate
                    :color="color"
                    :size="size"
                    :width="width"
                >
                </v-progress-circular>
                <v-progress-linear
                    v-if="type === 'linear'"
                    indeterminate
                    :color="color"
                    :height="height"
                ></v-progress-linear>
            </v-col>
        </v-row>
    </v-container>
</template>
<script>
export default {
    name: "psi-content-loader",
    props: {
        type: {
            type: String,
            required: false,
            default: "linear",
        },
        color: {
            type: String,
            required: false,
            default: "primary",
        },
        height: {
            type: Number,
            required: false,
            default: 7,
        },
        size: {
            type: Number,
            required: false,
            default: 60,
        },
        width: {
            type: Number,
            required: false,
            default: 5,
        },
    },

    data() {
        return {};
    },
};
</script>
