var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-container",
    { attrs: { fluid: "", "fill-height": "" } },
    [
      _c(
        "v-row",
        { attrs: { align: "center", justify: "center" } },
        [
          _c(
            "v-col",
            { staticClass: "d-flex justify-center", attrs: { cols: "12" } },
            [
              _vm.type === "circular"
                ? _c("v-progress-circular", {
                    attrs: {
                      indeterminate: "",
                      color: _vm.color,
                      size: _vm.size,
                      width: _vm.width
                    }
                  })
                : _vm._e(),
              _vm.type === "linear"
                ? _c("v-progress-linear", {
                    attrs: {
                      indeterminate: "",
                      color: _vm.color,
                      height: _vm.height
                    }
                  })
                : _vm._e()
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }