var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-card",
    { attrs: { color: _vm.backgroundColor } },
    [
      !_vm.hideTitle
        ? _c(
            "psi-card-heading",
            {
              attrs: {
                icon: _vm.icon,
                headingTitle: _vm.title,
                headingColor: _vm.headingColor,
                indicator: _vm.indicator
              }
            },
            [
              _c(
                "v-btn",
                {
                  attrs: { icon: "", dark: "" },
                  on: {
                    click: function($event) {
                      $event.stopPropagation()
                      return _vm.$emit("close")
                    }
                  }
                },
                [_c("v-icon", { staticClass: "small" }, [_vm._v("mdi-close")])],
                1
              )
            ],
            1
          )
        : _vm._e(),
      _c(
        "v-card-text",
        {
          class:
            "" +
            (_vm.$vuetify.breakpoint.mobile ? "py-2 px-2" : _vm.cardTextClass),
          style: _vm.maxHeight ? "height: " + _vm.maxHeight + "px" : ""
        },
        [_vm._t("default")],
        2
      ),
      !_vm.hideActions
        ? _c(
            "v-card-actions",
            { staticClass: "px-4 grey lighten-2" },
            [
              _c("v-spacer"),
              _vm._t("actions", [
                _c(
                  "v-btn",
                  {
                    staticClass: "mr-4",
                    on: {
                      click: function($event) {
                        $event.stopPropagation()
                        return _vm.$emit("close")
                      }
                    }
                  },
                  [_vm._v("Cancel")]
                ),
                _c(
                  "v-btn",
                  {
                    staticClass: "px-4",
                    attrs: { color: _vm.btnColor },
                    on: {
                      click: function($event) {
                        $event.stopPropagation()
                        return _vm.$emit("save")
                      }
                    }
                  },
                  [
                    _c("v-icon", { staticClass: "mr-1" }, [
                      _vm._v("mdi-content-save")
                    ]),
                    _vm._v(" Save ")
                  ],
                  1
                )
              ])
            ],
            2
          )
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }