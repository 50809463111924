import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";

import vuetify from "./plugins/vuetify";
import "@plugins/config";

import ComponentLib from "./lib/src/main";
Vue.use(ComponentLib);

import "@/plugins/page-transitions";
import "@/plugins/vue-uuid";
import "@/plugins/perfect-scrollbar";
import "@/plugins/vue-apex";

Vue.config.productionTip = false;
// Import Global Access to Trix, Trouble with this package forced a component outside the Psi Component Lib
import PsiFormTrixField from "@components/lib/PsiFormTrixField";
Vue.component("PsiFormTrixField", PsiFormTrixField);

new Vue({
    router,
    store,
    vuetify,
    render: (h) => h(App),
}).$mount("#app");
