<template>
    <v-list dense>
        <v-list-item v-for="(item, index) in items" :key="index">
            <v-list-item-content>
                <div class="d-flex">
                    <div
                        style="width: 200px"
                        class="text-right text-uppercase font-weight-bold"
                    >
                        {{ item.label }}
                    </div>
                    <div class="ml-6">
                        {{ item.value }}
                    </div>
                </div>
            </v-list-item-content>
        </v-list-item>
    </v-list>
</template>
<script>
export default {
    name: "psi-simple-list",
    props: {
        items: {
            type: Array,
            required: true,
        },
    },
};
</script>

<style scoped>
.v-list-item--dense,
.v-list--dense .v-list-item {
    min-height: 30px;
}
</style>