// import the api endpoints
import api from "../api";
//import lib from "../lib";
import lib from "../lib/mock";

import store from "@/store";

/**
 * callback handles the async events from the Ring Central Library
 * @param {string} event
 * @param {*} params
 */
const callback = async (event, params) => {
    switch (event) {
        case "registered":
            store.commit("Calls/SET_REGISTERED", params);
            break;
        case "activeCallInfo":
            store.commit("Calls/SET_ACTIVE_CALL_INFO", params);
            break;
        case "callDuration":
            store.commit("Calls/SET_CALL_DURATION", params);
            break;
        case "callActive":
            store.commit("Calls/SET_CALL_ACTIVE", params);
            break;
        case "callMute":
            store.commit("Calls/SET_CALL_MUTE", params);
            break;
        case "callRecord":
            store.commit("Calls/SET_CALL_RECORD", params);
            break;
        case "callHold":
            store.commit("Calls/SET_CALL_HOLD", params);
            break;
        case "error":
            store.commit(
                "SET_ERROR",
                Object.keys(params).includes("message")
                    ? params.message
                    : params
            );
            break;
        default:
            break;
    }
    console.log("Callback event = %s", event);
    console.log("Callback params = %o", params);
};

const initialState = () => ({
    history: {},
    ringCentralClient: {},
    devices: [],
    volume: 0,
    audioInputDeviceId: null,
    audioOutputDeviceId: null,
    callActive: false,
    callHold: false,
    callMute: false,
    callRecord: false,
    callDuration: "",
    activeCallInfo: {},
    extensionInfo: {},
    registered: false,
    sipInfo: {},
    initialized: false,
    contact: {},
    phoneNumber: "",
    loading: false,
});

const state = initialState();

const getters = {
    // Call Logs
    history: (state) => state.history,
    // Audio Device List
    devices: (state) => state.devices,

    // Call States
    callActive: (state) => state.callActive,
    callHold: (state) => state.callHold,
    callMute: (state) => state.callMute,
    callRecord: (state) => state.callRecord,
    callDuration: (state) => state.callDuration,

    // Technical info from the RC SDK interface
    extensionInfo: (state) => state.extensionInfo,
    activeCallInfo: (state) => state.activeCallInfo,
    sipInfo: (state) => state.sipInfo,

    // SDK Client
    ringCentralClient: (state) => state.client,

    // Flags
    initialized: (state) => state.initialized,
    registered: (state) => state.registered,
    // Audio Device Selection
    audioInputDeviceId: (state) => state.audioInputDeviceId,
    audioOutputDeviceId: (state) => state.audioOutputDeviceId,

    // Audio output for calls
    volume: (state) => state.volume,

    // Call Contact, Phone Number
    contact: (state) => state.contact,
    phoneNumber: (state) => state.phoneNumber,

    // We're async loading from the lib or API
    loading: (state) => state.loading,
};

const actions = {
    reset({ commit }) {
        commit("RESET");
    },
    async initialize({ commit }, params) {
        const {
            ring_central_username: username,
            ring_central_password: password,
            ring_central_extension: extension,
        } = params.user;

        // Get the Ring Central Client Information from the API including ID, Secret
        try {
            const client = await api.getRingCentrallAppInfo();
            commit("SET_RING_CENTRAL_CLIENT", client);
            // Setup for Audio Device Selection
            const audioInputDeviceId = api.getInputAudioDevice();
            commit("SET_AUDIO_INPUT_DEVICE_ID", audioInputDeviceId);
            const audioOutputDeviceId = api.getOutputAudioDevice();
            commit("SET_AUDIO_OUTPUT_DEVICE_ID", audioOutputDeviceId);

            const response = await lib.initialize(
                client,
                params.media,
                username,
                extension,
                password,
                callback
            );
            if (response.error === null) {
                const { sipInfo = {}, extensionInfo = {} } = response.data;
                commit("SET_SIP_INFO", sipInfo);
                commit("SET_EXTENSION_INFO", extensionInfo);
                commit("SET_INITIALIZED", true);
            }
        } catch (error) {
            commit("SET_ERROR", error, { root: true });
        }
    },
    async call({ commit }, number) {
        try {
            await lib.makeCall(
                number,
                state.audioInputDeviceId,
                state.audioOutpuDeviceId
            );
        } catch (error) {
            commit("SET_ERROR", error, { root: true });
        }
    },
    async hangup({ commit }) {
        try {
            lib.hangup();
            commit("RESET_CALL");
        } catch (error) {
            commit("SET_ERROR", error, { root: true });
        }
    },
    async mute({ commit }) {
        lib.mute();
    },
    async unmute({ commit }) {
        lib.unmute();
    },
    async hold({ commit }) {
        try {
            await lib.hold();
        } catch (error) {
            commit("SET_ERROR", error, { root: true });
        }
    },
    async unhold({ commit }) {
        try {
            await lib.unhold();
        } catch (error) {
            commit("SET_ERROR", error, { root: true });
        }
    },
    async dtmf({ commit }, digit) {
        try {
            lib.dtmf(digit);
        } catch (error) {
            commit("SET_ERROR", error, { root: true });
        }
    },
    async startRecord({ commit }) {
        try {
            commit("SET_LOADING", true);
            await lib.startRecord();
            commit("SET_LOADING", false);
        } catch (error) {
            commit("SET_ERROR", error, { root: true });
            commit("SET_LOADING", false);
        }
    },
    async stopRecord({ commit }) {
        try {
            commit("SET_LOADING", true);
            await lib.stopRecord();
            commit("SET_LOADING", false);
        } catch (error) {
            commit("SET_ERROR", error, { root: true });
            commit("SET_LOADING", false);
        }
    },

    /**
     * Get call logs from the RC interface
     * @param {*} param0
     */
    async fetchCallHistory({ commit }) {
        try {
            commit("SET_LOADING", true);
            let history = await lib.callHistory();
            commit("SET_HISTORY", history);
            commit("SET_LOADING", false);
        } catch (error) {
            commit("SET_ERROR", error, { root: true });
            commit("SET_LOADING", false);
        }
    },
    /**
     * Get a list of Audio Devices from the browser, requires user permission
     * @param {*} param0
     */
    async fetchAudioDevices({ commit }) {
        try {
            commit("SET_LOADING", true);
            let devices = await api.getAudioDevices();
            commit("SET_DEVICES", devices);
            commit("SET_LOADING", false);
        } catch (error) {
            commit("SET_ERROR", error, { root: true });
            commit("SET_LOADING", false);
        }
    },
    async setInputAudio({ commit }, deviceId) {
        api.setInputAudioDevice(deviceId);
        commit("SET_AUDIO_INPUT_DEVICE_ID", deviceId);
    },
    async setOutputAudio({ commit }, deviceId) {
        api.setOutputAudioDevice(deviceId);
        commit("SET_AUDIO_OUTPUT_DEVICE_ID", deviceId);
    },
    /**
     * Adjust the output volume
     * @param {*}
     * @param {*} volume
     */
    async setVolume({ commit }, volume) {
        // Api stores the volume
        api.setVolume(volume);
        // Lib adusts video element for call
        lib.setVolume(volume);
        commit("SET_VOLUME", volume);
    },
};

const mutations = {
    RESET: (state) => {
        const newState = initialState();
        Object.keys(newState).forEach((key) => {
            state[key] = newState[key];
        });
    },
    RESET_CALL: (state) => {
        state.contact = {};
        state.phoneNumber = "";
        state.callMute = false;
        state.callHold = false;
        state.callRecord = false;
        state.callActive = false; // we should get this status from the Phone Lib, but be safe
    },
    SET_DEVICES: (state, payload) => {
        state.devices = payload;
    },
    SET_HISTORY: (state, payload) => {
        state.history = payload;
    },
    SET_VOLUME: (state, payload) => {
        state.volume = payload;
    },
    SET_CALL_MUTE: (state, payload) => {
        state.callMute = payload;
    },
    SET_CALL_HOLD: (state, payload) => {
        state.callHold = payload;
    },
    SET_CALL_RECORD: (state, payload) => {
        state.callRecord = payload;
    },
    SET_CALL_DURATION: (state, payload) => {
        state.callDuration = payload;
    },
    SET_CALL_ACTIVE: (state, payload) => {
        state.callActive = payload;
    },
    SET_LOADING: (state, payload) => {
        state.loading = payload;
    },
    SET_SIP_INFO: (state, payload) => {
        state.sipInfo = payload;
    },
    SET_EXTENSION_INFO: (state, payload) => {
        state.extensionInfo = payload;
    },
    SET_REGISTERED: (state, payload) => {
        state.registered = payload;
    },
    SET_INITIALIZED: (state, payload) => {
        state.initialized = payload;
    },
    SET_RING_CENTRAL_CLIENT: (state, payload) => {
        state.ringCentralClient = payload;
    },
    SET_AUDIO_INPUT_DEVICE_ID: (state, payload) => {
        state.audioInputDeviceId = payload;
    },
    SET_AUDIO_OUTPUT_DEVICE_ID: (state, payload) => {
        state.audioOutputDeviceId = payload;
    },
    SET_ACTIVE_CALL_INFO: (state, payload) => {
        state.activeCallInfo = payload;
    },
    SET_CONTACT: (state, payload) => {
        state.contact = payload;
    },
    SET_PHONE_NUMBER: (state, payload) => {
        state.phoneNumber = payload;
    },
};

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations,
};
