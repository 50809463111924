var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-row",
    [
      _c(
        "v-col",
        { attrs: { cols: _vm.labelCols } },
        [
          _c(
            "v-card",
            {
              staticStyle: { opacity: "0.6" },
              attrs: { flat: "", color: "primary lighten-2", height: "100%" }
            },
            [
              _c("v-card-text", [
                _c(
                  "div",
                  { staticClass: "d-flex" },
                  [
                    _c("v-spacer"),
                    _c(
                      "h3",
                      {
                        staticClass:
                          "text-uppercase primary--text text--darken-2"
                      },
                      [_vm._v(" " + _vm._s(_vm.title) + " ")]
                    )
                  ],
                  1
                ),
                _c(
                  "div",
                  { staticClass: "d-flex mt-4" },
                  [
                    _c("v-spacer"),
                    _c(
                      "v-avatar",
                      {
                        attrs: {
                          rounded: "",
                          size: "120",
                          color: "primary darken-1"
                        }
                      },
                      [
                        _c(
                          "v-icon",
                          {
                            staticClass: "primary--text text--lighten-2",
                            attrs: { size: "90" }
                          },
                          [_vm._v(_vm._s(_vm.icon))]
                        )
                      ],
                      1
                    )
                  ],
                  1
                )
              ])
            ],
            1
          )
        ],
        1
      ),
      _c(
        "v-col",
        { attrs: { cols: _vm.contentCols } },
        [
          _c(
            "v-card",
            {
              attrs: { tile: "", disabled: _vm.sectionDisabled || _vm.loading }
            },
            [
              _c(
                "v-card-text",
                [
                  _vm._t("default", [
                    _c("v-card", { attrs: { flat: "", height: "500" } })
                  ])
                ],
                2
              ),
              !_vm.hideActions && !_vm.loading
                ? _c(
                    "v-card-actions",
                    { staticClass: "card-actions" },
                    [
                      _c("v-spacer"),
                      _vm._t("actions", [
                        _c(
                          "v-btn",
                          {
                            attrs: {
                              color: "primary darken-2",
                              disabled: _vm.btnDisabled
                            },
                            on: {
                              click: function($event) {
                                $event.stopPropagation()
                                return _vm.$emit("section-save")
                              }
                            }
                          },
                          [
                            _c("v-icon", { staticClass: "mr-2" }, [
                              _vm._v(_vm._s(_vm.btnIcon))
                            ]),
                            _vm._v(" " + _vm._s(_vm.btnLabel) + " ")
                          ],
                          1
                        )
                      ])
                    ],
                    2
                  )
                : _vm._e(),
              _vm.loading
                ? _c(
                    "v-card-actions",
                    [
                      _c("v-progress-linear", {
                        attrs: { indeterminate: "", color: "primary" }
                      })
                    ],
                    1
                  )
                : _vm._e()
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }