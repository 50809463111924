// import the api endpoints
import api from "../api";

const initialState = () => ({
    uploads: [],
    currentUpload: {},
    loading: false,
});

const state = initialState();

const getters = {
    uploads: (state) => state.uploads,
    currentUpload: (state) => state.currentUpload,
};

const actions = {
    reset({ commit }) {
        commit("RESET");
    },
    async getUploads({ commit }, count = 10) {
        try {
            commit("SET_LOADING", true);
            let uploads = await api.getUploads(count);
            commit("SET_UPLOADS", uploads);
            commit("SET_LOADING", false);
        } catch (error) {
            commit("SET_ERROR", error, { root: true });
        }
    },
};

const mutations = {
    RESET: (state) => {
        const newState = initialState();
        Object.keys(newState).forEach((key) => {
            state[key] = newState[key];
        });
    },
    SET_UPLOADS: (state, payload) => {
        state.uploads = payload;
    },
    SET_CURRENT_UPLOAD: (state, payload) => {
        state.currentUpload = payload;
    },
    SET_LOADING: (state, payload) => {
        state.loading = payload;
    },
};

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations,
};
