var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-menu",
    {
      attrs: {
        bottom: "",
        left: "",
        "nudge-top": "-36",
        "close-on-click": true,
        "close-on-content-click": false
      },
      scopedSlots: _vm._u([
        {
          key: "activator",
          fn: function(ref) {
            var on = ref.on
            var attrs = ref.attrs
            return [
              _c(
                "v-btn",
                _vm._g(
                  _vm._b(
                    { class: _vm.btnClass, attrs: { small: _vm.small } },
                    "v-btn",
                    attrs,
                    false
                  ),
                  on
                ),
                [
                  _c(
                    "v-badge",
                    {
                      class: _vm.badgeClass,
                      attrs: {
                        color: _vm.badgeColor,
                        content: _vm.filterItemCount,
                        value: _vm.filterItemCount > 0
                      }
                    },
                    [
                      _c("v-icon", { attrs: { small: _vm.small } }, [
                        _vm._v("mdi-filter-menu")
                      ])
                    ],
                    1
                  )
                ],
                1
              )
            ]
          }
        }
      ]),
      model: {
        value: _vm.menu,
        callback: function($$v) {
          _vm.menu = $$v
        },
        expression: "menu"
      }
    },
    [
      _c(
        "v-card",
        { staticClass: "pa-3", attrs: { width: _vm.width, outlined: "" } },
        [
          _vm.filterItemCount > 0
            ? _c(
                "v-btn",
                {
                  staticClass: "my-1",
                  attrs: { block: "" },
                  on: {
                    click: function($event) {
                      $event.stopPropagation()
                      return _vm.filterClearAll($event)
                    }
                  }
                },
                [
                  _c("v-icon", { staticClass: "mr-2" }, [
                    _vm._v("mdi-filter-remove")
                  ]),
                  _vm._v(" Clear all filters ")
                ],
                1
              )
            : _vm._e(),
          _c(
            "v-list",
            { staticClass: "py-0 px-0" },
            [
              _vm._l(_vm.filters, function(filter, index) {
                return [
                  _c(
                    "v-list-item",
                    { key: index, staticClass: "py-0 my-2 px-0" },
                    [
                      _c(
                        "v-list-item-content",
                        { staticClass: "py-0 mt-0" },
                        [
                          _c(
                            "v-card-title",
                            {
                              staticClass:
                                "font-weight-bold text-uppercase py-2",
                              class: _vm.labelClass
                            },
                            [_vm._v(_vm._s(filter.label))]
                          ),
                          _c(
                            "v-card-text",
                            { staticClass: "grey lighten-4 py-2" },
                            [
                              _c("v-select", {
                                attrs: {
                                  dense: "",
                                  clearable: filter.value != filter.default,
                                  "background-color": "grey lighten-4",
                                  color: _vm.color,
                                  value: filter.value,
                                  "prepend-icon": filter.icon,
                                  items: filter.items,
                                  "item-text": filter.itemText,
                                  "item-value": filter.itemValue,
                                  solo: "",
                                  "hide-details": ""
                                },
                                on: {
                                  change: function($event) {
                                    return _vm.filterItemChange(
                                      filter.name,
                                      $event
                                    )
                                  },
                                  "click:clear": function($event) {
                                    return _vm.filterItemClear(filter.name)
                                  }
                                }
                              })
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                ]
              })
            ],
            2
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }