var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-card",
    { staticClass: "mb-2", attrs: { flat: "" } },
    [
      _c(
        "v-card-text",
        {
          staticClass: "pa-5",
          class: "alternate" + _vm.colorOption.toString()
        },
        [
          _c("div", { staticClass: "d-flex align-center" }, [
            _c("div", { staticClass: "align-self-center" }, [
              !!_vm.icon
                ? _c(
                    "h1",
                    { staticClass: "white--text", class: _vm.fonts.icon },
                    [
                      _c(
                        "v-icon",
                        { staticClass: "white--text", class: _vm.fonts.icon },
                        [_vm._v(_vm._s(_vm.icon))]
                      )
                    ],
                    1
                  )
                : _vm._e()
            ]),
            _c("div", { staticClass: "mx-4" }, [
              _c("h3", {
                staticClass: "card-title white--text font-weight-regular",
                class: _vm.fonts.title,
                domProps: { textContent: _vm._s(_vm.title) }
              }),
              !!_vm.subTitle
                ? _c("h6", {
                    staticClass:
                      "card-subtitle white--text op-5 font-weight-regular",
                    class: _vm.fonts.subTitle,
                    domProps: { textContent: _vm._s(_vm.subTitle) }
                  })
                : _vm._e()
            ])
          ]),
          _c(
            "v-row",
            { staticClass: "mt-1" },
            [
              _c(
                "v-col",
                { staticClass: "d-flex align-center", attrs: { cols: "12" } },
                [
                  _c("h3", {
                    staticClass: "font-weight-light white--text text-nowrap",
                    class: _vm.fonts.stat,
                    domProps: { textContent: _vm._s(_vm.stat) }
                  }),
                  _vm._t("qualifier")
                ],
                2
              )
            ],
            1
          ),
          _vm.counter
            ? _c(
                "v-row",
                { staticClass: "mt-1" },
                [
                  _c(
                    "v-col",
                    {
                      staticClass: "d-flex justify-end",
                      attrs: { cols: "12" }
                    },
                    [
                      _c(
                        "v-chip",
                        {
                          attrs: {
                            dark: "",
                            color:
                              "alternate" +
                              _vm.colorOption.toString() +
                              " darken-2"
                          }
                        },
                        [_vm._v(" " + _vm._s(_vm.counter) + " ")]
                      )
                    ],
                    1
                  )
                ],
                1
              )
            : _vm._e(),
          _vm._t("footer")
        ],
        2
      ),
      !_vm.hideActions
        ? _c(
            "v-card-actions",
            {
              staticClass: "d-flex justify-end lighten pa-3",
              class: "alternate" + _vm.colorOption.toString() + " lighten-2"
            },
            [
              this.action
                ? _c(
                    "v-btn",
                    {
                      staticClass: "white--text text--darken-2",
                      attrs: {
                        color: "alternate" + _vm.colorOption.toString()
                      },
                      on: {
                        click: function($event) {
                          $event.stopPropagation()
                          return _vm.$emit("stat-action:clicked")
                        }
                      }
                    },
                    [
                      _c(
                        "v-icon",
                        { staticClass: "mr-1", attrs: { small: "" } },
                        [_vm._v(_vm._s(_vm.icon))]
                      ),
                      _vm._v(" " + _vm._s(_vm.getActionLabel()) + " ")
                    ],
                    1
                  )
                : _c("div", { staticStyle: { height: "38px" } })
            ],
            1
          )
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }