import httpClient from "@api/client";

import { transformMenus } from "./transform";
import { config } from "@config";

// if we are prototyping, use the user ID = 3 which
const API_GET_AUTHENTICATED_USER = process.env.VUE_APP_PROTOTYPING
    ? `/users/${config("prototype.user_id")}`
    : "/user";
// use fully qualified to bypass prototyping

const API_LOGOUT = "/user/logout";
const API_GET_USERS = "/users";
const API_GET_USER_MENUS = "/menus";

export default {
    async getAuthenticatedUser() {
        let res = await httpClient.get(API_GET_AUTHENTICATED_USER);
        return res.data.data;
    },
    async getUsers() {
        let res = await httpClient.get(API_GET_USERS);
        return res.data.data;
    },

    async getUser(id) {
        let res = await httpClient.get(`${API_GET_USERS}/${id}`);
        return res.data.data;
    },
    async getUserMenus() {
        let res = await httpClient.get(API_GET_USER_MENUS);
        return transformMenus(res.data);
    },
    async logout() {
        await httpClient.post(API_LOGOUT);
    },
};
